import HttpUtilService from './HttpUtilService'
import Properties from '../util/Properties'
import ReferenceCache from '../state/ReferenceCache'

import noCityImageSmall from '../assets/img/tripsavvi_no_city.png'
import noCityImageLarge from '../assets/img/tripsavvi_no_city_large.png'
import {City, CityMinimal} from "../model/Model";

export interface OptionalCity {
    code?: string,
    name?: string,
    countryCode?: string
    timezone?: string
}
class ReferenceCacheService {
    BASE_URL: string;
    httpUtilService: HttpUtilService;
    referenceCache: ReferenceCache;

    static citiesListToMap(cities: City[]): { [key: string]: City; } {
        return cities.reduce((accumulator: { [key: string]: City; }, city) => {
            accumulator[city.code] = city;
            return accumulator;
        }, {});
    }

    constructor() {
        this.BASE_URL = process.env.REACT_APP_REFERENCE_DATA_BACK_END_API || "";
        this.httpUtilService = new HttpUtilService();
        this.referenceCache = new ReferenceCache();
    }

    getCityPhoto(cityCode: string): string {
        const destinationPhoto = this.referenceCache.getPhoto(cityCode);
        return (destinationPhoto && destinationPhoto.length >= 1) ? destinationPhoto[0].smallPictureUrl : noCityImageSmall;
    }

    getLargeCityPhoto(cityCode: string): string {
        const destinationPhoto = this.referenceCache.getPhoto(cityCode);
        return (destinationPhoto && destinationPhoto.length >= 1) ? destinationPhoto[0].largePictureUrl : noCityImageLarge;
    }

    getCity(cityCode: string): OptionalCity {
        return this.referenceCache.getCity(cityCode) || {
            code: undefined,
            name: undefined,
            countryCode: undefined,
            timezone: undefined
        };
    }

    loadCacheWithCityData(cityCodes: string[]): Promise<void> {
        const cityCodesToLoad = cityCodes
            .filter(cityCode => !this.referenceCache.isCityInCache(cityCode) || !this.referenceCache.isPhotoInCache(cityCode));

        if (cityCodesToLoad.length < 1)
            return Promise.resolve();

        const params = {cityCodes: cityCodesToLoad.join(',')};

        return Promise.all([
            this.httpUtilService.get(Properties.GET_PHOTOS_URL, this.BASE_URL, params),
            this.httpUtilService.get(Properties.GET_CITIES_URL, this.BASE_URL, params)
        ]).then(([photosResponse, citiesResponse]) => {
            this.referenceCache.addPhotos(photosResponse.data.response);
            this.referenceCache.addCities(ReferenceCacheService.citiesListToMap(citiesResponse.data));
        });
    }

    /** http calls **/
    fetchCityDetailedInfo(originCityCode: string, destinationCityCode: string, chosenStartDate: string, chosenEndDate: string) {
        return this.httpUtilService
            .get(Properties.GET_CITY_INFO_URL, this.BASE_URL, {
                originCityCode: originCityCode,
                destinationCityCode: destinationCityCode,
                chosenStartDate: chosenStartDate,
                chosenEndDate: chosenEndDate
            })
            .then(response => response.data);
    }

    fetchCitiesForAutocomplete(value: string, cityCodesToIgnore: string[] | undefined = undefined) {
        return this.httpUtilService
            .get(Properties.GET_CITIES_AUTOCOMPLETE_URL, this.BASE_URL, {
                value: value,
                cityCodesToIgnore: cityCodesToIgnore?.join(',')
            })
            .then(response => response.data.map((city: CityMinimal) => {
                return {
                    cityName: city.name,
                    cityCode: city.code,
                    countryName: city.countryName
                }
            }));
    }

    fetchCity(cityCode: string) {
        return this.httpUtilService.get(Properties.GET_CITIES_URL, this.BASE_URL, {cityCodes: cityCode})
            .then(cityResponse => {
                const cityData = cityResponse.data[0];
                return {
                    cityName: cityData ? cityData.name : undefined,
                    cityCode: cityData ? cityData.code : undefined,
                    countryName: cityData ? cityData.countryName : undefined,
                    temperature: {},
                    pricePerPerson: {},
                };
            })
    }

    fetchCitySuggestionInfo(cityCode: string, startTravelDate: string) {
        return Promise.all([
            this.httpUtilService.get(Properties.GET_PHOTOS_URL, this.BASE_URL, {cityCodes: cityCode}),
            this.httpUtilService.get(Properties.GET_TEMPERATURE_URL, this.BASE_URL, {
                cityCodes: cityCode,
                checkInDate: startTravelDate
            }),
            this.httpUtilService.get(Properties.GET_CITIES_URL, this.BASE_URL, {cityCodes: cityCode})
        ]).then(([photosResponse, temperatureResponse, cityResponse]) => {
            const photoData = photosResponse.data.response[cityCode];
            const temperatureData = temperatureResponse.data.response[cityCode];
            const cityData = cityResponse.data[0];

            return {
                largePictureUrl: (photoData && photoData.length >= 1) ? photoData[0].largePictureUrl : undefined,
                pricePerPerson: {},
                temperature: {
                    high: temperatureData ? temperatureData.maxTemperatureC : undefined,
                    average: temperatureData ? temperatureData.avgTemperatureC : undefined,
                    low: temperatureData ? temperatureData.avgTemperatureC : undefined
                },
                cityName: cityData ? cityData.name : undefined,
                cityCode: cityData ? cityData.code : undefined,
                countryName: cityData ? cityData.countryName : undefined
            };

        });
    }

    fetchFlights(originCityCode: string, destinationCityCode: string, departDate: string, returnDate: string,
                 isDirectFlight: boolean, noOfAdults: number) {
        return this.httpUtilService.get(Properties.GET_FLIGHTS_URL, this.BASE_URL, {
            originCityCode: originCityCode,
            destinationCityCode: destinationCityCode,
            departDate: departDate,
            returnDate: returnDate,
            isDirectFlight: isDirectFlight,
            noOfAdults: noOfAdults
        }).then(response => response.data, (err) => {
            console.error('couldn\'t fetch flights', err);
            return [];
        });
    }

    fetchHotels(cityCode: string, checkInDate: string, checkOutDate: string, noOfAdults: number) {
        return this.httpUtilService.get(Properties.GET_HOTELS_URL, this.BASE_URL, {
            cityCode: cityCode,
            checkInDate: checkInDate,
            checkOutDate: checkOutDate,
            noOfAdults: noOfAdults
        }).then(response => response.data, (err) => {
            console.error('couldn\'t fetch hotels', err);
            return [];
        });
    }

}

export default ReferenceCacheService;