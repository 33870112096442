import React from "react";
import FontAwesome from "react-fontawesome"
import {
    SingletonReferenceCacheService,
    SingletonSocialService,
    SingletonTripSharedState,
    SingletonUserService,
    SingletonWebSocketClient
} from "../../AppContext";
import {Button, ButtonGroup} from "react-bootstrap";
import ToggleDisplay from 'react-toggle-display'

import Properties from "../../util/Properties";
import TripMessagePanel from "./components/tripMessagePanel/TripMessagePanel";
import Itinerary from "./components/itinerary/Itinerary";
import {Redirect} from "react-router-dom";
import TripDetailsPanel from "./components/tripDetailsPanel/TripDetailsPanel";
import ConfirmBusyButton from "../../common_ui/ConfirmBusyButton";
import LoadingComponent from "../../common_ui/LoadingComponent";

class PlanningPage extends React.Component {

    TRIP_UPDATES_TOPIC = `TRIP_UPDATES_${this.props.match.params.id}`;

    constructor(props) {
        super(props);
        this.state = {
            trip: undefined,
            redirectToHome: false,
            tripId: this.props.match.params.id
        };
        this.newMessagesClient = SingletonWebSocketClient;
        this.userService = SingletonUserService;
        this.referenceCacheService = SingletonReferenceCacheService;
        this.socialService = SingletonSocialService;
        this.tripSharedState = SingletonTripSharedState;
    }

    static getCityDetailsHref(tripId, itinerary) {
        return Properties.ROUTE_LOAD_TRIP_CITY_DETAIL_PAGE
            .replace(':id', tripId)
            .replace(':originCityCode', itinerary.originCityCode)
            .replace(':destinationCityCode', itinerary.selectedDestination)
            .replace(':chosenStartDate', itinerary.startTravelDate)
            .replace(':chosenEndDate', itinerary.endTravelDate);
    }

    getCity = (itinerary) => this.referenceCacheService.getCity(itinerary.selectedDestination);

    componentWillMount() {
        this.getTrip(this.state.tripId);

        this.newMessagesClient.connect([this.TRIP_UPDATES_TOPIC])
            .then(() => this.newMessagesClient.onMessageReceived((topic, tripMessage) => {
                if (topic === this.TRIP_UPDATES_TOPIC) this.handleTripUpdate(tripMessage);
            }));
    }

    componentWillReceiveProps(nextProps) {
        const newTripId = nextProps.match.params.id;
        if (this.props.match.params.id !== newTripId) {
            this.setState({tripId: newTripId, trip: undefined});
            this.getTrip(newTripId);
        }
    }

    getTrip(tripId = this.state.tripId) {
        return this.userService.getTrip(tripId)
            .then(
                trip => this.handleTripUpdate(trip),
                error => {
                    if (error.response.status === 404) {
                        this.setState({redirectToHome: true})
                    }
                });
    }

    removeItinerary(cityCode) {
        const trip = this.state.trip;
        const index = trip.potentialItineraries.findIndex(it => it.selectedDestination === cityCode);
        if (index === -1)
            return;

        const itinerary = trip.potentialItineraries[index];
        itinerary.isRemoving = true;
        this.setState({trip: trip});

        this.userService.deleteItinerary(trip.id, cityCode)
            .then(response => this.handleTripUpdate(response));
    }

    deleteTrip() {
        const trip = this.state.trip;
        trip.potentialItineraries[0].isRemoving = true;
        this.setState({trip: trip});

        return this.userService.deleteTrip(this.state.trip.id)
            .then(() => this.setState({redirectToHome: true}));
    }

    handleTripUpdate(trip) {
        return this.userService._updateCityReferenceData(trip) //TODO bit ugly try to handle another way.
            .then(() => {
                const availableItineraries = trip.potentialItineraries.filter(it => it.status !== 'REMOVED');
                return this.setState({trip: trip}, this.tripSharedState.setState({itineraries: availableItineraries}));
            });
    }

    onSaveItinerary(cityCode, itinerary) {
        const {trip} = this.state;
        const index = trip.potentialItineraries.findIndex(it => it.selectedDestination === cityCode);
        if (index === -1)
            return;

        const clonedTrip = {...trip};
        clonedTrip.potentialItineraries[index] = itinerary;
        this.setState({trip: clonedTrip});

        return this.userService.updateUserTrip(clonedTrip)
            .then((response) => this.handleTripUpdate(response.savedTrip));
    }

    render() {
        if (this.state.redirectToHome === true) {
            return <Redirect to={Properties.ROUTE_DISCOVER_NEXT_DESTINATION}/>
        }

        if (!this.state.trip) {
            return (
                <div className="destination-suggestion-section col-v-centered" style={{height: '80vh'}}>
                    <LoadingComponent isLoading={true}/>
                </div>
            );
        }

        const isLoggedOn = this.userService.isUserLoggedIn();

        const availableItineraries = this.state.trip.potentialItineraries.filter((it) => it.status !== 'REMOVED');

        const getConfirmMessage = (itinerary) => {
            return availableItineraries.length > 1
                ? "Are you sure you want to remove the option " + this.getCity(itinerary).name + " from this trip?"
                : "Are you sure you want to delete this trip?";
        };

        const getRemoveItineraryActionText = (itinerary) => {
            return availableItineraries.length > 1
                ? "Remove " + this.getCity(itinerary).name
                : "Delete Trip";
        };

        const performRemove = (cityCode) => {
            if (availableItineraries.length > 1) {
                this.removeItinerary(cityCode)
            } else {
                this.deleteTrip()
            }
        };

        const itinerariesDisplay = availableItineraries.map((itinerary, index) =>
            <div className="planning-itinerary" key={index}>
                <div className="block-style-planning-destination">
                    <div
                        style={{backgroundImage: 'url("' + this.referenceCacheService.getCityPhoto(itinerary.selectedDestination) + '")'}}
                        className='destination-suggestion-planning'>
                        <div className='destination-suggestion-title'>
                            <span>{this.getCity(itinerary).name + ", " + this.getCity(itinerary).countryName + " (" + itinerary.selectedDestination + ")"}</span>

                        </div>
                        <div className='destination-suggestion-middle'/>

                        <div className="destination-suggestion-info">
                            <Button style={{borderRadius: '10px', color: 'white'}} bsStyle="link" bsSize="small"
                                    href={PlanningPage.getCityDetailsHref(this.state.tripId, itinerary)}>
                                <FontAwesome name='info-circle' style={{color: 'white'}}/> Info
                            </Button>
                        </div>
                    </div>
                </div>

                <Itinerary tripId={this.state.tripId}
                           itinerary={itinerary}
                           onSaveItinerary={(itinerary) => this.onSaveItinerary(itinerary.selectedDestination, itinerary)}/>

                <ToggleDisplay if={isLoggedOn} key={index}>
                    <ButtonGroup vertical block key={index}>
                        <ConfirmBusyButton bsStyle="danger"
                                           confirmMessage={getConfirmMessage(itinerary)}
                                           onClick={() => performRemove(itinerary.selectedDestination)}
                                           disabled={itinerary.isRemoving || false}
                                           buttonText={getRemoveItineraryActionText(itinerary)}
                                           loadingMessage="Deleting..."
                                           isLoading={itinerary.isRemoving || false}
                                           key={index}/>
                    </ButtonGroup>
                </ToggleDisplay>
            </div>);

        const cityCodesToIgnore = availableItineraries.map(it => it.selectedDestination);

        return (
            <div>
                <div className="destination-suggestion-section" style={{paddingBottom: '80px'}}>
                    <TripDetailsPanel trip={this.state.trip}/>

                    <div className="destination-suggestion-scroll-panel">
                        <div className="destination-itineraries">
                            {itinerariesDisplay}
                        </div>
                    </div>
                </div>

                <div className="message-bottom-panel">
                    <TripMessagePanel tripId={this.state.tripId} cityCodesToIgnore={cityCodesToIgnore} {...this.props}/>
                </div>
            </div>
        );
    }
}

export default PlanningPage;