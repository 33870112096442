import * as React from "react";
import {Button} from "react-bootstrap";
import FontAwesome from "react-fontawesome"
import ToggleDisplay from 'react-toggle-display'

class ConfirmEdit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            disabled: this.props.disabled || false
        }
    }

    startEditing() {
        this.props.onStartEdit && this.props.onStartEdit();
    }

    onSave() {
        this.props.onStopEdit && this.props.onStopEdit();
        this.props.onSave && this.props.onSave();
    }

    onCancel() {
        this.props.onStopEdit && this.props.onStopEdit();
        this.props.onCancel && this.props.onCancel();
    }

    render() {
        return (
            <div>
                <ToggleDisplay if={!this.props.editMode}>
                    <Button bsStyle="primary" bsSize="small"
                            disabled={this.state.disabled}
                            onClick={(e) => this.startEditing()}>
                        <FontAwesome name='edit'/>
                    </Button>
                </ToggleDisplay>

                <ToggleDisplay if={this.props.editMode}>
                    <Button bsSize="small"
                            disabled={this.state.disabled}
                            onClick={(e) => this.onSave()}>
                        <FontAwesome name='check'/>
                    </Button>
                    <Button bsStyle="danger" bsSize="small"
                            disabled={this.state.disabled}
                            onClick={(e) => this.onCancel()}>
                        <FontAwesome name='times'/>
                    </Button>
                </ToggleDisplay>
            </div>
        );
    }
}

export {ConfirmEdit}