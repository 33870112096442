import React from "react";
import {Button, ButtonGroup, Checkbox, FormControl, FormGroup, InputGroup} from "react-bootstrap";
import DatePicker from 'react-datepicker'
import ItinerarySelection from "../../itinerary/ItinerarySelection";
import BusyButton from "../../../../../common_ui/BusyButton";
import DateUtils from "../../../../../util/DateUtils";
import Properties from "../../../../../util/Properties";
import ValidationUtils from "../../../../../util/ValidationUtils";
import moment from "moment";
import {SingletonReferenceCacheService, SingletonSocialService} from "../../../../../AppContext";
import skyscannerLogo from '../../../../../assets/img/skyscanner_logo.png'

class PostFlightSuggestionInputs extends React.Component {

    constructor(props) {
        super(props);
        this.setStateFunctions = this.props.setStateFunctions;
        this.socialService = SingletonSocialService;
        this.referenceCacheService = SingletonReferenceCacheService;
        this.state = {
            showFlightPicker: false
        }
    }

    validatePostFlight() {
        return ValidationUtils.combineValidationResults([
            ValidationUtils.validateValueOver(this.props.flightItinerarySelection, 0),
            ValidationUtils.validateLength(this.props.flightAirlineName, 1, 300),
            ValidationUtils.validateValueOver(this.props.flightPrice, 1),
            ValidationUtils.validateLength(this.props.flightOutboundDepartureTime, 1, 30),
            ValidationUtils.validateLength(this.props.flightOutboundArrivalTime, 1, 30),
        ]);
    }

    getSkyScannerFlightSearchHref() {
        let itinerary = this.getItinerary();
        return Properties.EXTERNAL_ROUTE_SKY_SCANNER_FLIGHTS
            .replace(':originCityCode', itinerary.originCityCode)
            .replace(':destinationCityCode', itinerary.selectedDestination)
            .replace(':outboundDate', DateUtils.dateStringSwitchFormat(itinerary.startTravelDate, Properties.DATE_FORMAT, 'YYMMDD'))
            .replace(':inboundDate', DateUtils.dateStringSwitchFormat(itinerary.endTravelDate, Properties.DATE_FORMAT, 'YYMMDD'))
            .replace(':noOfAdults', itinerary.noOfAdults)
            .replace(':noOfChildren', itinerary.noOfChildren)
    }

    getItinerary() {
        const itinerary = this.props.itineraries[this.props.flightItinerarySelection];
        return itinerary ? itinerary : {};
    }

    handleUrlChange(value) {
        this.setStateFunctions.flightUrl(value);

        if (value.length > 10) {
            this.socialService.checkLink(value, Properties.FLIGHT_SUGGESTION)
                .then(responseData => {
                    if (responseData.result !== undefined) {
                        this.setFlightDetails(responseData.result + ' link detected. Fetching details...', 'Fetching details...');
                        return this.socialService.processLink(value, Properties.FLIGHT_SUGGESTION)
                            .then(responseData => {
                                const result = responseData.result;
                                this.setFlightDetails(result.outboundAirline, result.totalCost, result.outboundDepartDateTime,
                                    result.outboundArrivalDateTime, result.inboundDepartDateTime, result.inboundArrivalDateTime);
                            });
                    } else {
                        this.setState({manualInput: true});
                        return Promise.resolve();
                    }
                })
                .catch(() => {
                    this.setFlightDetails('Could not get details from link', undefined);
                });
        }
    }

    handlePostSuggestion() {
        return this.props.postFlightSuggestion()
            .then(() => this.clearInputs())
    }

    setFlightDetails(airlineName, accommodationPrice, outboundDepartureTime, outboundArrivalTime,
                     inboundDepartureTime, inboundArrivalTime,) {
        this.setStateFunctions.flightAirlineName(airlineName);
        this.setStateFunctions.flightPrice(accommodationPrice);
        if (outboundDepartureTime) this.setStateFunctions.flightOutboundDepartureTime(outboundDepartureTime);
        if (outboundArrivalTime) this.setStateFunctions.flightOutboundArrivalTime(outboundArrivalTime);
        if (inboundDepartureTime) this.setStateFunctions.flightInboundDepartureTime(inboundDepartureTime);
        if (inboundArrivalTime) this.setStateFunctions.flightInboundArrivalTime(inboundArrivalTime);
    }

    clearInputs() {
        this.setStateFunctions.flightAirlineName(undefined);
        this.setStateFunctions.flightPrice(undefined);
        this.setStateFunctions.flightUrl(undefined);
        this.setStateFunctions.flightOutboundDepartureTime(undefined);
        this.setStateFunctions.flightOutboundArrivalTime(undefined);
        this.setStateFunctions.flightInboundDepartureTime(undefined);
        this.setStateFunctions.flightInboundArrivalTime(undefined);
        this.setStateFunctions.flightItinerarySelection(-1);
        this.setStateFunctions.flightAddToItinerary(false);
    }

    render() {
        return (
            <React.Fragment>
                <FormGroup validationState={this.validatePostFlight()}>
                    <ItinerarySelection itineraries={this.props.itineraries}
                                        value={this.props.flightItinerarySelection}
                                        disabled={this.props.isPostingMessage}
                                        onChange={(e) => this.setStateFunctions.flightItinerarySelection(e.target.value)}/>

                    <ButtonGroup className="input-big-button-holder" vertical>
                        <Button href={this.getSkyScannerFlightSearchHref()} target="_blank" rel="noopener noreferrer"
                                bsStyle="primary"
                                disabled={this.props.flightItinerarySelection === -1 || this.props.isPostingMessage}>
                            <span className="middle-text">Search Flights With </span><img src={skyscannerLogo}  alt="skyscanner logo"/>
                        </Button>
                    </ButtonGroup>

                    <p className="instructions-text">Enter details</p>
                    <div>
                        <InputGroup className="comment-text-area" bsSize="sm">
                            <FormControl type="text"
                                         placeholder="Enter URL to Flight (optional)"
                                         disabled={this.props.isPostingMessage}
                                         value={this.props.flightUrl}
                                         onChange={(e) => this.handleUrlChange(e.target.value)}
                                         id="url"/>
                            <InputGroup.Button>
                                <Button disabled={this.props.isPostingMessage}
                                        onClick={() => this.handleUrlChange('')}>Clear</Button>
                            </InputGroup.Button>
                        </InputGroup>

                        <FormControl type="text"
                                     bsSize="sm"
                                     placeholder="Enter Airline Name"
                                     className="comment-text-area"
                                     value={this.props.flightAirlineName}
                                     disabled={this.props.isPostingMessage}
                                     onChange={(e) => this.setStateFunctions.flightAirlineName(e.target.value)}
                                     id="airline-name"/>

                        <InputGroup className="comment-text-area" bsSize="sm">
                            <InputGroup.Addon>£</InputGroup.Addon>
                            <FormControl type="text"
                                         placeholder="Enter Total Flight Price"
                                         disabled={this.props.isPostingMessage}
                                         value={this.props.flightPrice}
                                         onChange={(e) => this.setStateFunctions.flightPrice(e.target.value)}
                                         id="price"/>
                        </InputGroup>

                        <div className="col-v-centered comment-dates">
                            <DatePicker id="outbound-start-date"
                                        className="form-control input-sm"
                                        placeholderText="Outbound Departure Date"
                                        minDate={moment().add(1, "days")}
                                        maxDate={moment().add(1, "years")}
                                        dateFormat={Properties.DISPLAY_DATE_TIME_FORMAT}
                                        selected={DateUtils.stringToMoment(this.props.flightOutboundDepartureTime,
                                            Properties.DATE_TIME_FORMAT)}
                                        startDate={DateUtils.stringToMoment(this.props.flightOutboundDepartureTime,
                                            Properties.DATE_TIME_FORMAT)}
                                        endDate={DateUtils.stringToMoment(this.props.flightOutboundArrivalTime,
                                            Properties.DATE_TIME_FORMAT)}
                                        onChange={(newDate) =>
                                            this.setStateFunctions.flightOutboundDepartureTime(DateUtils.momentToString(Properties.DATE_TIME_FORMAT, newDate))}
                                        disabled={this.props.isPostingMessage}
                                        showTimeSelect
                                        showMonthYearDropdown
                                        withPortal
                                        selectsStart/>

                            <DatePicker id="outbound-end-date"
                                        className="form-control input-sm"
                                        placeholderText="Outbound Arrival Date"
                                        minDate={DateUtils.stringToMoment(this.props.flightOutboundDepartureTime)}
                                        maxDate={moment().add(1, "years")}
                                        dateFormat={Properties.DISPLAY_DATE_TIME_FORMAT}
                                        selected={DateUtils.stringToMoment(this.props.flightOutboundArrivalTime,
                                            Properties.DATE_TIME_FORMAT)}
                                        startDate={DateUtils.stringToMoment(this.props.flightOutboundDepartureTime,
                                            Properties.DATE_TIME_FORMAT)}
                                        endDate={DateUtils.stringToMoment(this.props.flightOutboundArrivalTime,
                                            Properties.DATE_TIME_FORMAT)}
                                        onChange={(newDate) =>
                                            this.setStateFunctions.flightOutboundArrivalTime(DateUtils.momentToString(Properties.DATE_TIME_FORMAT, newDate))}
                                        disabled={this.props.isPostingMessage}
                                        showTimeSelect
                                        showMonthYearDropdown
                                        withPortal
                                        selectsEnd/>
                        </div>

                        <div className="col-v-centered comment-dates">
                            <DatePicker id="inbound-start-date"
                                        className="form-control input-sm"
                                        placeholderText="Inbound Departure Date"
                                        minDate={DateUtils.stringToMoment(this.props.flightOutboundArrivalTime)}
                                        maxDate={moment().add(1, "years")}
                                        dateFormat={Properties.DISPLAY_DATE_TIME_FORMAT}
                                        selected={DateUtils.stringToMoment(this.props.flightInboundDepartureTime,
                                            Properties.DATE_TIME_FORMAT)}
                                        startDate={DateUtils.stringToMoment(this.props.flightInboundDepartureTime,
                                            Properties.DATE_TIME_FORMAT)}
                                        endDate={DateUtils.stringToMoment(this.props.flightInboundArrivalTime,
                                            Properties.DATE_TIME_FORMAT)}
                                        onChange={(newDate) =>
                                            this.setStateFunctions.flightInboundDepartureTime(DateUtils.momentToString(Properties.DATE_TIME_FORMAT, newDate))}
                                        disabled={this.props.isPostingMessage}
                                        showTimeSelect
                                        showMonthYearDropdown
                                        withPortal
                                        selectsStart/>

                            <DatePicker id="inbound-end-date"
                                        className="form-control input-sm"
                                        placeholderText="Inbound Arrival Date"
                                        minDate={DateUtils.stringToMoment(this.props.flightInboundDepartureTime)}
                                        maxDate={moment().add(1, "years")}
                                        dateFormat={Properties.DISPLAY_DATE_TIME_FORMAT}
                                        selected={DateUtils.stringToMoment(this.props.flightInboundArrivalTime,
                                            Properties.DATE_TIME_FORMAT)}
                                        startDate={DateUtils.stringToMoment(this.props.flightInboundDepartureTime,
                                            Properties.DATE_TIME_FORMAT)}
                                        endDate={DateUtils.stringToMoment(this.props.flightInboundArrivalTime,
                                            Properties.DATE_TIME_FORMAT)}
                                        onChange={(newDate) =>
                                            this.setStateFunctions.flightInboundArrivalTime(DateUtils.momentToString(Properties.DATE_TIME_FORMAT, newDate))}
                                        disabled={this.props.isPostingMessage}
                                        showTimeSelect
                                        showMonthYearDropdown
                                        withPortal
                                        selectsEnd/>
                        </div>
                    </div>

                    <Checkbox
                        className="add-to-itinerary"
                        defaultChecked={this.props.flightAddToItinerary}
                        disabled={this.props.isPostingMessage}
                        onChange={(e) => this.setStateFunctions.flightAddToItinerary(e.target.checked)}>
                        Add to Itinerary
                    </Checkbox>
                </FormGroup>


                <ButtonGroup>
                    <BusyButton bsStyle="primary" onClick={(e) => this.handlePostSuggestion()}
                                buttonText="Suggest Flight"
                                disabled={"success" !== this.validatePostFlight()}
                                loadingMessage="Saving..."
                                isLoading={this.props.isPostingMessage}/>
                    <Button onClick={() => this.props.onCancel()}>
                        Cancel
                    </Button>
                </ButtonGroup>
            </React.Fragment>
        );
    }
}

export default PostFlightSuggestionInputs;