import React from "react";
import * as d3 from "d3";

class BarChart extends React.Component {
    state = {
        widthScale: d3
            .scaleBand()
            .domain(d3.range(0, this.props.data.length))
            .range([0, this.props.width]),

        heightScale: d3
            .scaleLinear()
            .domain([-10, d3.max(this.props.data)])
            .range([0, this.props.height])
    };

    render() {
        const {x, y, data, height, highlightBar, highlightedBar, displayValues} = this.props,
            {widthScale, heightScale} = this.state;

        const color = ['darkgray','gray'];

        return (
            <g transform={`translate(${x}, ${y})`}>
                {data.map((d, i) => (
                    [<rect
                        x={widthScale(i)}
                        y={height - heightScale(d)}
                        width={widthScale.bandwidth()}
                        height={heightScale(d)}
                        style={{
                            fill: i === highlightedBar ? "white" : color[i % 2]
                        }}
                        onMouseOver={() => highlightBar(i)}
                        key={i}
                    />,
                    <text x={widthScale(i) + 6} y="140" onMouseOver={() => highlightBar(i)}>{displayValues[i]}</text>
                    ]
                ))}
            </g>
        );
    }
}

export default BarChart;
