import SessionStore from "./SessionStore";
import {City, LocationPhoto} from "../model/Model";

type PhotoMap = { [key: string]: LocationPhoto[]; };
type CitiesMap = { [key: string]: City; };

class ReferenceCache {

    photos: PhotoMap;
    cities: CitiesMap;

    static addToCache(stateToSave: any, newObjectToState: any, sessionStoreName: string) {
        Object.assign(stateToSave, newObjectToState);
        SessionStore.setItem(sessionStoreName, stateToSave);
    }

    constructor() {
        this.photos = SessionStore.getItemOrDefault('refCachePhotos', {});
        this.cities = SessionStore.getItemOrDefault('refCacheCities', {});
    }

    addPhotos(photos: PhotoMap) {
        ReferenceCache.addToCache(this.photos, photos, 'refCachePhotos')
    }

    addCities(cities: CitiesMap) {
        ReferenceCache.addToCache(this.cities, cities, 'refCacheCities')
    }

    getPhoto(cityCode: string): LocationPhoto[] | undefined {
        return this.photos[cityCode];
    }

    getCity(cityCode: string): City | undefined {
        return this.cities[cityCode];
    }

    isPhotoInCache(cityCode: string): boolean {
        return !!this.photos[cityCode];
    }

    isCityInCache(cityCode: string): boolean {
        return !!this.cities[cityCode];
    }

}

export default ReferenceCache;