import * as React from "react";
import DatePicker from 'react-datepicker'
import {Button} from "react-bootstrap";
import ToggleDisplay from 'react-toggle-display'
import moment from 'moment';

import DateUtils from '../util/DateUtils'
import Properties from '../util/Properties'
import SessionStore from "../state/SessionStore";
import DestinationSelector from "./DestinationSelector";
import {Redirect} from "react-router-dom";
import {SingletonTripCreationSharedState} from "../AppContext";

class UserDestinationSuggestionInput extends React.Component {

    constructor(props) {
        super(props);
        this.sharedState = SingletonTripCreationSharedState;
        this.state = {
            selectedDestinations: [{
                destinationCityCode: undefined
            }]
        }
    }

    storeState(partialState, callback) {
        return this.setState(partialState, () => {
            callback && callback();
        });
    }

    handleSelectedDestination(destinationCityCode, index) {
        const selectedDestinations = this.state.selectedDestinations;
        selectedDestinations[index] = {destinationCityCode: destinationCityCode};
        this.storeState({selectedDestinations: selectedDestinations});
    }

    handleClearDestination(index) {
        this.storeState({selectedDestinations: this.state.selectedDestinations.filter((_, i) => i !== index)});
    }

    addPotentialDestination() {
        const selectedDestinations = this.state.selectedDestinations;
        selectedDestinations.push({destinationCityCode: undefined, destinationDetails: undefined});
        this.storeState({selectedDestinations: selectedDestinations});
    }

    isAnySuggestionSelected() {
        const selectedShortlist = this.state.selectedDestinations.filter(selectedDestination => selectedDestination.destinationCityCode);
        return Object.keys(selectedShortlist).length > 0;
    }

    handleNext() {
        const destinationShortList = this.state.selectedDestinations
            .filter(selectedDestination => selectedDestination.destinationCityCode)
            .reduce((accumulator, selectedDestination) => {
                accumulator[selectedDestination.destinationCityCode] = {
                    cityCode: selectedDestination.destinationCityCode
                };
                return accumulator;
            }, {});

        this.sharedState.setState({
            selectedDestinationSuggestions: destinationShortList,
            travelParameters: {
                originCityCode: this.props.originCityCode,
                startTravelDate: this.props.startTravelDate,
                endTravelDate: this.props.endTravelDate,
                noOfAdults: this.props.noOfAdults,
                noOfChildren: this.props.noOfChildren
            }
        });
        this.props.handleNext();
    }

    render() {
        const selectedDestinations = this.state.selectedDestinations;

        const selectedDestinationCodes = selectedDestinations.map(it => it.destinationCityCode);

        const showAddMoreDestinationsHint = !!selectedDestinations[0].destinationCityCode
            && selectedDestinations.length < 2;

        const showAddMoreDestinationsButton = !!selectedDestinations[0].destinationCityCode;

        const destinationSelectors = selectedDestinations.map((selection, index) => {
            const destinationCityCode = this.state.selectedDestinations[index].destinationCityCode;

            const cityCodesToIgnore = selectedDestinationCodes.filter(it => it !== destinationCityCode).concat([this.props.originCityCode]);

            return <div key={index}>
                <DestinationSelector id={'destination-selector' + index}
                                     showDestinationDetails={true}
                                     startTravelDate={this.props.startTravelDate}
                                     endTravelDate={this.props.endTravelDate}
                                     selectedDestination={destinationCityCode}
                                     onSelect={(code) => this.handleSelectedDestination(code, index)}
                                     cityCodesToIgnore={cityCodesToIgnore}
                                     showDeleteButton={index > 0}
                                     onDelete={() => this.handleClearDestination(index)}/>
            </div>;
        });

        return (
            <React.Fragment>
                <div>
                    <p>Where are you going?</p>
                    {destinationSelectors}

                    <ToggleDisplay if={showAddMoreDestinationsHint || showAddMoreDestinationsButton}>
                        <div className="info-label">
                            <ToggleDisplay if={showAddMoreDestinationsHint}>
                                        <span>
                                            Can't decide between one destination yet? <br/>
                                            Add multiple potential destinations and decide later when planning your trip.
                                        </span>
                            </ToggleDisplay>

                            <ToggleDisplay if={showAddMoreDestinationsButton}>
                                <Button bsStyle="primary" bsSize="small"
                                        ref={button => {
                                            this.target = button;
                                        }}
                                        onClick={(e) => this.addPotentialDestination()}>
                                    Add Another Potential Destination
                                </Button>
                            </ToggleDisplay>
                        </div>
                    </ToggleDisplay>
                </div>
                {
                    this.props.handleCancel
                        ? <section className="btn-group">
                            <Button bsStyle="primary"
                                    disabled={!this.isAnySuggestionSelected()}
                                    onClick={() => this.handleNext()}>Suggest New Destinations</Button>
                            <Button disabled={this.props.disabled}
                                    onClick={() => this.props.handleCancel()}>
                                Cancel
                            </Button>
                        </section>
                        : <Button bsSize="large" bsStyle="primary"
                                  disabled={!this.isAnySuggestionSelected()}
                                  onClick={() => this.handleNext()}>Next</Button>
                }
            </React.Fragment>
        );
    }
}

class UserDestinationSuggestionSection extends React.Component {

    STORE_NAME = 'userDestinationSuggestions';

    constructor(props) {
        super(props);
        const initialState = UserDestinationSuggestionSection.getInitialState();

        this.state = SessionStore.getItemOrDefault(this.STORE_NAME, initialState);
        this.state.next = false;
        this.target = undefined;

        this.datePicker = undefined;
        this.endDatePicker = undefined;

        const startTravelMoment = DateUtils.stringToMoment(this.state.startTravelDate, Properties.DATE_FORMAT);
        if (moment().valueOf() > startTravelMoment.valueOf()) {
            this.state.startTravelDate = initialState.startTravelDate;
            this.state.endTravelDate = initialState.endTravelDate;
        }
    }

    static getInitialState() {
        return {
            noOfAdults: 2,
            noOfChildren: 0,
            startTravelDate: DateUtils.momentToString(Properties.DATE_FORMAT, moment().add(1, "days")),
            endTravelDate: DateUtils.momentToString(Properties.DATE_FORMAT, moment().add(5, "days")),
            originCityCode: "LON",
            selectedDestinations: [{
                destinationCityCode: undefined
            }]
        };
    }

    storeState(partialState, callback) {
        return this.setState(partialState, () => {
            callback && callback();
            return SessionStore.setItem(this.STORE_NAME, this.state);
        });
    }

    handleStartTravelDateChange(newDate) {
        this.storeState({
            startTravelDate: DateUtils.momentToString(Properties.DATE_FORMAT, newDate),
            endTravelDate: DateUtils.momentToString(Properties.DATE_FORMAT, newDate.add(1, "days"))
        }, () => this.endDatePicker.input.focus());
    }

    handleEndTravelDateChange(newDate) {
        this.storeState({
            endTravelDate: DateUtils.momentToString(Properties.DATE_FORMAT, newDate)
        });
    }

    render() {
        if (this.state.next) {
            return <Redirect to={this.props.nextPageRoute}/>
        }

        const numberOfAdultsOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9].map((number) =>
            <option key={number} value={number}>{number}</option>);

        const numberOfChildrenOptions = [0, 1, 2, 3, 4, 5, 6].map((number) =>
            <option key={number} value={number}>{number}</option>);

        return (

            <div>
                <div>
                    <div className="find-destination-section">
                        <div>
                            <p>How many people are travelling?</p>

                            <div className="people-amount-selection">
                                <div>
                                    <label htmlFor="no-of-adults-select">Adults (18+)</label>
                                    <select className="form-control input-sm" id="no-of-adults-select"
                                            value={this.state.noOfAdults}
                                            onChange={(e) => this.storeState({noOfAdults: e.target.value})}>
                                        {numberOfAdultsOptions}
                                    </select>
                                </div>

                                <div>
                                    <label htmlFor="no-of-children-select">Children</label>
                                    <select className="form-control input-sm" id="no-of-children-select"
                                            value={this.state.noOfChildren}
                                            onChange={(e) => this.storeState({noOfChildren: e.target.value})}>
                                        {numberOfChildrenOptions}
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div>
                            <p>When are you travelling?</p>
                            <div className="people-amount-selection">
                                <div>
                                    <label htmlFor="travel-start-date">Start Date</label>
                                    <DatePicker id="travel-start-date"
                                                ref={(datePicker) => {
                                                    this.datePicker = datePicker
                                                }}
                                                selected={DateUtils.stringToMoment(this.state.startTravelDate, Properties.DATE_FORMAT)}
                                                startDate={DateUtils.stringToMoment(this.state.startTravelDate, Properties.DATE_FORMAT)}
                                                endDate={DateUtils.stringToMoment(this.state.endTravelDate, Properties.DATE_FORMAT)}
                                                className="form-control input-sm"
                                                onChange={(newDate) => this.handleStartTravelDateChange(newDate)}
                                                minDate={moment().add(1, "days")}
                                                maxDate={moment().add(1, "years")}
                                                dateFormat={Properties.DISPLAY_DATE_FORMAT}
                                                showMonthYearDropdown
                                                withPortal
                                                selectsStart/>
                                </div>

                                <div>
                                    <label htmlFor="travel-end-date">End Date</label>
                                    <DatePicker id="travel-end-date"
                                                ref={(datePicker) => {
                                                    this.endDatePicker = datePicker
                                                }}
                                                selected={DateUtils.stringToMoment(this.state.endTravelDate, Properties.DATE_FORMAT)}
                                                startDate={DateUtils.stringToMoment(this.state.startTravelDate, Properties.DATE_FORMAT)}
                                                endDate={DateUtils.stringToMoment(this.state.endTravelDate, Properties.DATE_FORMAT)}
                                                className="form-control input-sm"
                                                onChange={(newDate) => this.handleEndTravelDateChange(newDate)}
                                                minDate={DateUtils.stringToMoment(this.state.startTravelDate)}
                                                maxDate={moment().add(1, "years")}
                                                dateFormat={Properties.DISPLAY_DATE_FORMAT}
                                                showMonthYearDropdown
                                                withPortal
                                                selectsEnd/>
                                </div>
                            </div>
                        </div>

                        <div>
                            <p>Where are you traveling from?</p>

                            <DestinationSelector id="origin-selector"
                                                 showDestinationDetails={false}
                                                 selectedDestination={this.state.originCityCode}
                                                 onSelect={(code) => this.setState({originCityCode: code})}/>
                        </div>

                        <UserDestinationSuggestionInput startTravelDate={this.state.startTravelDate}
                                                        endTravelDate={this.state.endTravelDate}
                                                        originCityCode={this.state.originCityCode}
                                                        noOfAdults={this.state.noOfAdults}
                                                        noOfChildren={this.state.noOfChildren}
                                                        handleNext={() => this.setState({next: true})}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default UserDestinationSuggestionSection;
export {UserDestinationSuggestionInput}