class Properties {
    static GET_SUGGESTIONS_DESTINATIONS_URL = "/suggestions/destinations";
    static GET_CITIES_URL = "reference/cities";
    static GET_CITIES_AUTOCOMPLETE_URL = "reference/cities/autocomplete";
    static GET_CITY_INFO_URL = "reference/cities/info";
    static GET_PHOTOS_URL = "reference/photos";
    static GET_TEMPERATURE_URL = "reference/temperatures";
    static GET_FLIGHTS_URL = "reference/flights";
    static GET_HOTELS_URL = "reference/hotels";

    static RESOURCE_USER_TRIP_BY_ID = "/user/trip/:id";
    static GET_USER_TRIPS_URL = "/user/:userId/trips";
    static DELETE_ITINERARY_URL = "/user/trip/:id/itinerary/destination/:cityCode";

    static POST_ACCOUNT_LOGIN_URL = "/account/login";
    static POST_GUEST_LOGIN_URL = "/guest/login";
    static POST_ACCOUNT_SIGN_UP_URL = "/account/signUp";
    static GET_ACCOUNT_USERS_URL = "/account/users";
    static POST_ACCOUNT_INITIATE_FORGOTTEN_PASSWORD_URL = "/account/forgotPassword";
    static POST_REFRESH_ACCESS_TOKEN_URL = "/account/refreshAccessToken";
    static POST_ACCOUNT_NEW_PASSWORD_URL = "/account/newPassword";
    static POST_ACCOUNT_VERIFY_EMAIL_URL = "/account/verifyEmail";

    static POST_USER_TRIP_URL = "/user/trip";
    static POST_USER_ANALYTICS_FEEDBACK = "/feedback";
    static POST_USER_ANALYTICS_EVENT = "/event";

    static GET_MESSAGES_FOR_TRIP_URL = "/social/trip/:id/messages";
    static GET_NOTIFICATIONS_FOR_USER_URL = "/social/user/:userId/notifications";
    static UPDATE_NOTIFICATIONS_FOR_USER_URL = "/social/user/:userId/notifications/update?notificationIds=:notificationIds";
    static POST_COMMENTS_FOR_TRIP_URL = "/social/trip/:id/comments";
    static POST_FLIGHT_SUGGESTION_FOR_TRIP_URL = "/social/trip/:id/flightSuggestions";
    static POST_ACCOMMODATION_SUGGESTION_FOR_TRIP_URL = "/social/trip/:id/accommodationSuggestions";
    static POST_EXTRAS_SUGGESTION_FOR_TRIP_URL = "/social/trip/:id/extraSuggestions";
    static POST_NEW_DESTINATION_SUGGESTION_FOR_TRIP_URL = "/social/trip/:id/newDestinationSuggestion";
    static POST_NEW_POLL = "/social/trip/:id/poll";
    static POST_NEW_TASK_LIST = "/social/trip/:id/taskList";

    static SUGGESTION_TO_ITINERARY_URL = "/social/trip/messages/:messageId/itinerary";
    static POST_MESSAGE_FOR_LIKE_URL = "/social/trip/messages/:messageId/like";
    static POST_MESSAGE_FOR_UNLIKE_URL = "/social/trip/messages/:messageId/unlike";
    static DELETE_MESSAGE_URL = "/social/trip/messages/:messageId";
    static POST_UPDATE_POLL_URl = "/social/trip/poll/:pollId";
    static POST_UPDATE_TASK_LIST_URl = "/social/trip/taskList/:taskId";

    static CHECK_LINK_URL = "/check";
    static PROCESS_LINK_URL = "/process";

    static GET_RESOURCES_IOT_GATEWAY = "/resources/iotgateway";

    static DISPLAY_DATE_FORMAT = "DD MMM YYYY";
    static DISPLAY_DATE_TIME_FORMAT = "DD MMM YYYY HH:mm";

    static DATE_FORMAT = "YYYY-MM-DD";
    static DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm";

    static MESSAGE = "MESSAGE";
    static FLIGHT_SUGGESTION = "FLIGHT_SUGGESTION";
    static ACCOMMODATION_SUGGESTION = "ACCOMMODATION_SUGGESTION";
    static EXTRAS_SUGGESTION = "EXTRAS_SUGGESTION";
    static NEW_DESTINATION_SUGGESTION = "NEW_DESTINATION_SUGGESTION";
    static POLL = "POLL";
    static TODO_LIST = "TODO_LIST";

    static ACCOUNT_TYPE_NORMAL = "NORMAL";
    static ACCOUNT_TYPE_FACEBOOK = "FACEBOOK";
    static ACCOUNT_TYPE_GOOGLE = "GOOGLE";

    static ROUTE_DISCOVER_NEXT_DESTINATION = "/";

    static ROUTE_SELECTED_YOUR_DESTINATION = "/selectDestination";
    static ROUTE_SELECTED_YOUR_DESTINATION_CITY_DETAIL_PAGE = "/selectDestination/city/:originCityCode/:destinationCityCode" +
        "/:chosenStartDate/:chosenEndDate";

    static ROUTE_START_PLANNING_TRIP = "/selectDestination/startPlanning";
    static ROUTE_START_PLANNING_TRIP_FROM_HOME = "/startPlanning";

    static ROUTE_LOAD_TRIP = "/trip/:id";
    static ROUTE_LOAD_TRIP_CITY_DETAIL_PAGE = "/trip/:id/city/:originCityCode/:destinationCityCode/" +
        ":chosenStartDate/:chosenEndDate";
    static ROUTE_LOAD_TRIP_NEW_DESTINATION_SEARCH_RESULTS = "/trip/:id/newSearch/results";
    static ROUTE_LOAD_TRIP_NEW_DESTINATION_SEARCH_RESULTS_CONFIRM = "/trip/:id/newSearch/confirm";

    static ROUTE_APP_SHARE_SUGGESTION = "/shareSuggestion";
    static ROUTE_RESET_PASSWORD = "/resetPassword";
    static ROUTE_VERIFY_EMAIL = "/verifyEmail";

    static EXTERNAL_ROUTE_SKY_SCANNER_FLIGHTS = "https://www.skyscanner.net/transport/flights/:originCityCode/:destinationCityCode" +
        "/:outboundDate/:inboundDate?adults=:noOfAdults&children=:noOfChildren&cabinclass=economy&rtn=1&preferdirects=false" +
        "&outboundaltsenabled=false&inboundaltsenabled=false&ref=day-view#results";
    static EXTERNAL_ROUTE_GOOGLE_FLIGHTS = "https://www.google.com/flights?q=flights+:origin+to+:destination+:outboundDate+:inboundDate+:noOfAdults+adults";

    static EXTERNAL_ROUTE_EXPEDIA_ACCOMMODATION = "https://www.expedia.co.uk/Hotel-Search?destination=:destinationCityCode&" +
        "startDate=:outboundDate&endDate=:inboundDate&adults=:noOfAdults&sort=recommended";
    static EXTERNAL_ROUTE_AIRBNB_ACCOMDODATION = "https://www.airbnb.co.uk/s/homes?checkin=:outboundDate&checkout=:inboundDate" +
        "&adults=:noOfAdults&children=:noOfChildren&infants=0&toddlers=0&query=:query&map_toggle=true";

    static EXTERNAL_ROUTE_EXPEDIA_EXTRAS = "https://www.expedia.co.uk/things-to-do/search?" +
        "location=:destinationCityCode&startDate=:outboundDate&endDate=:inboundDate";
    static EXTERNAL_ROUTE_AIRBNB_EXTRAS = "https://www.airbnb.co.uk/s/experiences" +
        "?checkin=:outboundDate&checkout=:inboundDate&query=:query";
}

export default Properties;