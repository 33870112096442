import React from "react";
import LoadingComponent from "./LoadingComponent";
import Autocomplete from 'react-autocomplete'
import ToggleDisplay from 'react-toggle-display'
import FontAwesome from "react-fontawesome"

import {SingletonReferenceCacheService} from "../AppContext";
import {Button, InputGroup} from "react-bootstrap";

class DestinationSelector extends React.Component {

    constructor(props) {
        super(props);

        this.state = DestinationSelector.getInitialPersistedState(props);
        this.state.isLoadingDestinationDetails = false;
        this.state.items = [];
        this.referenceCacheService = SingletonReferenceCacheService;
    }

    static getInitialPersistedState(props) {
        return {
            citySuggestionInfo: {
                temperature: {}
            },
            selectedDestination: !!props ? props.selectedDestination : undefined,
            startTravelDate: !!props ? props.startTravelDate : undefined,
            cityCodesToIgnore: !!props && props.cityCodesToIgnore ? props.cityCodesToIgnore : []
        };
    }

    componentWillReceiveProps(nextProps) {
        const newPartialState = {};

        if (nextProps.startTravelDate !== this.state.startTravelDate) {
            newPartialState.startTravelDate = nextProps.startTravelDate;
            this.loadDestinationDetails(nextProps.selectedDestination, nextProps.startTravelDate);
        }

        newPartialState.cityCodesToIgnore = nextProps.cityCodesToIgnore ? nextProps.cityCodesToIgnore : [];

        const cityCode = this.state.citySuggestionInfo.cityCode;
        if (cityCode && newPartialState.cityCodesToIgnore.indexOf(cityCode) !== -1) {
            newPartialState.selectedDestination = undefined;
            newPartialState.citySuggestionInfo = DestinationSelector.getInitialPersistedState().citySuggestionInfo
        }

        this.setState(newPartialState);
    }

    handleTypingDestination(value) {
        this.setState({selectedDestination: value});

        if (value.length < 3) {
            this.setState({items: []});
        } else {
            const selectedCityCode = this.state.citySuggestionInfo.cityCode;
            const cityCodesToIgnore = selectedCityCode ? this.state.cityCodesToIgnore : [this.state.cityCodesToIgnore];
            this.referenceCacheService.fetchCitiesForAutocomplete(value, cityCodesToIgnore)
                .then(responseData => this.setState({items: responseData}))
        }
    }

    handleSelectingValidDestination(value) {
        this.setState({selectedDestination: value});
        let destinationCode = value.split(/([()])/)[2];
        this.handleChange(destinationCode);

        this.loadDestinationDetails(destinationCode)
            .then(responseData => this.setState({selectedDestination: this.getCityDisplayText(responseData)}));
    }

    handleChange(destination) {
        !!this.props.onSelect && this.props.onSelect(destination);
    }

    handleFinishLoading(destination) {
        this.setState({isLoadingDestinationDetails: false});
        !!this.props.onLoadDesintation && this.props.onLoadDesintation(destination);
    }

    componentWillMount() {
        this.loadDestinationDetails(this.state.selectedDestination)
            .then(responseData => this.setState({selectedDestination: this.getCityDisplayText(responseData)}));
    }

    loadDestinationDetails(destinationCode, startTravelDate = this.state.startTravelDate) {
        if (destinationCode === undefined) {
            this.setState({citySuggestionInfo: {temperature: {}}});
            return Promise.resolve({});
        }

        this.setState({
            citySuggestionInfo: DestinationSelector.getInitialPersistedState().citySuggestionInfo,
            isLoadingDestinationDetails: true
        });

        const fetchCitySuggestionInfo = (this.props.showDestinationDetails)
            ? this.referenceCacheService.fetchCitySuggestionInfo(destinationCode, startTravelDate)
            : this.referenceCacheService.fetchCity(destinationCode);


        return fetchCitySuggestionInfo
            .then(
                responseData => {
                    this.setState({citySuggestionInfo: responseData});
                    this.handleFinishLoading(destinationCode);
                    return responseData;
                },
                () => this.handleFinishLoading(destinationCode));

    }

    getCityDisplayText = city => !!city && !!city.cityName
        ? city.cityName + ", " + city.countryName + " (" + city.cityCode + ")"
        : "";

    render() {
        const cityRender = (item, isHighlighted) =>
            <div style={{background: isHighlighted ? 'lightgray' : 'white'}}>
                {this.getCityDisplayText(item)}
            </div>;

        const temperature = this.state.citySuggestionInfo.temperature ? this.state.citySuggestionInfo.temperature : {};

        return (
            <div>
                <Autocomplete
                    getItemValue={this.getCityDisplayText}
                    renderItem={cityRender}
                    items={this.state.items}
                    value={this.state.selectedDestination}
                    onChange={e => this.handleTypingDestination(e.target.value)}
                    onSelect={e => this.handleSelectingValidDestination(e)}
                    renderInput={(props) =>
                        <InputGroup className="text-width">
                            <input {...props} type="text"
                                   className="form-control"
                                   disabled={ this.props.disabled}
                                   placeholder="Enter Destination City Name" aria-label=""/>
                            {
                                this.props.showDeleteButton && <InputGroup.Button>
                                    <Button bsStyle="danger"
                                            onClick={() => this.props.onDelete && this.props.onDelete()}>x</Button>
                                </InputGroup.Button>
                            }
                        </InputGroup>
                    }
                    menuStyle={{
                        textAlign: 'left',
                        zIndex: 99
                    }}/>

                <div className="suggest-new-destination-display">
                    <div className="destination-suggestion-small col-v-centered">
                        <LoadingComponent
                            isLoading={this.props.showDestinationDetails && this.state.isLoadingDestinationDetails}
                            color="white"/>
                    </div>

                    <ToggleDisplay
                        if={this.props.showDestinationDetails && !this.state.isLoadingDestinationDetails && !!this.state.citySuggestionInfo.cityName}>
                        <div
                            style={{backgroundImage: 'url("' + this.state.citySuggestionInfo.largePictureUrl + '")'}}
                            className='destination-suggestion-small'>

                            <div className="destination-suggestion-title-small">
                                <span>{this.getCityDisplayText(this.state.citySuggestionInfo)}</span>
                            </div>

                            <div style={{minHeight: '100px', padding: '2px'}}/>

                            <div className="destination-suggestion-info" style={{padding: '5px'}}>
                                <span className="destination-suggestion-text">
                                    <FontAwesome name='sun'/>
                                    {" Low: " + temperature.low + "°C" +
                                    ", Average: " + temperature.average + "°C" +
                                    ", High: " + temperature.high + "°C"}
                                </span>
                            </div>
                        </div>

                    </ToggleDisplay>
                </div>
            </div>
        );
    }
}

export default DestinationSelector;