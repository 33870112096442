import React from "react";
import {Alert, Button, Panel} from "react-bootstrap";

import {GlobalErrorState} from '../AppContext'

class ErrorDisplayBar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            errorMessage: undefined
        }
    }

    handleToggle() {
        this.setState({errorMessage: undefined})
    }

    componentWillMount() {
        GlobalErrorState.subscribeOnState((state) => { //TODO use redux
            this.setState({
                errorMessage: state.errorMessage
            });
        });
    }

    render() {
        return (
            <div>
                <Panel expanded={!!this.state.errorMessage} onToggle={()=>{}} >
                    <Panel.Collapse className="error-display-bar">
                        <Panel.Body>
                            <Alert bsStyle="danger" className="error-alert">
                                <p>
                                    <span className="error-display-bar-title">
                                        {this.state.errorMessage}
                                    </span>
                                </p>

                                <p>
                                    <Button bsStyle="danger" onClick={() => this.handleToggle()}>
                                        Close
                                    </Button>
                                </p>
                            </Alert>
                        </Panel.Body>
                    </Panel.Collapse>
                </Panel>
            </div>
        );
    }
}

export default ErrorDisplayBar;