import * as React from "react";
import {Checkbox, Col, ControlLabel, Form, FormControl, FormGroup} from "react-bootstrap";
import FontAwesome from "react-fontawesome"
import DateUtils from "../../../../util/DateUtils";
import Properties from "../../../../util/Properties";

class ShareAccommodationSuggestion extends React.Component {

    render() {
        const checkInDate = DateUtils.dateStringSwitchFormat(this.props.parameters.checkInDate,
            Properties.DATE_FORMAT, Properties.DISPLAY_DATE_FORMAT);

        const checkOutDate = DateUtils.dateStringSwitchFormat(this.props.parameters.checkOutDate,
            Properties.DATE_FORMAT, Properties.DISPLAY_DATE_FORMAT);

        return (
            <div style={{maxWidth: '1000px', margin: '10px auto', color: 'white'}}>
                <span>
                    <b><FontAwesome name='bed'/> Share Accommodation Suggestion</b>
                </span>

                <Form componentClass="form" className="suggestion-form" horizontal>
                    <FormGroup>
                        <Col componentClass={ControlLabel} sm={2}>
                            Link
                        </Col>
                        <Col sm={10}>
                            <FormControl value={this.props.parameters.accommodationUrl} disabled={true}/>
                        </Col>
                    </FormGroup>

                    <FormGroup>
                        <Col componentClass={ControlLabel} sm={2}>
                            Hotel Name
                        </Col>
                        <Col sm={10}>
                            <FormControl value={this.props.parameters.accommodationName} disabled={true}/>
                        </Col>
                    </FormGroup>

                    <FormGroup>
                        <Col componentClass={ControlLabel} sm={2}>
                            Price
                        </Col>
                        <Col sm={10}>
                            <FormControl bsSize="sm"
                                         value={this.props.parameters.accommodationPrice +
                                         " " + this.props.parameters.accommodationPriceCurrency}
                                         disabled={true}/>
                        </Col>
                    </FormGroup>

                    <FormGroup>
                        <Col componentClass={ControlLabel} sm={2}>
                            Check-In / Check-Out
                        </Col>
                        <Col sm={10}>
                            <FormControl bsSize="sm"
                                         value={checkInDate + " / " + checkOutDate}
                                         disabled={true}/>
                        </Col>
                    </FormGroup>

                    <FormGroup>
                        <Col componentClass={ControlLabel} sm={2}>
                            Add To Itinerary
                        </Col>
                        <Col sm={10} style={{textAlign: 'left', paddingLeft: '10px'}}>
                            <Checkbox inline checked={this.props.parameters.accommodationAddToItinerary} readOnly/>
                        </Col>
                    </FormGroup>
                </Form>
            </div>
        )
    }
}

export default ShareAccommodationSuggestion;