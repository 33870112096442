import React, {ChangeEvent} from "react";
import {SingletonReferenceCacheService} from "../../../../AppContext";
import {TripItinerary} from "../../../../model/Model";
import ReferenceCacheService from "../../../../service/ReferenceCacheService";

interface Props {
    itineraries: TripItinerary[],
    onChange: (e: Event | ChangeEvent) => void,
    value: number,
    disabled: boolean
}

interface Event {
    target: {
        value: number
    }
}

class ItinerarySelection extends React.Component<Props> {

    itineraries: TripItinerary[];
    referenceCacheService: ReferenceCacheService;

    constructor(props: Props) {
        super(props);
        this.itineraries = this.props.itineraries;
        this.referenceCacheService = SingletonReferenceCacheService;
    }

    componentWillMount() {
        if (this.props.itineraries.length === 1) {
            const event = {target: {value: 0}};
            this.handleChange(event);
        }
    }

    handleChange(e: Event | ChangeEvent) {
        !!this.props.onChange && this.props.onChange(e);
    }

    render() {
        const options = this.itineraries.map((itinerary, index) =>
            <option key={index} value={index}>
                {this.referenceCacheService.getCity(itinerary.selectedDestination).name}
            </option>);

        return (
            <select className="form-control comment-text-area" id="itinerary-select"
                    value={this.props.value}
                    disabled={this.props.disabled}
                    onChange={(e) => this.handleChange(e)}>
                <option value={-1} disabled>Select Destination</option>
                {options}
            </select>
        );
    }
}

export default ItinerarySelection;