import * as React from "react";
import {Button, ButtonGroup, FormControl, FormGroup, InputGroup, Panel} from "react-bootstrap";
import FontAwesome from "react-fontawesome"
import ToggleDisplay from 'react-toggle-display'

import SessionStore from "../../../../state/SessionStore";
import {SingletonUserService} from "../../../../AppContext";

import BusyButton from "../../../../common_ui/BusyButton";
import Properties from '../../../../util/Properties'

import {Redirect} from "react-router-dom";
import ConfirmBusyButton from "../../../../common_ui/ConfirmBusyButton";
import ValidationUtils from "../../../../util/ValidationUtils";

class TripDetailsPanel extends React.Component {

    STORE_NAME = 'tripDetailsPanel';

    constructor(props) {
        super(props);
        this.state = SessionStore.getItemOrDefault(this.STORE_NAME, TripDetailsPanel.getInitialState());
        this.state = {
            ...SessionStore.getItemOrDefault(this.STORE_NAME, TripDetailsPanel.getInitialState()),
            trip: props.trip,
            expanded: false,
            isLoading: false,
            isDeleting: false,
            redirectToHome: false,
            tripCreator: {},
            tripPlanners: [],
        };
        this.userService = SingletonUserService;
    }

    static getInitialState() {
        return {
            expanded: false,
            tripCreator: {},
            tripPlanners: [],

            newEmailParticipant: "",
        }
    }

    static getTripParticipantDisplay(tripPlanner) {
        return (tripPlanner.firstName)
            ? tripPlanner.firstName + " - " + tripPlanner.emailAddress
            : tripPlanner.emailAddress;
    }

    storeState(partialState) {
        this.setState(partialState, () => SessionStore.setItem(this.STORE_NAME, this.state));
    }

    componentWillMount() {
        const tripCreatorId = this.state.trip.creatorUserId;
        const tripPlannersEmailAddresses = this.state.trip.participantEmailAddresses;

        if (tripCreatorId && tripPlannersEmailAddresses) {
            const tripCreatorPromise = this.userService.getUsersByIds([tripCreatorId]);
            const tripPlannersPromise = tripPlannersEmailAddresses.length > 0
                ? this.userService.getUsersByEmailAddresses(tripPlannersEmailAddresses)
                : Promise.resolve([]);

            Promise.all([tripCreatorPromise, tripPlannersPromise])
                .then(([tripCreatorResult, tripPlannersResult]) => {
                    this.setState({tripCreator: tripCreatorResult[0], tripPlanners: tripPlannersResult})
                });
        }
    }

    handleToggle() {
        this.storeState({expanded: !this.state.expanded});
    }

    updateTrip() {
        this._startLoading();
        return this.userService.updateUserTrip(this.state.trip)
            .then(() => window.location.reload(), () => window.location.reload());
    }

    deleteTrip() {
        this._startDeleting();
        return this.userService.deleteTrip(this.state.trip.id)
            .then(
                () => this.setState({isDeleting: false, redirectToHome: true}),
                () => this._stopDeleting());
    }

    addTripParticipant() {
        this.userService.getUsersByEmailAddresses([this.state.newEmailParticipant])
            .then(newTripPlanners => {
                let updatedTrip = this.state.trip;
                updatedTrip.participantEmailAddresses.push(this.state.newEmailParticipant);

                const updatedTripPlanners = this.state.tripPlanners.concat(newTripPlanners);

                this.storeState({trip: updatedTrip, tripPlanners: updatedTripPlanners, newEmailParticipant: ""})
            });
    }

    removeTripParticipant(index) {
        let updatedTrip = this.state.trip;
        updatedTrip.participantEmailAddresses.splice(index, 1);

        let updatedTripPlanners = this.state.tripPlanners;
        updatedTripPlanners.splice(index, 1);

        this.storeState({trip: updatedTrip, tripPlanners: updatedTripPlanners})
    }

    getItineraryValue(fieldName) {
        return this.state.trip.potentialItineraries.length > 0
            ? this.state.trip.potentialItineraries[0][fieldName]
            : undefined
    }

    setAllItineraryValue(fieldName, newValue) {
        const trip = this.state.trip;
        trip.potentialItineraries.forEach(itinerary => {
            itinerary[fieldName] = newValue;
        });

        this.storeState({trip: trip});
    }

    _startLoading() {
        return this.setState({isLoading: true});
    }

    _startDeleting() {
        return this.setState({isDeleting: true});
    }

    _stopDeleting() {
        return this.setState({isDeleting: false});
    }

    render() {
        if (this.state.redirectToHome) {
            return <Redirect to={Properties.ROUTE_DISCOVER_NEXT_DESTINATION}/>
        }

        const handleEmailAddressKeyPress = (e) => {
            if (e.key === 'Enter' && !e.shiftKey && ValidationUtils.validateEmail(this.state.newEmailParticipant) === 'success') {
                this.addTripParticipant();
                e.preventDefault();
            }
        };

        const userLoggedIn = this.userService.isUserLoggedIn();

        const tripPlanners = this.state.tripPlanners.map((tripPlanner, index) =>
            <div className="trip-creator" key={index}>
                <InputGroup>
                    <FormControl type="text"
                                 className="form-control input-sm"
                                 value={TripDetailsPanel.getTripParticipantDisplay(tripPlanner)} readOnly={true}/>
                    <InputGroup.Button>
                        <Button bsStyle="danger"
                                disabled={!userLoggedIn}
                                onClick={(e) => this.removeTripParticipant(index)}>x</Button>
                    </InputGroup.Button>
                </InputGroup>
            </div>
        );

        const numberOfAdultsOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9].map((number) =>
            <option key={number} value={number}>{number}</option>);

        const numberOfChildrenOptions = [0, 1, 2, 3, 4, 5, 6].map((number) =>
            <option key={number} value={number}>{number}</option>);


        return (
            <div className="trip-details-panel">
                <ToggleDisplay if={userLoggedIn}>
                    <Panel expanded={this.state.expanded} onToggle={() => this.handleToggle()}>
                        <Panel.Heading className="trip-details-panel-header">
                            <div>
                                <Panel.Title toggle>
                                    <span>
                                        {this.props.trip.tripName + " "}
                                        <FontAwesome name={this.state.expanded ? 'caret-down' : 'caret-left'}/>
                                    </span>
                                </Panel.Title>
                            </div>
                        </Panel.Heading>
                        <Panel.Collapse>
                            <Panel.Body className="trip-details-panel-body">
                                <div className="trip-creator">
                                    <FormControl type="text"
                                                 className="form-control input-sm"
                                                 value={TripDetailsPanel.getTripParticipantDisplay(this.state.tripCreator) + " (Trip Creator)"}
                                                 readOnly={true}/>
                                </div>
                                {tripPlanners}

                                <div className="trip-creator">
                                    <FormGroup
                                        validationState={ValidationUtils.validateEmail(this.state.newEmailParticipant)}>
                                        <InputGroup>
                                            <InputGroup.Addon>@</InputGroup.Addon>
                                            <FormControl type="text"
                                                         className="form-control input-sm"
                                                         value={this.state.newEmailParticipant}
                                                         placeholder="Enter additional email address"
                                                         onKeyPress={handleEmailAddressKeyPress}
                                                         onChange={(e) => this.storeState({newEmailParticipant: e.target.value})}/>
                                        </InputGroup>
                                        <FormControl.Feedback/>

                                        <Button bsStyle="primary"
                                                bsSize="small"
                                                disabled={'success' !== ValidationUtils.validateEmail(this.state.newEmailParticipant)}
                                                onClick={(e) => this.addTripParticipant()}>
                                            Add Email
                                        </Button>
                                    </FormGroup>
                                </div>

                                <div className="trip-creator">
                                    <div style={{width: '50%', float: 'left', padding: '10px'}}>
                                        <label htmlFor="no-of-adults-select" style={{color: 'white'}}>Adults
                                            (18+)</label>
                                        <select className="form-control input-sm" id="no-of-adults-select"
                                                value={this.getItineraryValue('noOfAdults')}
                                                onChange={(e) => this.setAllItineraryValue('noOfAdults', e.target.value)}>
                                            {numberOfAdultsOptions}
                                        </select>
                                    </div>

                                    <div style={{width: '50%', float: 'right', padding: '10px'}}>
                                        <label htmlFor="no-of-children-select" style={{color: 'white'}}>Children</label>
                                        <select className="form-control input-sm" id="no-of-children-select"
                                                value={this.getItineraryValue('noOfChildren')}
                                                onChange={(e) => this.setAllItineraryValue('noOfChildren', e.target.value)}>
                                            {numberOfChildrenOptions}
                                        </select>
                                    </div>
                                </div>

                                <ButtonGroup>
                                    <BusyButton bsStyle="primary"
                                                onClick={() => this.updateTrip()}
                                                disabled={this.state.isLoading || this.state.isDeleting}
                                                buttonText="Save"
                                                loadingMessage="Saving..."
                                                isLoading={this.state.isLoading}/>

                                    <ConfirmBusyButton bsStyle="danger"
                                                       confirmMessage="Are you sure you want to delete this trip?"
                                                       onClick={() => this.deleteTrip()}
                                                       disabled={this.state.isLoading || this.state.isDeleting}
                                                       buttonText="Delete Trip"
                                                       loadingMessage="Deleting..."
                                                       isLoading={this.state.isDeleting}/>
                                </ButtonGroup>
                            </Panel.Body>
                        </Panel.Collapse>
                    </Panel>
                </ToggleDisplay>

                <ToggleDisplay if={!userLoggedIn}>
                    <Panel>
                        <Panel.Heading className="trip-details-panel-header">
                            <div>
                                <Panel.Title>
                                    <span>
                                        {this.props.trip.tripName}
                                    </span>
                                </Panel.Title>
                            </div>
                        </Panel.Heading>
                    </Panel>
                </ToggleDisplay>
            </div>);
    }

}

export default TripDetailsPanel;