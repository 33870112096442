import mqtt from 'mqtt';
import HttpUtilService from "./HttpUtilService";
import Properties from '../util/Properties';
import {IClientOptions, MqttClient} from "mqtt/types/lib/client";

const validateClientConnected = (client: MqttClient | undefined) => {
    if (!client) {
        throw new Error("Client is not connected yet. Call client.connect() first!");
    }
};

const BASE_URL = process.env.REACT_APP_RESOURCES_API || "";
const httpUtilService = new HttpUtilService();

type Callback = (...args: any[]) => void;

interface WebSocketClient {
    connect: (topics: string[]) => Promise<WebSocketClient>,
    onConnect: (callback: Callback) => WebSocketClient
    onDisconnect: (callback: Callback) => WebSocketClient,
    onMessageReceived: (callback: Callback) => WebSocketClient,
    sendMessage: (message: any, topic: string) => WebSocketClient
}

export default (): WebSocketClient => {
    const options: IClientOptions = {
        will: {
            topic: "client-connection",
            payload: "{}",
            qos: 1,
            retain: false
        }
    };

    let client: MqttClient | undefined = undefined;

    const connect = async (topics: string []) => {
        if (!client) {
            const url = await httpUtilService.get(Properties.GET_RESOURCES_IOT_GATEWAY, BASE_URL).then(response => response.data.url);
            client = mqtt.connect(url, options);

            client.on('close', () => {
                client?.end();
            });
        }

        topics.forEach(topic => client?.subscribe(topic));
        return clientWrapper;
    };

    const onConnect = (callback: Callback) => {
        validateClientConnected(client);
        client?.on('connect', callback);
        return clientWrapper;
    };
    const onDisconnect = (callback: Callback) => {
        validateClientConnected(client);
        client?.on('close', callback);
        return clientWrapper;
    };
    const onMessageReceived = (callback: Callback) => {
        validateClientConnected(client);
        client?.on('message', (topic, message) => {
            callback(topic, JSON.parse(message.toString()));
        });
        return clientWrapper;
    };
    const sendMessage = (message: any, topic: string) => {
        validateClientConnected(client);
        client?.publish(topic, JSON.stringify(message));
        return clientWrapper;
    };

    const clientWrapper = {
        connect: connect,
        onConnect: onConnect,
        onDisconnect: onDisconnect,
        onMessageReceived: onMessageReceived,
        sendMessage: sendMessage
    };
    return clientWrapper;
};