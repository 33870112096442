import React from 'react'
import {Button, ButtonGroup, FormControl, FormGroup, InputGroup, Radio} from 'react-bootstrap'
import SessionStore from "../../state/SessionStore";
import ValidationUtils from "../../util/ValidationUtils";
import SignUpLogInModal from "../../common_ui/SignUpLogInModal";
import {Redirect} from "react-router-dom";
import {
    SingletonAnalyticsService,
    SingletonReferenceCacheService,
    SingletonTripCreationSharedState,
    SingletonUserService
} from "../../AppContext";
import Properties from '../../util/Properties'
import BusyButton from "../../common_ui/BusyButton";
import DestinationDisplay from "../../common_ui/DestinationDisplay";
import StarRatings from 'react-star-ratings'

class InlineSurvey extends React.PureComponent {

    STORE_NAME = 'startPlanningTripSurvey';

    static getInitialState() {
        return {
            rating: 0,
            commentText: undefined,
            isDestinationMatch: undefined,
            showExtras: false,
            hasSubmitted: false
        };
    }

    constructor(props) {
        super(props);
        const initialStore = this.props.location.search === "?resetSurvey"
            ? InlineSurvey.getInitialState()
            : SessionStore.getItemOrDefault(this.STORE_NAME, InlineSurvey.getInitialState())

        this.state = {
            ...initialStore,
            isSubmitting: false,
            hideSurvey: initialStore.hasSubmitted
        };
        this.userService = SingletonUserService;
        this.analyticsService = SingletonAnalyticsService;
    }

    storeState(partialState) {
        this.setState(partialState, () => SessionStore.setItem(this.STORE_NAME, this.state));
    }

    onChangeRating(rating) {
        this.storeState({rating: rating, showExtras: true})
    }

    onSubmit() {
        if (!this.state.hasSubmitted) {
            this.storeState({isSubmitting: true});
            const request = {
                rating: this.state.rating,
                page: '/selectDestination/startPlanning',
                comment: this.state.commentText,
                additionalData: {
                    isDestinationMatch: this.state.isDestinationMatch && this.state.isDestinationMatch === "true",
                    travelParameters: this.props.travelParameters,
                    selectedDestinationSuggestions: this.props.selectedDestinationSuggestions
                }
            };
            this.analyticsService.postSurveyFeedback(request).then(
                () => this.storeState({hasSubmitted: true, isSubmitting: false}),
                () => this.storeState({hasSubmitted: false, isSubmitting: false})
            );
        }
    }

    validateSurvey() {
        return ValidationUtils.validateLength(this.state.commentText, 1, 1000);
    }

    render() {
        if (this.state.hideSurvey) {
            return null;
        }

        const changeRating = !this.state.hasSubmitted ? ((rating) => this.onChangeRating(rating)) : null;

        return (
            <div className="inline-survey">
                <div>
                    <span>How would you rate Tripsavvi?</span>
                    <br/>
                    <StarRatings
                        disabled={this.state.hasSubmitted}
                        isSelectable={true}
                        rating={this.state.rating}
                        changeRating={changeRating}
                        numberOfStars={5}
                        starRatedColor={"black"}
                        starHoverColor={"black"}
                        name='rating'
                        starDimension='30px'
                        starSpacing='7px'/>

                </div>

                {this.state.showExtras && <React.Fragment>
                    <div className="fade">
                        <span>Did these destinations match your search?</span>
                        <FormGroup>
                            <Radio disabled={this.state.hasSubmitted} name="radioGroup" value={true} inline
                                   onChange={(e) => this.setState({isDestinationMatch: e.target.value})}>
                                Yes</Radio>{' '}
                            <Radio disabled={this.state.hasSubmitted} name="radioGroup" value={false} inline
                                   onChange={(e) => this.setState({isDestinationMatch: e.target.value})}>
                                No</Radio>{' '}
                        </FormGroup>
                    </div>

                    <FormGroup validationState={this.validateSurvey()}>
                        <div className="fade">
                            <FormControl componentClass="textarea"
                                         bsSize="sm"
                                         placeholder="What would improve your experience?"
                                         rows="3"
                                         id="comment"
                                         value={this.state.commentText}
                                         onChange={(e) => this.setState({commentText: e.target.value})}
                                         disabled={this.state.hasSubmitted}/>
                        </div>

                        <div className="fade">
                            <BusyButton onClick={() => this.onSubmit()}
                                        disabled={this.validateSurvey() !== 'success' || this.state.hasSubmitted}
                                        isLoading={this.state.isSubmitting}
                                        buttonText={this.state.hasSubmitted ? 'Thanks for your feedback' : 'Submit'}
                                        loadingMessage={'Submitting...'}/>
                        </div>
                    </FormGroup>
                </React.Fragment>}
            </div>
        )
    }
}

class StartPlanningTrip extends React.Component {

    STORE_NAME = 'selectedDestinations';

    static getInitialState() {
        return {
            selectedDestinationSuggestions: {},
            tripName: '',
            emailsToSend: [],
            newEmailToSend: '',
            travelParameters: {}
        };
    }

    constructor(props) {
        super(props);
        this.state = SessionStore.getItemOrDefault(this.STORE_NAME, StartPlanningTrip.getInitialState());
        this.state.loadTrip = undefined;
        this.state.back = false;
        this.state.showSignUp = false;
        this.state.isCreatingTrip = false;

        this.referenceCacheService = SingletonReferenceCacheService;
        this.userService = SingletonUserService;
        this.sharedState = SingletonTripCreationSharedState;
    }

    storeState(partialState) {
        this.setState(partialState, () => SessionStore.setItem(this.STORE_NAME, this.state));
    }

    componentWillMount() {
        this.sharedState.subscribeOnState((state) => { //TODO use redux
            this.storeState({
                selectedDestinationSuggestions: state.selectedDestinationSuggestions,
                travelParameters: state.travelParameters
            });
        });
    }

    addEmail() {
        let emailsToSend = this.state.emailsToSend;
        emailsToSend.push(this.state.newEmailToSend);
        this.storeState({emailsToSend: emailsToSend, newEmailToSend: ''})
    }

    removeEmail(index) {
        let emailsToSend = this.state.emailsToSend;
        emailsToSend.splice(index, 1);
        this.storeState({emailsToSend: emailsToSend})
    }

    handlePlanTrip() {
        if (!this.userService.isUserLoggedIn()) {
            this.setState({showSignUp: true});
        } else {
            this.postTrip();
        }
    }

    postTrip() {
        const selectedDestinations = Object
            .values(this.state.selectedDestinationSuggestions)
            .map((suggestion, index) => suggestion.cityCode);

        const userTripRequest = {
            tripName: this.state.tripName,
            originCityCode: this.state.travelParameters.originCityCode,
            selectedDestinations: selectedDestinations,
            participantEmailAddresses: this.state.emailsToSend,
            startTravelDate: this.state.travelParameters.startTravelDate,
            endTravelDate: this.state.travelParameters.endTravelDate,
            noOfChildren: this.state.travelParameters.noOfChildren,
            noOfAdults: this.state.travelParameters.noOfAdults,
        };

        this.setState({isCreatingTrip: true});
        return this.userService.saveUserTrip(userTripRequest)
            .then((data) => this.handleNext(data.savedTrip))
            .then(() => this.setState({isCreatingTrip: false}), () => this.setState({isCreatingTrip: false}));
    }

    validateCreateTrip() {
        return ValidationUtils.combineValidationResults([
            ValidationUtils.validateLength(this.state.tripName, 0, 50),
        ]);
    }

    handleNext(trip) {
        this.setState({loadTrip: trip.id});
    }

    render() {
        if (this.state.loadTrip) {
            return <Redirect to={Properties.ROUTE_LOAD_TRIP.replace(":id", this.state.loadTrip)}/>
        }

        const suggestions = Object.values(this.state.selectedDestinationSuggestions).map((suggestion, index) =>
            <DestinationDisplay id={'destination-display' + index}
                                key={index}
                                destinationCityCode={suggestion.cityCode}
                                startTravelDate={this.state.travelParameters.startTravelDate}
                                endTravelDate={this.state.travelParameters.endTravelDate}/>);

        const emailEntries = this.state.emailsToSend.map((email, index) =>
            <InputGroup key={index}>
                <FormControl type="text" value={email} readOnly={true}/>
                <InputGroup.Button>
                    <Button bsStyle="danger"
                            disabled={this.state.isCreatingTrip}
                            onClick={() => this.removeEmail(index)}>x</Button>
                </InputGroup.Button>
            </InputGroup>
        );
        return (
            <div>
                <SignUpLogInModal title="Please Sign In to Plan Your Trip"
                                  show={this.state.showSignUp}
                                  logInFunction={() => this.postTrip()}
                                  closeFunction={() => this.setState({showSignUp: false})}/>

                <div className="create-trip-section">
                    <div className="find-destination-section">
                        <div>
                            <p>Please enter a trip name</p>

                            <FormGroup validationState={ValidationUtils.validateLength(this.state.tripName, 0, 50)}>
                                <FormControl type="text" className="form-control"
                                             value={this.state.tripName}
                                             disabled={this.state.isCreatingTrip}
                                             onChange={(e) => this.storeState({tripName: e.target.value})}/>
                                <FormControl.Feedback/>
                            </FormGroup>
                        </div>

                        <div>
                            <p>{Object.keys(this.state.selectedDestinationSuggestions).length > 1
                                ? 'Destination shortlist' : 'Selected destination'}</p>

                            <div className="destination-suggestion-holder" style={{marginTop: '0', paddingTop: '5px'}}>
                                {suggestions}
                            </div>
                        </div>

                        <div>
                            <p>Plan trip with others</p>
                            <FormGroup>
                                {emailEntries}
                            </FormGroup>
                            <FormGroup validationState={ValidationUtils.validateEmail(this.state.newEmailToSend)}>
                                <InputGroup>
                                    <InputGroup.Addon>@</InputGroup.Addon>
                                    <FormControl type="text"
                                                 value={this.state.newEmailToSend}
                                                 disabled={this.state.isCreatingTrip}
                                                 placeholder="Enter email address"
                                                 onChange={(e) => this.storeState({newEmailToSend: e.target.value})}/>
                                </InputGroup>
                                <FormControl.Feedback/>

                                <Button bsStyle="primary"
                                        onClick={(e) => this.addEmail()}
                                        disabled={'success' !== ValidationUtils.validateEmail(this.state.newEmailToSend) || this.state.isCreatingTrip}>
                                    Add Email
                                </Button>
                            </FormGroup>

                        </div>
                    </div>


                    <ButtonGroup>
                        <Button bsSize="large" href={this.props.backPageRoute}>Back</Button>
                        <BusyButton bsSize="large" bsStyle="primary" onClick={(e) => this.handlePlanTrip()}
                                    buttonText="Plan Your Trip!"
                                    loadingMessage="Creating Trip..."
                                    disabled={"success" !== this.validateCreateTrip()}
                                    isLoading={this.state.isCreatingTrip}/>
                    </ButtonGroup>
                </div>

                <InlineSurvey {...this.props}
                              travelParameters={this.state.travelParameters}
                              selectedDestinationSuggestions={this.state.selectedDestinationSuggestions}/>
            </div>
        );
    }

}

export default StartPlanningTrip;