import React from "react";
import {Button, ButtonGroup, FormControl, FormGroup} from "react-bootstrap";
import BusyButton from "../../../../../common_ui/BusyButton";
import ValidationUtils from "../../../../../util/ValidationUtils";

class PostMessageInputs extends React.Component {

    validatePostMessage() {
        return ValidationUtils.validateLength(this.props.newCommentText, 1, 1000);
    }

    handlePostSuggestion() {
        return this.props.postComment()
            .then(() => this.clearInputs())
    }

    clearInputs() {
        this.props.setNewCommentFunction(undefined);
    }

    render() {
        const handleOnChange = (e) => this.props.setNewCommentFunction(e.target.value);
        const handlePostMessage = () => this.handlePostSuggestion();
        const handleKeyPress = (e) => {
            if (e.key === 'Enter' && !e.shiftKey && this.validatePostMessage() === 'success') {
                this.handlePostSuggestion();
                e.preventDefault();
            }
        };

        return (
            <FormGroup validationState={this.validatePostMessage()}>
                <FormControl componentClass="textarea"
                             bsSize="sm"
                             placeholder="Enter message here" className="comment-text-area"
                             rows="2"
                             id="comment"
                             value={this.props.newCommentText}
                             disabled={this.props.isPostingMessage}
                             onChange={handleOnChange}
                             onKeyPress={handleKeyPress}/>
                <FormControl.Feedback/>

                <ButtonGroup>
                    <BusyButton bsStyle="primary"
                                disabled={"success" !== this.validatePostMessage()}
                                onClick={handlePostMessage}
                                buttonText="Post Message"
                                loadingMessage="Saving..."
                                isLoading={this.props.isPostingMessage}/>
                    <Button onClick={() => this.props.onCancel()}>
                        Cancel
                    </Button>
                </ButtonGroup>
            </FormGroup>
        );
    }

}

export default PostMessageInputs;