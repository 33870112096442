import SessionStore from './SessionStore'

type Callback<T> = (state: T) => void;

class SharedState<T> {

    stateName: string;
    state: T;
    storeStateInLocalStorage: boolean;
    callbacks: Callback<T>[];

    constructor(stateName: string, initialState: any, storeStateInLocalStorage: boolean = true) {
        this.stateName = stateName;

        const initState = !!initialState ? initialState : {};
        this.state = storeStateInLocalStorage
            ? SessionStore.getItemOrDefault(this.stateName, initState)
            : initState;

        this.storeStateInLocalStorage = storeStateInLocalStorage;
        this.callbacks = [];
    }

    subscribeOnState(callback: Callback<T>) {
        callback(this.state);
        this.callbacks.push(callback);
    }

    setState(partialState: Partial<T>) {
        Object.assign(this.state, partialState);

        if (this.storeStateInLocalStorage) {
            SessionStore.setItem(this.stateName, this.state);
        }

        this.callbacks.forEach((callback) => {
            callback(this.state);
        })
    };

}

export default SharedState;