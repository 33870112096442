import React from "react";
import ToggleDisplay from 'react-toggle-display'
import DatePicker from 'react-datepicker'

import {ItineraryDateUtils} from "./ItineraryDateUtils";
import {ItineraryComponent, ItineraryProps} from "./ItineraryComponent";
import {ConfirmEdit} from "../../../../common_ui/ConfirmEdit";
import DateUtils from "../../../../util/DateUtils";
import Properties from '../../../../util/Properties'
import {FormControl} from "react-bootstrap";
import {SingletonUserService} from "../../../../AppContext";
import UserService from "../../../../service/UserService";

class Accommodation extends ItineraryComponent {

    userService: UserService;

    constructor(props: ItineraryProps) {
        super(props);
        this.userService = SingletonUserService;
    }

    setStartDate(startDate: string | undefined) {
        if (!startDate) {
            return;
        }

        const itineraryItem = {...this.state.itineraryItem};
        this.setState({itineraryItem: {...itineraryItem, startDate: startDate}})
    }

    setAccommodationName(accommodationName: string) {
        const itineraryItem = {...this.state.itineraryItem};
        this.setState({itineraryItem: {...itineraryItem, accommodationName: accommodationName,}})
    }

    render() {
        return (
            <div>
                <div className="itinerary-item hotel-item">
                    <div className="flight-outbound-details col-v-centered">
                        <span>
                            <span className="itinerary-item-flight">
                                {this.props.title}
                            </span>
                        </span>

                        <i className="fas fa-bed"/>

                        <span className="itinerary-item-flight">
                            <ToggleDisplay if={!this.state.editMode}>
                                {ItineraryDateUtils.formatTime(this.state.itineraryItem.startDate)}
                            </ToggleDisplay>

                            <ToggleDisplay if={this.state.editMode}>
                                 <DatePicker id="travel-end-date"
                                             selected={DateUtils.stringToMoment(this.state.itineraryItem.startDate)}
                                             onChange={(newDate) => this.setStartDate(DateUtils.momentToString(Properties.DATE_TIME_FORMAT, newDate))}
                                             className="form-control"
                                             dateFormat={Properties.DISPLAY_DATE_TIME_FORMAT}
                                             showMonthDropdown
                                             showTimeSelect
                                             withPortal/>
                            </ToggleDisplay>
                        </span>
                    </div>

                    <span>
                        <ToggleDisplay if={!this.state.editMode}>
                            <a href={this.state.itineraryItem.pageLink} target="_blank" rel="noopener noreferrer">
                                {this.state.itineraryItem.accommodationName + ' '}
                            </a>
                        </ToggleDisplay>

                        <ToggleDisplay if={this.state.editMode}>
                            <FormControl type="text"
                                         id="accommodation-name"
                                         value={this.state.itineraryItem.accommodationName}
                                         onChange={(e: any) => this.setAccommodationName(e.target.value)}
                                         placeholder="Enter accommodation name"/>
                        </ToggleDisplay>
                    </span>
                    <ToggleDisplay if={this.state.itineraryItem.cost.amount > 0}>
                        <div className="flight-price">
                            <span>
                                {this.state.itineraryItem.cost.amount + ' ' + this.state.itineraryItem.cost.currency}
                            </span>
                        </div>
                    </ToggleDisplay>

                    <div>
                        <ConfirmEdit editMode={this.state.editMode}
                                     onStartEdit={() => this.setEditMode(true)}
                                     onStopEdit={() => this.setEditMode(false)}
                                     onSave={() => this.onSave()}
                                     disabled={!this.userService.isUserLoggedIn()}
                                     onCancel={() => this.onCancel()}/>
                    </div>
                </div>

            </div>
        );
    }
}

export {Accommodation};