import React from "react";
import BusyButton from "./BusyButton";
import {Button, ButtonGroup, Modal} from "react-bootstrap";

class ConfirmBusyButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modalShow: false
        }
    }

    handleOpenModal() {
        this.setState({modalShow: true})
    }

    handleOnConfirm(e, onClick) {
        this.setState({modalShow: false});
        onClick(e);
    }

    handleClose() {
        this.setState({modalShow: false})
    }

    render() {
        const {confirmMessage, buttonText, bsStyle, onClick, ...other} = this.props;

        return (
            <>
                <BusyButton bsStyle={bsStyle}
                            buttonText={buttonText}
                            onClick={() => this.handleOpenModal()} {...other}/>

                <Modal show={this.state.modalShow} onHide={() => this.handleClose}>
                    <Modal.Body>
                        <b>{confirmMessage}</b>
                    </Modal.Body>
                    <Modal.Footer>
                        <ButtonGroup>
                            <Button bsStyle={bsStyle} onClick={(e) => this.handleOnConfirm(e, onClick)}>
                                {buttonText}
                            </Button>
                            <Button onClick={(e) => this.handleClose()}>
                                Close
                            </Button>
                        </ButtonGroup>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }

}

export default ConfirmBusyButton;