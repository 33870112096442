import HttpUtilService from "./HttpUtilService";
import Properties from '../util/Properties';

class AnalyticsService {
    USER_ANALYTICS_FEEDBACK_URL: string;
    httpUtilService: HttpUtilService;

    constructor() {
        this.USER_ANALYTICS_FEEDBACK_URL = process.env.REACT_APP_ANALYTICS_FEEDBACK_API || "";
        this.httpUtilService = new HttpUtilService();
    }

    logPageView() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this._logPageViewWithPosition, this._logPageView, {
                timeout: 5000,
            });
        }

        return this._logPageView();

    }

    postSurveyFeedback(request: any) {
        return this.httpUtilService.post(Properties.POST_USER_ANALYTICS_FEEDBACK, this.USER_ANALYTICS_FEEDBACK_URL, request)
            .then((response) => response.data);
    }

    _logPageViewWithPosition(position: Position) {
        const request = {
            eventType: 'PAGE_VIEW',
            page: window.location.href,
            additionalData: {
                userAgent: navigator.userAgent,
                language: navigator.language,
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
            }
        };
        return this._logEvent(request);
    }

    _logPageView() {
        const request = {
            eventType: 'PAGE_VIEW',
            page: window.location.href,
            additionalData: {
                userAgent: navigator.userAgent,
                language: navigator.language,
                latitude: 'UNKNOWN',
                longitude: 'UNKNOWN'
            }
        };
        return this._logEvent(request);
    }

    _logEvent(request: any) {
        return this.httpUtilService.post(Properties.POST_USER_ANALYTICS_EVENT, this.USER_ANALYTICS_FEEDBACK_URL, request, {defaultErrorHandling: false})
            .then((response) => response.data);
    }
}

export default AnalyticsService;