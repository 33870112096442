import * as React from "react";
import {Button, ButtonGroup, Checkbox, FormControl, FormGroup, InputGroup} from "react-bootstrap";
import SessionStore from "../../../../../state/SessionStore";
import BusyButton from "../../../../../common_ui/BusyButton";
import ValidationUtils from "../../../../../util/ValidationUtils";

class NewPollInputs extends React.Component {

    STORE_NAME = 'newPoll';

    static getInitialState() {
        return {
            pollQuestion: undefined,
            pollOptions: [undefined, undefined, undefined],
            pollAllowMultipleVotes: false
        };
    }

    constructor(props) {
        super(props);
        this.state = SessionStore.getItemOrDefault(this.STORE_NAME, NewPollInputs.getInitialState())
    }

    storeState(partialState, callback) {
        return this.setState(partialState, () => {
            callback && callback();
            return SessionStore.setItem(this.STORE_NAME, this.state);
        });
    }

    changePollOptionValue(newValue, index) {
        const options = this.state.pollOptions;
        options[index] = newValue;
        this.storeState({pollOptions: options})
    }

    addPollOption() {
        const options = this.state.pollOptions;
        options.push(undefined);
        this.storeState({pollOptions: options})
    }

    removePollOption(index) {
        const options = this.state.pollOptions;
        options.splice(index, 1);
        this.storeState({pollOptions: options})
    }

    handlePostNewPoll() {
        const poll = {
            question: this.state.pollQuestion,
            options: this.state.pollOptions,
            allowMultipleVotes: this.state.pollAllowMultipleVotes
        };

        return this.props.postNewPoll(poll)
            .then(() => this.clearInputs());
    }

    clearInputs() {
        this.storeState(NewPollInputs.getInitialState());
    }

    validateNewPoll() {
        const optionsValidations = this.state.pollOptions
            .map(it => ValidationUtils.validateLength(it, 1, 80));

        return ValidationUtils.combineValidationResults([
            ValidationUtils.validateLength(this.state.pollQuestion, 1, 80),
            ...optionsValidations
        ]);
    }


    render() {
        const pollOptions = this.state.pollOptions.map((option, index) => {
            return (
                <InputGroup key={index} className="new-poll-option-inputs" bsSize="sm">
                    <FormControl type="text"
                                 placeholder={index + 1 + ') Enter a poll option'}
                                 id={'poll-option-' + index}
                                 value={option}
                                 disabled={this.props.isPostingMessage}
                                 onChange={(e) => this.changePollOptionValue(e.target.value, index)}/>
                    <InputGroup.Button>
                        <Button bsStyle="danger"
                                disabled={index === 0 || this.props.isPostingMessage}
                                onClick={(e) => this.removePollOption(index)}>x</Button>
                    </InputGroup.Button>
                </InputGroup>
            );
        });

        return (
            <div className="new-poll-option-inputs-holder">
                <FormGroup validationState={this.validateNewPoll()}>
                    <FormControl type="text"
                                 className="new-poll-option-inputs"
                                 id='poll-question'
                                 value={this.state.pollQuestion}
                                 placeholder="Enter a poll question e.g Where do you want to go?"
                                 onChange={(e) => this.storeState({pollQuestion: e.target.value})}
                                 disabled={this.props.isPostingMessage}/>
                    <hr/>
                    {pollOptions}
                    <Button disabled={this.state.pollOptions.length >= 5 || this.props.isPostingMessage}
                            bsSize="sm"
                            onClick={(e) => this.addPollOption()}>Add option</Button>
                </FormGroup>

                <Checkbox defaultChecked={this.state.pollAllowMultipleVotes}
                          onChange={(e) => this.storeState({pollAllowMultipleVotes: e.target.checked})}
                          disabled={this.props.isPostingMessage}>
                    Allow votes for multiple options
                </Checkbox>

                <ButtonGroup>
                    <BusyButton bsStyle="primary" onClick={(e) => this.handlePostNewPoll()}
                                buttonText="New Poll"
                                loadingMessage="Saving..."
                                disabled={"success" !== this.validateNewPoll() || this.props.isPostingMessage}
                                isLoading={this.props.isPostingMessage}/>
                    <Button disabled={this.props.isPostingMessage}
                            onClick={() => this.props.onCancel()}>
                        Cancel
                    </Button>
                </ButtonGroup>
            </div>
        )
    }
}

export default NewPollInputs;