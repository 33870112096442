import * as React from "react";
import {Checkbox, Col, ControlLabel, Form, FormControl, FormGroup} from "react-bootstrap";
import FontAwesome from "react-fontawesome"
import DateUtils from "../../../../util/DateUtils";
import Properties from "../../../../util/Properties";

class ShareOtherSuggestion extends React.Component {

    render() {
        const extrasStartDate = DateUtils.dateStringSwitchFormat(this.props.parameters.extrasStartDate,
            Properties.DATE_TIME_FORMAT, Properties.DISPLAY_DATE_TIME_FORMAT);

        const extrasEndDate = DateUtils.dateStringSwitchFormat(this.props.parameters.extrasEndDate,
            Properties.DATE_TIME_FORMAT, Properties.DISPLAY_DATE_TIME_FORMAT);

        return (
            <div style={{maxWidth: '1000px', margin: '10px auto', color: 'white'}}>
                <span>
                    <b><FontAwesome name='glass-martini'/> Share Other Suggestion</b>
                </span>

                <Form componentClass="form" className="suggestion-form" horizontal>
                    <FormGroup>
                        <Col componentClass={ControlLabel} sm={2}>
                            Link
                        </Col>
                        <Col sm={10}>
                            <FormControl value={this.props.parameters.extrasUrl} disabled={true}/>
                        </Col>
                    </FormGroup>

                    <FormGroup>
                        <Col componentClass={ControlLabel} sm={2}>
                            Activity Name
                        </Col>
                        <Col sm={10}>
                            <FormControl value={this.props.parameters.extrasName} disabled={true}/>
                        </Col>
                    </FormGroup>

                    <FormGroup>
                        <Col componentClass={ControlLabel} sm={2}>
                            Category
                        </Col>
                        <Col sm={10}>
                            <FormControl value={this.props.parameters.extrasCategory} disabled={true}/>
                        </Col>
                    </FormGroup>

                    <FormGroup>
                        <Col componentClass={ControlLabel} sm={2}>
                            Description
                        </Col>
                        <Col sm={10}>
                            <FormControl value={this.props.parameters.extrasDescription} disabled={true}/>
                        </Col>
                    </FormGroup>

                    <FormGroup>
                        <Col componentClass={ControlLabel} sm={2}>
                            Price
                        </Col>
                        <Col sm={10}>
                            <FormControl bsSize="sm"
                                         value={this.props.parameters.extrasPrice +
                                         " " + this.props.parameters.extrasPriceCurrency}
                                         disabled={true}/>
                        </Col>
                    </FormGroup>

                    <FormGroup>
                        <Col componentClass={ControlLabel} sm={2}>
                            Start / End Time
                        </Col>
                        <Col sm={10}>
                            <FormControl bsSize="sm"
                                         value={extrasStartDate + " / " + extrasEndDate}
                                         disabled={true}/>
                        </Col>
                    </FormGroup>

                    <FormGroup>
                        <Col componentClass={ControlLabel} sm={2}>
                            Add To Itinerary
                        </Col>
                        <Col sm={10} style={{textAlign: 'left', paddingLeft: '10px'}}>
                            <Checkbox inline checked={this.props.parameters.extrasAddToItinerary} readOnly/>
                        </Col>
                    </FormGroup>
                </Form>
            </div>
        )
    }

}

export default ShareOtherSuggestion;