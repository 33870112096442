import * as React from "react";
import {Checkbox, Col, ControlLabel, Form, FormControl, FormGroup} from "react-bootstrap";
import FontAwesome from "react-fontawesome"
import DateUtils from "../../../../util/DateUtils";
import Properties from "../../../../util/Properties";

class ShareFlightSuggestion extends React.Component {

    render() {
        const flightOutboundDepartureTime = DateUtils.dateStringSwitchFormat(this.props.parameters.flightOutboundDepartureTime,
            Properties.DATE_TIME_FORMAT, Properties.DISPLAY_DATE_TIME_FORMAT);

        const flightOutboundArrivalTime = DateUtils.dateStringSwitchFormat(this.props.parameters.flightOutboundArrivalTime,
            Properties.DATE_TIME_FORMAT, Properties.DISPLAY_DATE_TIME_FORMAT);

        const flightInboundDepartureTime = DateUtils.dateStringSwitchFormat(this.props.parameters.flightInboundDepartureTime,
            Properties.DATE_TIME_FORMAT, Properties.DISPLAY_DATE_TIME_FORMAT);

        const flightInboundArrivalTime = DateUtils.dateStringSwitchFormat(this.props.parameters.flightInboundArrivalTime,
            Properties.DATE_TIME_FORMAT, Properties.DISPLAY_DATE_TIME_FORMAT);

        return (
            <div style={{maxWidth: '1000px', margin: '10px auto', color: 'white'}}>
                <span>
                    <b><FontAwesome name='plane'/> Share Flight Suggestion</b>
                </span>

                <Form componentClass="form" className="suggestion-form" horizontal>
                    <FormGroup>
                        <Col componentClass={ControlLabel} sm={2}>
                            Link
                        </Col>
                        <Col sm={10}>
                            <FormControl value={this.props.parameters.flightUrl} disabled={true}/>
                        </Col>
                    </FormGroup>

                    <FormGroup>
                        <Col componentClass={ControlLabel} sm={2}>
                            Airline Name
                        </Col>
                        <Col sm={10}>
                            <FormControl value={this.props.parameters.flightAirlineName} disabled={true}/>
                        </Col>
                    </FormGroup>

                    <FormGroup>
                        <Col componentClass={ControlLabel} sm={2}>
                            Price
                        </Col>
                        <Col sm={10}>
                            <FormControl bsSize="sm"
                                         value={this.props.parameters.flightPrice +
                                         " " + this.props.parameters.flightPriceCurrency}
                                         disabled={true}/>
                        </Col>
                    </FormGroup>

                    <FormGroup>
                        <Col componentClass={ControlLabel} sm={2}>
                            Outbound
                        </Col>
                        <Col sm={10}>
                            <FormControl bsSize="sm"
                                         value={flightOutboundDepartureTime + " / " + flightOutboundArrivalTime}
                                         disabled={true}/>
                        </Col>
                    </FormGroup>

                    <FormGroup>
                        <Col componentClass={ControlLabel} sm={2}>
                            Inbound
                        </Col>
                        <Col sm={10}>
                            <FormControl bsSize="sm"
                                         value={flightInboundDepartureTime + " / " + flightInboundArrivalTime}
                                         disabled={true}/>
                        </Col>
                    </FormGroup>

                    <FormGroup>
                        <Col componentClass={ControlLabel} sm={2}>
                            Add To Itinerary
                        </Col>
                        <Col sm={10} style={{textAlign: 'left', paddingLeft: '10px'}}>
                            <Checkbox inline checked={this.props.parameters.flightAddToItinerary} readOnly/>
                        </Col>
                    </FormGroup>
                </Form>
            </div>
        )
    }

}

export default ShareFlightSuggestion;