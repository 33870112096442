import React from "react";
import {Button, ButtonGroup, Checkbox, FormControl, FormGroup, InputGroup} from "react-bootstrap";
import DatePicker from 'react-datepicker'
import ItinerarySelection from "../../itinerary/ItinerarySelection";
import BusyButton from "../../../../../common_ui/BusyButton";
import DateUtils from "../../../../../util/DateUtils";
import Properties from "../../../../../util/Properties";
import moment from "moment";
import ValidationUtils from "../../../../../util/ValidationUtils";

import expediaLogo from '../../../../../assets/img/expedia_logo.png'
import airbnbLogo from '../../../../../assets/img/airbnb_logo.png'
import {SingletonReferenceCacheService, SingletonSocialService} from "../../../../../AppContext";

class PostOtherSuggestionInputs extends React.Component {

    constructor(props) {
        super(props);
        this.setStateFunctions = this.props.setStateFunctions;
        this.socialService = SingletonSocialService;
        this.referenceCacheService = SingletonReferenceCacheService;
    }

    validateOtherSuggestion() {
        return ValidationUtils.combineValidationResults([
            ValidationUtils.validateValueOver(this.props.extrasItinerarySelection, 0),
            ValidationUtils.validateLength(this.props.extrasName, 1, 100),
            ValidationUtils.validateValueNotEquals(this.props.extrasCategory, "N/A"),
            ValidationUtils.validatePrice(this.props.extrasPrice),
            ValidationUtils.validateLength(this.props.extrasStartDate, 1),
            ValidationUtils.validateLength(this.props.extrasEndDate, 1),
        ]);
    }

    getExpediaSearchHref() {
        let itinerary = this.getItinerary();
        return Properties.EXTERNAL_ROUTE_EXPEDIA_EXTRAS
            .replace(':destinationCityCode', itinerary.selectedDestination)
            .replace(':outboundDate', DateUtils.dateStringSwitchFormat(itinerary.startTravelDate, Properties.DATE_FORMAT, 'DD/MM/YYYY'))
            .replace(':inboundDate', DateUtils.dateStringSwitchFormat(itinerary.endTravelDate, Properties.DATE_FORMAT, 'DD/MM/YYYY'))
    }

    getAirBnbSearchHref() {
        const itinerary = this.getItinerary();
        const city = this.referenceCacheService.getCity(itinerary.selectedDestination);

        return Properties.EXTERNAL_ROUTE_AIRBNB_EXTRAS
            .replace(':query', city.name + "," + city.countryName)
            .replace(':outboundDate', itinerary.startTravelDate)
            .replace(':inboundDate', itinerary.endTravelDate)
    }

    getItinerary() {
        const itinerary = this.props.itineraries[this.props.extrasItinerarySelection];
        return itinerary ? itinerary : {};
    }

    handleUrlChange(value) {
        this.setStateFunctions.extrasUrl(value);

        if (value.length > 10) {
            this.socialService.checkLink(value, Properties.EXTRAS_SUGGESTION)
                .then(responseData => {
                    if (responseData.result !== undefined) {
                        this.setActivityDetails(responseData.result + ' link detected. Fetching details...', 'Fetching details...');
                        return this.socialService.processLink(value, Properties.EXTRAS_SUGGESTION)
                            .then(responseData => {
                                const result = responseData.result;
                                this.setActivityDetails(result.title, result.description, result.totalCost, result.startTime, result.endTime);
                            });
                    } else {
                        this.setState({manualInput: true});
                        return Promise.resolve();
                    }
                })
                .catch(() => {
                    this.setActivityDetails('Could not get details from link', undefined, undefined);
                });
        }
    }

    handlePostSuggestion() {
        return this.props.postExtrasSuggestion()
            .then(() => this.clearInputs())
    }

    setActivityDetails(title, description, price, startDate, endDate) {
        this.setStateFunctions.extrasName(title);
        this.setStateFunctions.extrasDescription(description);
        this.setStateFunctions.extrasPrice(price);
        if (startDate) this.setStateFunctions.extrasStartDate(startDate);
        if (endDate) this.setStateFunctions.extrasEndDate(endDate);
    }

    clearInputs() {
        this.setStateFunctions.extrasName(undefined);
        this.setStateFunctions.extrasDescription(undefined);
        this.setStateFunctions.extrasPrice(undefined);
        this.setStateFunctions.extrasStartDate(undefined);
        this.setStateFunctions.extrasEndDate(undefined);
        this.setStateFunctions.extrasUrl(undefined);
        this.setStateFunctions.extrasCategory("N/A");
        this.setStateFunctions.extrasItinerarySelection(-1);
        this.setStateFunctions.extrasAddToItinerary(false);
    }

    render() {
        return (
            <div>
                <FormGroup validationState={this.validateOtherSuggestion()}>
                    <ItinerarySelection itineraries={this.props.itineraries}
                                        value={this.props.extrasItinerarySelection}
                                        onChange={(e) => this.setStateFunctions.extrasItinerarySelection(e.target.value)}
                                        disabled={this.props.isPostingMessage}/>


                    <ButtonGroup className="input-big-button-holder" vertical>
                        <Button href={this.getExpediaSearchHref()} target="_blank" rel="noopener noreferrer"
                                disabled={this.props.extrasItinerarySelection === -1 || this.props.isPostingMessage}>
                            <span className="middle-text">Search Things to Do With </span><img src={expediaLogo}/>
                        </Button>

                        <Button href={this.getAirBnbSearchHref()} target="_blank"
                                rel="noopener noreferrer"
                                bsStyle="primary"
                                disabled={this.props.extrasItinerarySelection === -1 || this.props.isPostingMessage}>
                            <span className="middle-text">Search Things to Do With </span><img src={airbnbLogo}/>
                        </Button>
                    </ButtonGroup>

                    <p className="instructions-text">Enter details</p>
                    <select className="form-control input-sm comment-text-area"
                            id="extras-category"
                            value={this.props.extrasCategory}
                            onChange={(e) => this.setStateFunctions.extrasCategory(e.target.value)}
                            disabled={this.props.isPostingMessage}>
                        <option value="N/A" disabled>Select Category</option>
                        <option value="Activity">Activity</option>
                        <option value="Travel">Travel</option>
                        <option value="Restaurant/Bar">Restaurant/Bar</option>
                        <option value="Other">Other</option>
                    </select>

                    <InputGroup className="comment-text-area" bsSize="sm">
                        <FormControl type="text"
                                     placeholder="Enter URL (optional)"
                                     value={this.props.extrasUrl}
                                     onChange={(e) => this.handleUrlChange(e.target.value)}
                                     disabled={this.props.isPostingMessage}
                                     id="url"/>
                        <InputGroup.Button>
                            <Button disabled={this.props.isPostingMessage}
                                    onClick={() => this.handleUrlChange('')}>Clear</Button>
                        </InputGroup.Button>
                    </InputGroup>

                    <FormControl type="text"
                                 bsSize="sm"
                                 placeholder="Enter Name of Suggestion"
                                 className="comment-text-area"
                                 value={this.props.extrasName}
                                 onChange={(e) => this.setStateFunctions.extrasName(e.target.value)}
                                 disabled={this.props.isPostingMessage}
                                 id="name"/>

                    <FormControl componentClass="textarea"
                                 rows="1"
                                 bsSize="sm"
                                 placeholder="Enter Description"
                                 className="comment-text-area"
                                 value={this.props.extrasDescription}
                                 onChange={(e) => this.setStateFunctions.extrasDescription(e.target.value)}
                                 disabled={this.props.isPostingMessage}
                                 id="description"/>

                    <InputGroup className="comment-text-area" bsSize="sm">
                        <InputGroup.Addon>£</InputGroup.Addon>
                        <FormControl type="text"
                                     placeholder="Enter Total Price"
                                     value={this.props.extrasPrice}
                                     onChange={(e) => this.setStateFunctions.extrasPrice(e.target.value)}
                                     disabled={this.props.isPostingMessage}
                                     id="price"/>
                    </InputGroup>

                    <div className="col-v-centered comment-dates">
                        <DatePicker id="extra-start-date"
                                    className="form-control input-sm"
                                    placeholderText="Select Start Date"
                                    minDate={moment().add(1, "days")}
                                    maxDate={moment().add(1, "years")}
                                    dateFormat={Properties.DISPLAY_DATE_TIME_FORMAT}
                                    selected={DateUtils.stringToMoment(this.props.extrasStartDate,
                                        Properties.DATE_TIME_FORMAT)}
                                    startDate={DateUtils.stringToMoment(this.props.extrasStartDate,
                                        Properties.DATE_TIME_FORMAT)}
                                    endDate={DateUtils.stringToMoment(this.props.extrasEndDate,
                                        Properties.DATE_TIME_FORMAT)}
                                    onChange={(newDate) =>
                                        this.setStateFunctions.extrasStartDate(DateUtils.momentToString(Properties.DATE_TIME_FORMAT, newDate))}
                                    disabled={this.props.isPostingMessage}
                                    showTimeSelect
                                    showMonthYearDropdown
                                    withPortal
                                    selectsStart/>

                        <DatePicker id="extra-end-date"
                                    className="form-control input-sm"
                                    placeholderText="Select End Date"
                                    minDate={DateUtils.stringToMoment(this.props.extrasStartDate)}
                                    maxDate={moment().add(1, "years")}
                                    dateFormat={Properties.DISPLAY_DATE_TIME_FORMAT}
                                    selected={DateUtils.stringToMoment(this.props.extrasEndDate,
                                        Properties.DATE_TIME_FORMAT)}
                                    startDate={DateUtils.stringToMoment(this.props.extrasStartDate,
                                        Properties.DATE_TIME_FORMAT)}
                                    endDate={DateUtils.stringToMoment(this.props.extrasEndDate,
                                        Properties.DATE_TIME_FORMAT)}
                                    onChange={(newDate) =>
                                        this.setStateFunctions.extrasEndDate(DateUtils.momentToString(Properties.DATE_TIME_FORMAT, newDate))}
                                    disabled={this.props.isPostingMessage}
                                    showTimeSelect
                                    showMonthYearDropdown
                                    withPortal
                                    selectsEnd/>
                    </div>

                    <Checkbox defaultChecked={this.props.extrasAddToItinerary}
                              onChange={(e) => this.setStateFunctions.extrasAddToItinerary(e.target.checked)}
                              disabled={this.props.isPostingMessage}>
                        Add to Itinerary</Checkbox>
                </FormGroup>

                <ButtonGroup>
                    <BusyButton bsStyle="primary"
                                onClick={() => this.handlePostSuggestion()}
                                disabled={"success" !== this.validateOtherSuggestion()}
                                buttonText="Suggest Activity"
                                loadingMessage="Saving..."
                                isLoading={this.props.isPostingMessage}/>
                    <Button onClick={() => this.props.onCancel()}>
                        Cancel
                    </Button>
                </ButtonGroup>
            </div>
        );
    }

}

export default PostOtherSuggestionInputs;