import * as React from "react";
import queryString from 'query-string-es5'
import {Base64} from 'js-base64';
import SignUpLogInModal from "../../../../common_ui/SignUpLogInModal";
import {
    SingletonNavBarSharedState,
    SingletonReferenceCacheService,
    SingletonSocialService,
    SingletonUserService
} from "../../../../AppContext";

import ToggleDisplay from 'react-toggle-display'
import Properties from "../../../../util/Properties";
import {Col, Form, FormGroup} from "react-bootstrap";
import ShareAccommodationSuggestion from "./ShareAcccommodationSuggestion";
import ConfirmBusyButton from "../../../../common_ui/ConfirmBusyButton";
import {Redirect} from "react-router-dom";
import ShareFlightSuggestion from "./ShareFlightSuggestion";
import ShareOtherSuggestion from "./ShareOtherSuggestion";

class ShareSuggestionPage extends React.Component {

    constructor(props) {
        super(props);
        const values = queryString.parse(props.location.search);

        this.userService = SingletonUserService;
        this.navBarSharedState = SingletonNavBarSharedState;
        this.referenceCacheService = SingletonReferenceCacheService;
        this.socialService = SingletonSocialService;

        this.state = {
            suggestionType: values.type,
            parameters: ShareSuggestionPage.getSuggestionParameters(values.params),
            showSignUp: false,
            trips: [],

            selectedTripIndex: -1,
            selectedTrip: undefined,

            selectedItineraryIndex: -1,
            selectedItinerary: undefined,

            isAdding: false
        }
    }

    static getSuggestionParameters(params) {
        try {
            return JSON.parse(Base64.decode(params));
        } catch (err) {
            return {};
        }
    }

    handleSelectTrip(value) {
        this.setState({
            selectedTripIndex: value,
            selectedTrip: this.state.trips[value],

            selectedItineraryIndex: -1,
            selectedItinerary: undefined,
        })
    }

    handleSelectItinerary(value) {
        this.setState({
            selectedItineraryIndex: value,
            selectedItinerary: this.state.selectedTrip.potentialItineraries[value],
        })
    }

    componentWillMount() {
        if (!this.userService.isUserLoggedIn()) {
            this.setState({showSignUp: true});
        }

        this.navBarSharedState.subscribeOnState((state) => { //TODO use redux
            this.setState({trips: state.existingTrips.concat(state.invitedTrips)});
        });
    }

    handlePostSuggestion() {
        this.setState({isAdding: true});

        return this.createPostSuggestionPromise()
            .then(() => this.finishedPostingMessage())
    }

    createPostSuggestionPromise() {
        switch (this.state.suggestionType) {
            case Properties.FLIGHT_SUGGESTION:
                return this.socialService.postFlightSuggestionForTrip(
                    this.state.selectedTrip.id,
                    this.state.parameters.flightUrl,
                    this.state.parameters.flightAirlineName,
                    this.state.parameters.flightPrice,
                    true,
                    this.state.selectedItinerary.originCityCode,
                    this.state.selectedItinerary.selectedDestination,
                    this.state.selectedItinerary.selectedDestination,
                    this.state.selectedItinerary.originCityCode,
                    "",
                    this.state.parameters.flightOutboundDepartureTime,
                    this.state.parameters.flightOutboundArrivalTime,
                    this.state.parameters.flightInboundDepartureTime,
                    this.state.parameters.flightInboundArrivalTime,
                    this.state.selectedItineraryIndex,
                    this.state.parameters.flightAddToItinerary
                );
            case Properties.ACCOMMODATION_SUGGESTION:
                return this.socialService.postAccommodationSuggestionForTrip(
                    this.state.selectedTrip.id,
                    this.state.parameters.accommodationUrl,
                    this.state.parameters.accommodationName,
                    this.state.selectedItinerary.selectedDestination,
                    this.state.parameters.accommodationPrice,
                    "",
                    this.state.parameters.checkInDate,
                    this.state.parameters.checkOutDate,
                    this.state.selectedItineraryIndex,
                    this.state.parameters.accommodationAddToItinerary);
            case Properties.EXTRAS_SUGGESTION:
                return this.socialService.postExtrasSuggestionForTrip(
                    this.state.selectedTrip.id,
                    this.state.parameters.extrasUrl,
                    this.state.parameters.extrasName,
                    this.state.parameters.extrasCategory,
                    this.state.parameters.extrasDescription,
                    this.state.parameters.extrasPrice,
                    "",
                    this.state.parameters.extrasStartDate,
                    this.state.parameters.extrasEndDate,
                    this.state.selectedItineraryIndex,
                    this.state.parameters.extrasAddToItinerary
                );
            default:
                return Promise.reject("suggestionType invalid")
        }
    }

    finishedPostingMessage() {
        return this.setState({
            isAdding: false,
            loadTrip: this.state.selectedTrip.id
        });
    }

    render() {
        if (this.state.loadTrip) {
            return <Redirect to={Properties.ROUTE_LOAD_TRIP.replace(":id", this.state.loadTrip)}/>
        }

        const trips = this.state.trips;
        const tripOptions = trips.map((trip, index) =>
            <option key={index} value={index}>
                {trip.tripName}
            </option>);

        const itineraries = this.state.selectedTrip !== undefined ? this.state.selectedTrip.potentialItineraries : [];
        const itineraryOptions = itineraries.map((itinerary, index) =>
            <option key={index} value={index}>
                {this.referenceCacheService.getCity(itinerary.selectedDestination).name}
            </option>);

        const selectedTripName = this.state.selectedTrip !== undefined ? this.state.selectedTrip.tripName : undefined;

        return (
            <div className="create-trip-section">
                <SignUpLogInModal title="Please Sign In To Share Suggestion"
                                  show={this.state.showSignUp}
                                  closeFunction={(e) => this.setState({showSignUp: false})}/>

                <div className="suggestion-params">
                    <ToggleDisplay if={this.state.suggestionType === Properties.FLIGHT_SUGGESTION}>
                        <ShareFlightSuggestion parameters={this.state.parameters}/>
                    </ToggleDisplay>

                    <ToggleDisplay if={this.state.suggestionType === Properties.ACCOMMODATION_SUGGESTION}>
                        <ShareAccommodationSuggestion parameters={this.state.parameters}/>
                    </ToggleDisplay>

                    <ToggleDisplay if={this.state.suggestionType === Properties.EXTRAS_SUGGESTION}>
                        <ShareOtherSuggestion parameters={this.state.parameters}/>
                    </ToggleDisplay>
                </div>

                <div className="suggestion-params">
                    <ToggleDisplay if={this.userService.isUserLoggedIn()}>
                        <div style={{maxWidth: '1000px', margin: '10px auto'}}>
                            <Form componentClass="form" horizontal>
                                <FormGroup>
                                    <Col sm={2}/>
                                    <Col sm={10}>
                                        <select className="form-control"
                                                id="trip-select"
                                                value={this.state.selectedTripIndex}
                                                onChange={(e) => this.handleSelectTrip(e.target.value)}>
                                            <option value={-1} disabled>Select Trip</option>
                                            {tripOptions}
                                        </select>
                                    </Col>
                                </FormGroup>

                                <FormGroup>
                                    <Col sm={2}/>
                                    <Col sm={10}>
                                        <select className="form-control"
                                                id="itinerary-select"
                                                value={this.state.selectedItineraryIndex}
                                                onChange={(e) => this.handleSelectItinerary(e.target.value)}>
                                            <option value={-1} disabled>Select Itinerary</option>
                                            {itineraryOptions}
                                        </select>
                                    </Col>
                                </FormGroup>

                                <FormGroup>
                                    <Col sm={2}/>
                                    <Col sm={10}>
                                        <ConfirmBusyButton bsStyle="primary"
                                                           confirmMessage={"Are you sure you want to add this suggestion to '" + selectedTripName + "'?"}
                                                           onClick={(e) => this.handlePostSuggestion()}
                                                           disabled={this.state.selectedTripIndex === -1 || this.state.selectedItineraryIndex === -1}
                                                           buttonText="Add Suggestion To Trip/Itinerary"
                                                           loadingMessage="Adding..."
                                                           isLoading={this.state.isAdding}/>
                                    </Col>
                                </FormGroup>
                            </Form>
                        </div>
                    </ToggleDisplay>

                </div>
            </div>
        )
    }
}

export default ShareSuggestionPage;