import HttpUtilService from './HttpUtilService'
import Properties from '../util/Properties'
import SharedState from "../state/SharedState";
import ReferenceCacheService from "./ReferenceCacheService";
import {SuggestionRequest} from "../model/Model";
import {DestinationSuggestion, DestinationSuggestionResponse} from "../model/Suggestion";
import {TripCreationState} from "../model/State";

class DestinationSuggestionService {

    BASE_URL: string;
    sharedState: SharedState<TripCreationState>;
    referenceCacheService: ReferenceCacheService;
    httpUtilService: HttpUtilService;

    constructor(sharedState: SharedState<TripCreationState>, referenceCacheService: ReferenceCacheService) {
        this.BASE_URL = process.env.REACT_APP_DESTINATION_SUGGESTIONS_BACK_END_API || "";

        this.sharedState = sharedState;
        this.referenceCacheService = referenceCacheService;
        this.httpUtilService = new HttpUtilService();

        this._setIsBusyLoadingSuggestions(false);
    }

    getDestinationSuggestions(suggestionRequest: SuggestionRequest): Promise<DestinationSuggestionResponse> {
        this._setIsBusyLoadingSuggestions(true);
        this.sharedState.setState({suggestionsData: undefined});


        const onFulfilled = (returnedSuggestions: DestinationSuggestionResponse) => this._finishLoadingAndReturn(returnedSuggestions);

        return this._getSuggestions(suggestionRequest)
            .then((returnedSuggestions: DestinationSuggestionResponse) => this._storeInSharedState(suggestionRequest, returnedSuggestions))
            .then((returnedSuggestions: DestinationSuggestionResponse) => this._updateCityReferenceData(returnedSuggestions))
            .then(onFulfilled, onFulfilled);
    }

    getMoreDestinationSuggestions(suggestionRequest: SuggestionRequest,
                                  existingDestinationSuggestions: DestinationSuggestion[]): Promise<DestinationSuggestionResponse> {



        const onFulfilled = (returnedSuggestions: DestinationSuggestionResponse) => this._finishLoadingAndReturn(returnedSuggestions);

        this._setIsBusyLoadingSuggestions(true);
        return this._getSuggestions(suggestionRequest)
            .then((additionalSuggestions: DestinationSuggestionResponse) => {
                const newDestinationSuggestions = existingDestinationSuggestions.concat(additionalSuggestions.suggestions);

                this.sharedState.setState({
                    suggestionsData: {
                        suggestions: newDestinationSuggestions,
                        lastDestinationCodeChecked: additionalSuggestions.lastDestinationCodeChecked
                    },
                });

                return additionalSuggestions;
            })
            .then((additionalSuggestions: DestinationSuggestionResponse) => this._updateCityReferenceData(additionalSuggestions))
            .then(onFulfilled, onFulfilled);
    }

    selectDestinationSuggestion(suggestion: DestinationSuggestion) {
        const selectedDestinationSuggestions = this.sharedState.state.selectedDestinationSuggestions;

        if (Object.keys(selectedDestinationSuggestions).length < 5 && !selectedDestinationSuggestions[suggestion.cityCode]) {
            selectedDestinationSuggestions[suggestion.cityCode] = suggestion;
        } else {
            delete selectedDestinationSuggestions[suggestion.cityCode];
        }

        this.sharedState.setState({
            selectedDestinationSuggestions: selectedDestinationSuggestions,
        });
    }


    //*Internal methods*/
    private _getSuggestions(suggestionRequest: SuggestionRequest): Promise<DestinationSuggestionResponse> {
        return this.httpUtilService.post(Properties.GET_SUGGESTIONS_DESTINATIONS_URL, this.BASE_URL, suggestionRequest)
            .then((response) => response.data);
    }

    private _storeInSharedState(suggestionRequest: SuggestionRequest, returnedSuggestions: DestinationSuggestionResponse): DestinationSuggestionResponse {
        const travelParameters = {
            originCityCode: suggestionRequest.originCityCode,
            noOfAdults: suggestionRequest.noOfAdults,
            noOfChildren: suggestionRequest.noOfChildren,
            startTravelDate: suggestionRequest.startTravelDate,
            endTravelDate: suggestionRequest.endTravelDate
        };

        this.sharedState.setState({
            suggestionRequest: suggestionRequest,
            travelParameters: travelParameters,
            suggestionsData: returnedSuggestions,
            selectedDestinationSuggestions: {}
        });

        return returnedSuggestions;
    }

    private _updateCityReferenceData(returnedSuggestions: DestinationSuggestionResponse): Promise<DestinationSuggestionResponse> {
        const cityCodes = returnedSuggestions.suggestions.map(x => x.cityCode);

        return this.referenceCacheService.loadCacheWithCityData(cityCodes)
            .then(() => returnedSuggestions);
    }

    private _setIsBusyLoadingSuggestions(isLoadingSuggestions: boolean) {
        this.sharedState.setState({isLoadingSuggestions: isLoadingSuggestions});
    }

    private _finishLoadingAndReturn(returnedSuggestions: DestinationSuggestionResponse): DestinationSuggestionResponse {
        this._setIsBusyLoadingSuggestions(false);
        return returnedSuggestions;
    }

}

export default DestinationSuggestionService;