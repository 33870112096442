import HttpUtilService from "./HttpUtilService";
import Properties from '../util/Properties';
import UserState from '../state/UserState'
import SharedState from "../state/SharedState";
import ReferenceCacheService from "./ReferenceCacheService";
import {NavBarState} from "../model/State";
import {MinimalTrip, SaveUserTripResponse, TripPlan, StoredUser, UserTrips, User, Login} from "../model/Model";


type AccountType = 'NORMAL' | 'FACEBOOK' | 'GOOGLE'

export interface LoginRequest {
    emailAddress: string,
    password: string,
    accountType: AccountType
}

export interface SignInRequest {
    emailAddress: string,
    firstName: string,
    lastName: string,
    password: string,
    accountType: AccountType
}

export interface UserTripRequest {
    tripName: string,
    originCityCode: string,
    selectedDestinations: string[],
    participantEmailAddresses: string[],
    startTravelDate: string,
    endTravelDate: string,
    noOfChildren: number,
    noOfAdults: number
}

class UserService {

    USER_TRIP_SERVICE_URL: string;
    USER_ACCOUNT_SERVICE_URL: string;
    USER_ANALYTICS_FEEDBACK_URL: string;

    httpUtilService: HttpUtilService;
    navBarSharedState: SharedState<NavBarState>;
    referenceCacheService: ReferenceCacheService;

    constructor(navBarSharedState: SharedState<NavBarState>, referenceCacheService: ReferenceCacheService) {
        this.USER_TRIP_SERVICE_URL = process.env.REACT_APP_USER_TRIP_BACK_END_API || "";
        this.USER_ACCOUNT_SERVICE_URL = process.env.REACT_APP_USER_ACCOUNT_BACK_END_API || "";
        this.USER_ANALYTICS_FEEDBACK_URL = process.env.REACT_APP_ANALYTICS_FEEDBACK_API || "";

        this.httpUtilService = new HttpUtilService();
        this.navBarSharedState = navBarSharedState;
        this.referenceCacheService = referenceCacheService;
    }

    isUserLoggedIn(): boolean {
        return !!UserState.getCurrentUser();
    }

    getCurrentUser(): StoredUser | undefined {
        return UserState.getCurrentUser();
    }

    logoutCurrentUser() {
        UserState.clearCurrentUser();
        this.navBarSharedState.setState({existingTrips: [], invitedTrips: []})
    }

    signUp(signInRequest: SignInRequest) {
        return this.httpUtilService.post(Properties.POST_ACCOUNT_SIGN_UP_URL, this.USER_ACCOUNT_SERVICE_URL, signInRequest)
            .then((response) => UserService._setCurrentUser(response.data))
    }

    performLogin(loginRequest: LoginRequest) {
        return this.httpUtilService.post(Properties.POST_ACCOUNT_LOGIN_URL, this.USER_ACCOUNT_SERVICE_URL, loginRequest)
            .then((response) => UserService._setCurrentUser(response.data))
    }

    forgotPassword(request: { emailAddress: string }): Promise<void> {
        return this.httpUtilService.post(Properties.POST_ACCOUNT_INITIATE_FORGOTTEN_PASSWORD_URL, this.USER_ACCOUNT_SERVICE_URL, request)
    }

    newPassword(request: { userId: string | null, password: string | null, resetPasswordToken: string | null }): Promise<void> {
        return this.httpUtilService.post(Properties.POST_ACCOUNT_NEW_PASSWORD_URL, this.USER_ACCOUNT_SERVICE_URL,
            request, {defaultErrorHandling: true, errorMessage: 'Reset password link has expired!'})
    }

    verifyAccount(request: { userId: string | null, verifyToken: string | null }): Promise<void> {
        return this.httpUtilService.post(Properties.POST_ACCOUNT_VERIFY_EMAIL_URL,
            this.USER_ACCOUNT_SERVICE_URL, request, {
                defaultErrorHandling: true,
                errorMessage: 'Verify account link has expired!'
            })
    }

    saveUserTrip(userTripRequest: UserTripRequest): Promise<SaveUserTripResponse> {
        if (!this.isUserLoggedIn()) {
            throw new Error("User is not logged in");
        }
        const request = {
            ...userTripRequest, creatorUserId: UserState.currentUser?.userId
        };
        return this.httpUtilService.post(Properties.POST_USER_TRIP_URL, this.USER_TRIP_SERVICE_URL, request)
            .then(response => response.data)
            .then(
                response => this._handleUserTripResponse(response),
                (err) => {
                    this.navBarSharedState.setState({isLoadingTrips: false});
                    throw err;
                }
            );
    }

    updateUserTrip(tripPlan: TripPlan): Promise<SaveUserTripResponse> {
        return this.httpUtilService.put(Properties.POST_USER_TRIP_URL, this.USER_TRIP_SERVICE_URL, tripPlan)
            .then(response => response.data);
    }

    getTrip(tripId: string): Promise<TripPlan> {
        return this.httpUtilService.get(Properties.RESOURCE_USER_TRIP_BY_ID.replace(":id", tripId), this.USER_TRIP_SERVICE_URL, undefined,
            {defaultErrorHandling: true, errorMessage: "Trip could not be found!"})
            .then(response => response.data)
            .then(trip => this._updateCityReferenceData(trip));
    }

    deleteTrip(tripId: string): Promise<UserTrips> {
        return this.httpUtilService.delete(Properties.RESOURCE_USER_TRIP_BY_ID.replace(":id", tripId), this.USER_TRIP_SERVICE_URL)
            .then(response => response.data)
            .then(
                response => this._handleUserTripResponse(response),
                (err) => {
                    this.navBarSharedState.setState({isLoadingTrips: false});
                    throw err;
                })
    }

    deleteItinerary(tripId: string, destinationCityCode: string): Promise<TripPlan> {
        return this.httpUtilService
            .delete(Properties.DELETE_ITINERARY_URL.replace(":id", tripId).replace(":cityCode", destinationCityCode), this.USER_TRIP_SERVICE_URL)
            .then(response => response.data)
    }

    getUserTrips(): Promise<UserTrips> {
        if (!this.isUserLoggedIn()) {
            return Promise.resolve({
                createdTrips: [],
                invitedTrips: []
            });
        }

        const userId = UserState.currentUser?.userId || "";
        this.navBarSharedState.setState({ //TODO use redux to link state.
            isLoadingTrips: true,
        });
        return this.httpUtilService.get(Properties.GET_USER_TRIPS_URL.replace(":userId", userId), this.USER_TRIP_SERVICE_URL)
            .then(response => response.data)
            .then((userTripResponse: SaveUserTripResponse) => {
                const allTrips = userTripResponse.createdTrips.concat(userTripResponse.invitedTrips);
                const cityCodes = UserService._extractCityCodes(allTrips);

                return this.referenceCacheService.loadCacheWithCityData(cityCodes)
                    .then(() => userTripResponse);
            })
            .then(
                response => this._handleUserTripResponse(response),
                (err) => {
                    this.navBarSharedState.setState({isLoadingTrips: false});
                    throw err;
                });
    }

    getUsersByIds(userIds: string[]): Promise<User[]> {
        const params = {userIds: userIds.join(',')};
        return this._getUsers(params);
    }

    getUsersByEmailAddresses(emailAddresses: string[]): Promise<User[]> {
        const params = {emailAddresses: emailAddresses.join(',')};
        return this._getUsers(params);
    }

    _handleUserTripResponse(response: SaveUserTripResponse): SaveUserTripResponse {
        this.navBarSharedState.setState({ //TODO use redux to link state.
            existingTrips: response.createdTrips,
            invitedTrips: response.invitedTrips,
            isLoadingTrips: false
        });
        return response
    }

    _getUsers(params: any): Promise<User[]> {
        return this.httpUtilService.get(Properties.GET_ACCOUNT_USERS_URL, this.USER_ACCOUNT_SERVICE_URL, params)
            .then(response => response.data);
    }

    _updateCityReferenceData(trip: TripPlan) {
        const cityCodes = trip.potentialItineraries.map(x => x.selectedDestination);

        return this.referenceCacheService.loadCacheWithCityData(cityCodes)
            .then(() => trip);
    }

    static _setCurrentUser(responseData: Login) {
        if (responseData.loginSuccess === 'SUCCESS') {
            UserState.setCurrentUser(responseData.userId, responseData.userEmail, responseData.firstName, responseData.lastName, responseData.userDisplayPicture);
            UserState.setToken(responseData.accessToken, responseData.refreshToken)
        }
        return responseData;
    }

    static _setToken(responseData: Login) {
        if (responseData.loginSuccess === 'SUCCESS') {
            UserState.setToken(responseData.accessToken, responseData.refreshToken)
        }
        return responseData;
    }

    static _extractCityCodes(allTrips: MinimalTrip[]): string[] {
        return allTrips.reduce((initialValue: string[], trip: MinimalTrip) => {
            const cityCodesForTrip: string[] = trip.potentialItineraries.map(itinerary => itinerary.selectedDestination);
            cityCodesForTrip.push(trip.potentialItineraries[0].originCityCode);
            return initialValue.concat(cityCodesForTrip);
        }, []);
    }

    static _clearCurrentUser() {
        UserState.clearCurrentUser();
    }

}

export default UserService;