import React from 'react'
import SessionStore from "../../../../state/SessionStore";
import {SingletonSocialService, SingletonTripCreationSharedState} from "../../../../AppContext";
import DestinationDisplay from "../../../../common_ui/DestinationDisplay";
import {Button, ButtonGroup, FormControl, FormGroup} from "react-bootstrap";
import BusyButton from "../../../../common_ui/BusyButton";
import {Redirect} from "react-router-dom";

class NewDestinationSuggestionsPage extends React.Component {

    STORE_NAME = 'newDestinationSuggestions';

    static getInitialState() {
        return {
            commentText: undefined,
            selectedDestinationSuggestions: [],
            travelParameters: {}
        }
    }

    constructor(props) {
        super(props);
        this.state = SessionStore.getItemOrDefault(this.STORE_NAME, NewDestinationSuggestionsPage.getInitialState());
        this.state.next = false;
        this.state.isSavingSuggestion = false;

        this.tripId = this.props.match.params.id;
        this.socialService = SingletonSocialService;
        this.sharedState = SingletonTripCreationSharedState;
    }

    storeState(partialState, callback) {
        this.setState(partialState, () => {
            SessionStore.setItem(this.STORE_NAME, this.state);
            callback && callback();
        });
    }

    componentWillMount() {
        this.sharedState.subscribeOnState((state) => { //TODO use redux
            this.storeState({
                selectedDestinationSuggestions: state.selectedDestinationSuggestions,
                travelParameters: state.travelParameters
            });
        });
    }

    saveDestinationsToTrip() {
        this.setState({isSavingSuggestion: true});

        // TODO change B/E to be able to accept multiple destination suggestions
        const promises = Object.values(this.state.selectedDestinationSuggestions)
            .map(destination => this.socialService.postNewDestinationSuggestion(this.tripId, destination.cityCode, this.state.commentText, true));

        Promise.all(promises).then(() => this.setState({next: true}))
    }

    render() {
        if (this.state.next) {
            return <Redirect to={this.props.nextPageRoute}/>
        }

        const suggestions = Object.values(this.state.selectedDestinationSuggestions).map((suggestion, index) =>
            <DestinationDisplay id={'destination-display' + index}
                                key={index}
                                destinationCityCode={suggestion.cityCode}
                                startTravelDate={this.state.travelParameters.startTravelDate}
                                endTravelDate={this.state.travelParameters.endTravelDate}/>);

        return (
            <div className="create-trip-section">
                <div className="find-destination-section">
                    <div>
                        <p>{Object.keys(this.state.selectedDestinationSuggestions).length > 1
                            ? 'Destination shortlist' : 'Selected destination'}</p>

                        <div className="destination-suggestion-holder" style={{marginTop: '0', paddingTop: '5px'}}>
                            {suggestions}
                        </div>
                    </div>

                    <div>
                        <FormGroup>
                            <FormControl componentClass="textarea"
                                         bsSize="sm"
                                         placeholder="Add a message (optional)" className="comment-text-area"
                                         rows="2"
                                         id="comment"
                                         value={this.props.newCommentText}
                                         onChange={(e) => this.storeState({commentText: e.target.value})}/>
                            <FormControl.Feedback/>
                        </FormGroup>
                    </div>
                </div>

                <ButtonGroup>
                    <Button bsSize="large" href={this.props.backPageRoute}>Back</Button>
                    <BusyButton bsSize="large" bsStyle="primary" onClick={(e) => this.saveDestinationsToTrip()}
                                buttonText="Suggestion New Destinations to Trip"
                                loadingMessage="Adding..."
                                disabled={this.state.isSavingSuggestion || this.state.selectedDestinationSuggestions === 0}
                                isLoading={this.state.isSavingSuggestion}
                    />
                </ButtonGroup>
            </div>
        )
    }
}

export default NewDestinationSuggestionsPage;