import HttpUtilService from "./HttpUtilService";
import Properties from '../util/Properties';
import UserService from "./UserService";
import SharedState from "../state/SharedState";
import {TripSharedState} from "../model/State";
import {StoredUser} from "../model/Model";

class SocialService {

    SOCIAL_MESSAGE_FUNCTIONS_BASE_URL: string;
    URL_CRAWLER_BASE_URL: string;
    httpUtilService: HttpUtilService;
    userService: UserService;
    tripSharedState: SharedState<TripSharedState>;

    constructor(userService: UserService, tripSharedState: SharedState<TripSharedState>) {
        this.SOCIAL_MESSAGE_FUNCTIONS_BASE_URL = process.env.REACT_APP_SOCIAL_MESSAGE_FUNCTIONS_API || "";
        this.URL_CRAWLER_BASE_URL = process.env.REACT_APP_URL_CRAWLER_API || "";
        this.httpUtilService = new HttpUtilService();
        this.userService = userService;
        this.tripSharedState = tripSharedState;
    }

    getMessagesForTrip(tripId: string) {
        const url = Properties.GET_MESSAGES_FOR_TRIP_URL.replace(":id", tripId);
        return this.httpUtilService.get(url, this.SOCIAL_MESSAGE_FUNCTIONS_BASE_URL, undefined, {
            defaultErrorHandling: true,
            errorMessage: "Trip could not be found!"
        }).then(response => response.data).then(messages => this._populateMessagesWithUsersName(messages));
    }

    getNotifications() {
        const currentUser: StoredUser | undefined = this.userService.getCurrentUser();
        const url = Properties.GET_NOTIFICATIONS_FOR_USER_URL.replace(":userId", currentUser?.userId || "");
        return this.httpUtilService.get(url, this.SOCIAL_MESSAGE_FUNCTIONS_BASE_URL)
            .then(response => response.data);
    }

    updateNotifications(notificationIds: string[]) {
        const currentUser = this.userService.getCurrentUser();
        const url = Properties.UPDATE_NOTIFICATIONS_FOR_USER_URL
            .replace(":userId", currentUser?.userId || "")
            .replace(":notificationIds", notificationIds.join(','));

        return this.httpUtilService.post(url, this.SOCIAL_MESSAGE_FUNCTIONS_BASE_URL, {seen: true})
            .then(response => response.data);
    }

    postCommentsForTrip(tripId: string, commentText: string) {
        const url = Properties.POST_COMMENTS_FOR_TRIP_URL.replace(":id", tripId);
        const currentUser = this.userService.getCurrentUser();

        return this.httpUtilService.post(url, this.SOCIAL_MESSAGE_FUNCTIONS_BASE_URL,
            {
                commentText: commentText,
                userId: currentUser?.userId
            }
        ).then(response => response.data)
    }

    postFlightSuggestionForTrip(tripId: string, flightUrl: string, airlineName: string, price: number, isReturnFlight: boolean,
                                outboundOriginIATA: string, outboundDestinationIATA: string, inboundOriginIATA: string, inboundDestinationIATA: string,
                                commentText: string,
                                outboundDepartureTime: string, outboundArrivalTime: string, inboundDepartureTime: string, inboundArrivalTime: string,
                                itineraryNo: number, addToItinerary: boolean) {

        const url = Properties.POST_FLIGHT_SUGGESTION_FOR_TRIP_URL.replace(":id", tripId);
        const currentUser = this.userService.getCurrentUser();

        return this.httpUtilService.post(url, this.SOCIAL_MESSAGE_FUNCTIONS_BASE_URL,
            {
                url: flightUrl,
                airlineName: airlineName,
                cost: {
                    amount: price,
                    currency: 'GBP' //TODO not hardcoded
                },
                isReturnFlight: isReturnFlight,
                outboundOriginIATA: outboundOriginIATA,
                outboundDestinationIATA: outboundDestinationIATA,
                outboundDepartureTime: outboundDepartureTime,
                outboundArrivalTime: outboundArrivalTime,
                inboundOriginIATA: inboundOriginIATA,
                inboundDestinationIATA: inboundDestinationIATA,
                inboundDepartureTime: inboundDepartureTime,
                inboundArrivalTime: inboundArrivalTime,
                commentText: commentText,
                userId: currentUser?.userId,
                itineraryNo: itineraryNo
            })
            .then(response => response.data)
            .then((responseData) => this._addDirectlyToItinerary(addToItinerary, responseData))
    }

    postAccommodationSuggestionForTrip(tripId: string, accommodationUrl: string, accommodationName: string, destinationCityCode: string, price: number,
                                       commentText: string,
                                       checkInDate: string, checkOutDate: string, itineraryNo: number, addToItinerary: boolean) {


        const url = Properties.POST_ACCOMMODATION_SUGGESTION_FOR_TRIP_URL.replace(":id", tripId);
        const currentUser = this.userService.getCurrentUser();

        return this.httpUtilService.post(url, this.SOCIAL_MESSAGE_FUNCTIONS_BASE_URL,
            {
                url: accommodationUrl,
                accommodationName: accommodationName,
                destinationCityCode: destinationCityCode,
                cost: {
                    amount: price,
                    currency: 'GBP'  //TODO not hardcoded
                },
                commentText: commentText,
                checkInDate: checkInDate,
                checkOutDate: checkOutDate,
                userId: currentUser?.userId,
                itineraryNo: itineraryNo
            })
            .then(response => response.data)
            .then((responseData) => this._addDirectlyToItinerary(addToItinerary, responseData))
    }

    postExtrasSuggestionForTrip(tripId: string, extrasUrl: string, extrasName: string, extrasCategory: string, extrasDescription: string, price: number,
                                commentText: string, startDate: string, endDate: string, itineraryNo: number, addToItinerary: boolean) {

        const url = Properties.POST_EXTRAS_SUGGESTION_FOR_TRIP_URL.replace(":id", tripId);
        const currentUser = this.userService.getCurrentUser();

        return this.httpUtilService.post(url, this.SOCIAL_MESSAGE_FUNCTIONS_BASE_URL,
            {
                url: extrasUrl,
                name: extrasName,
                category: extrasCategory,
                description: extrasDescription,
                cost: {
                    amount: price,
                    currency: 'GBP'  //TODO not hardcoded
                },
                startDate: startDate,
                endDate: endDate,
                userId: currentUser?.userId,
                itineraryNo: itineraryNo
            })
            .then(response => response.data)
            .then((responseData) => this._addDirectlyToItinerary(addToItinerary, responseData))
    }

    postNewDestinationSuggestion(tripId: string, destinationCityCode: string, commentText: string, addToItinerary: boolean) {
        const url = Properties.POST_NEW_DESTINATION_SUGGESTION_FOR_TRIP_URL.replace(":id", tripId);
        const currentUser = this.userService.getCurrentUser();

        return this.httpUtilService.post(url, this.SOCIAL_MESSAGE_FUNCTIONS_BASE_URL,
            {
                destinationCityCode: destinationCityCode,
                commentText: commentText,
                userId: currentUser?.userId
            })
            .then(response => response.data)
            .then((responseData) => this._addDirectlyToItinerary(addToItinerary, responseData))
    }

    postNewPoll(tripId: string, poll: any) {
        const url = Properties.POST_NEW_POLL.replace(":id", tripId);
        const currentUser = this.userService.getCurrentUser();

        return this.httpUtilService.post(url, this.SOCIAL_MESSAGE_FUNCTIONS_BASE_URL,
            {
                poll: poll,
                userId: currentUser?.userId
            })
            .then(response => response.data)
    }

    postNewTaskList(tripId: string, taskList: any) {
        const url = Properties.POST_NEW_TASK_LIST.replace(":id", tripId);
        const currentUser = this.userService.getCurrentUser();

        return this.httpUtilService.post(url, this.SOCIAL_MESSAGE_FUNCTIONS_BASE_URL,
            {
                ...taskList,
                userId: currentUser?.userId
            })
            .then(response => response.data)
    }

    updatePoll(messageId: string, action: string, questionNo: number) {
        const url = Properties.POST_UPDATE_POLL_URl.replace(":pollId", messageId);
        const currentUser = this.userService.getCurrentUser();

        return this.httpUtilService.post(url, this.SOCIAL_MESSAGE_FUNCTIONS_BASE_URL,
            {
                action: action,
                questionNo: questionNo,
                userId: currentUser?.userId,
                userFirstName: currentUser?.firstName
            })
            .then(response => response.data)
    }

    updateTaskList(messageId: string, action: string, taskNo: number) {
        const url = Properties.POST_UPDATE_TASK_LIST_URl.replace(":taskId", messageId);
        const currentUser = this.userService.getCurrentUser();

        return this.httpUtilService.post(url, this.SOCIAL_MESSAGE_FUNCTIONS_BASE_URL,
            {
                action: action,
                taskNo: taskNo,
                userId: currentUser?.userId,
                userFirstName: currentUser?.firstName
            })
            .then(response => response.data)
    }

    checkLink(link: string, type: string) {
        const url = Properties.CHECK_LINK_URL;
        return this.httpUtilService.post(url, this.URL_CRAWLER_BASE_URL,
            {
                link: link,
                type: type
            }, {defaultErrorHandling: false}
        ).then(response => response.data)
    }

    processLink(link: string, type: string) {
        const url = Properties.PROCESS_LINK_URL;
        return this.httpUtilService.post(url, this.URL_CRAWLER_BASE_URL,
            {
                link: link,
                type: type
            }, {defaultErrorHandling: false}
        ).then(response => response.data)
    }

    postLike(messageId: string, messageType: string) {
        const url = Properties.POST_MESSAGE_FOR_LIKE_URL.replace(":messageId", messageId);
        const currentUser = this.userService.getCurrentUser();

        return this.httpUtilService.post(url, this.SOCIAL_MESSAGE_FUNCTIONS_BASE_URL,
            {
                messageType: messageType,
                userId: currentUser?.userId,
                userFirstName: currentUser?.firstName
            }
        ).then(response => response.data)
    }

    postUnlike(messageId: string, messageType: string) {
        const url = Properties.POST_MESSAGE_FOR_UNLIKE_URL.replace(":messageId", messageId);
        return this.httpUtilService.post(url, this.SOCIAL_MESSAGE_FUNCTIONS_BASE_URL,
            {
                messageType: messageType,
                userId: this.userService.getCurrentUser()?.userId
            }
        ).then(response => response.data)
    }

    addSuggestionToItinerary(messageId: string, messageType: string) {
        const url = Properties.SUGGESTION_TO_ITINERARY_URL.replace(":messageId", messageId);
        return this.httpUtilService.post(url, this.SOCIAL_MESSAGE_FUNCTIONS_BASE_URL, {messageType: messageType})
            .then(response => response.data)
    }

    removeSuggestionFromItinerary(messageId: string, messageType: string) {
        const url = Properties.SUGGESTION_TO_ITINERARY_URL.replace(":messageId", messageId);
        return this.httpUtilService.delete(url, this.SOCIAL_MESSAGE_FUNCTIONS_BASE_URL, {messageType: messageType})
            .then(response => response.data)
    }

    removeSuggestion(messageId: string, messageType: string) {
        const url = Properties.DELETE_MESSAGE_URL.replace(":messageId", messageId);
        return this.httpUtilService.delete(url, this.SOCIAL_MESSAGE_FUNCTIONS_BASE_URL, {messageType: messageType})
            .then(response => response.data)
    }

    isMessageLikedByMe(message: {likes: any}) {
        return this.userService.isUserLoggedIn()
            ? !!message?.likes[this.userService.getCurrentUser()?.userId || ""]
            : false;
    }

    isVotedByMe(pollOption: {votes: any}) {
        return this.userService.isUserLoggedIn()
            ? !!pollOption.votes[this.userService.getCurrentUser()?.userId || ""]
            : false;
    }

    populateMessageWithUsername(message: {creatorUserId: string, creatorFirstName?: string}) {
        return this.userService.getUsersByIds([message.creatorUserId])
            .then(users => {
                const user = users[0];
                message.creatorFirstName = user?.firstName || "";
                return message;
            })
    }

    _addDirectlyToItinerary(addToItineraryFlag: boolean, responseData: any) {
        const promise = addToItineraryFlag
            ? this.addSuggestionToItinerary(responseData.id, responseData.messageType)
            : Promise.resolve({});
        return promise.then(() => responseData);
    }

    _populateMessagesWithUsersName(messages: any[]) {
        if (messages.length === 0) {
            return messages;
        }

        const set: Set<string> = new Set(messages.map(message => message.creatorUserId));
        const usersIds = Array.from(set.values());

        return this.userService.getUsersByIds(usersIds)
            .then(users => {
                return users.reduce((accumulator: any, user) => {
                    accumulator[user.id] = user;
                    return accumulator;
                }, {});
            })
            .then(userMap => {
                return messages.map(message => {
                    const user = userMap[message.creatorUserId] || {};
                    message.creatorFirstName = user.firstName;
                    return message;
                });
            });
    }

}

export default SocialService;