/* Wrapper on sessionStorage */
class SessionStore<T> {

    static setItem <T>(key: string, data: T) {
        localStorage.setItem(key, JSON.stringify(data));
    }

    static getItem(key: string): any {
        const item = localStorage.getItem(key);
        return !!item ? JSON.parse(item) :null;
    }

    static getItemOrDefault <T>(key: string, defaultValue: T): T {
        const item = SessionStore.getItem(key);
        return (!!item) ? item : defaultValue;
    }

    static clearItem(key: string) {
        localStorage.removeItem(key);
    }

    static clearAll() {
        localStorage.clear();
    }
}

export default SessionStore;