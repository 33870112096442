import * as React from "react";
import {SingletonReferenceCacheService} from "../AppContext";
import LoadingComponent from "./LoadingComponent";
import ToggleDisplay from 'react-toggle-display'
import FontAwesome from "react-fontawesome"

class DestinationDisplay extends React.Component {

    constructor(props) {
        super(props);
        this.state = DestinationDisplay.getInitialPersistedState(props);
        this.referenceCacheService = SingletonReferenceCacheService;
    }

    static getInitialPersistedState(props) {
        return {
            citySuggestionInfo: {
                temperature: {}
            },
            isLoadingDestinationDetails: true,
            ...props
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.startTravelDate !== this.state.startTravelDate) {
            this.setState({startTravelDate: nextProps.startTravelDate});
            this.loadDestinationDetails(nextProps.selectedDestination);
        }
    }

    componentWillMount() {
        this.loadDestinationDetails(this.props.destinationCityCode);
    }

    loadDestinationDetails(destinationCode) {
        this.setState({isLoadingDestinationDetails: true});

        let fetchCitySuggestionInfo = this.referenceCacheService.fetchCitySuggestionInfo(destinationCode,
            this.state.startTravelDate);

        return fetchCitySuggestionInfo
            .then(
                responseData => {
                    this.setState({citySuggestionInfo: responseData, isLoadingDestinationDetails: false});
                    return responseData;
                },
                () => this.setState({isLoadingDestinationDetails: false}));
    }

    getCityDisplayText = (city) => city.cityName + ", " + city.countryName + " (" + city.cityCode + ")";

    render() {
        const temperature = this.state.citySuggestionInfo.temperature ? this.state.citySuggestionInfo.temperature : {};

        return (
            <div className="suggest-new-destination-display">
                <div className="destination-suggestion-small col-v-centered">
                    <LoadingComponent isLoading={this.state.isLoadingDestinationDetails} color="white"/>
                </div>

                <ToggleDisplay if={!this.state.isLoadingDestinationDetails && !!this.state.citySuggestionInfo.cityName}>
                    <div style={{backgroundImage: 'url("' + this.state.citySuggestionInfo.largePictureUrl + '")'}}
                         className='destination-suggestion-small'>

                        <div className="destination-suggestion-title-small">
                            <span>{this.getCityDisplayText(this.state.citySuggestionInfo)}</span>
                        </div>

                        <div style={{minHeight: '100px', padding: '2px'}}/>

                        <div className="destination-suggestion-info" style={{padding: '5px'}}>
                                <span className="destination-suggestion-text">
                                    <FontAwesome name='sun'/>
                                    {" Low: " + temperature.low + "°C" +
                                    ", Average: " + temperature.average + "°C" +
                                    ", High: " + temperature.high + "°C"}
                                </span>
                        </div>
                    </div>

                </ToggleDisplay>
            </div>
        );
    }

}

export default DestinationDisplay;