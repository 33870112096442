import React from "react"
import {Button, ButtonGroup, Checkbox, FormControl, FormGroup, InputGroup} from "react-bootstrap"
import DatePicker from 'react-datepicker'
import ValidationUtils from "../../../../../util/ValidationUtils";
import {SingletonReferenceCacheService, SingletonSocialService} from "../../../../../AppContext"
import ItinerarySelection from "../../itinerary/ItinerarySelection"
import BusyButton from "../../../../../common_ui/BusyButton"
import DateUtils from "../../../../../util/DateUtils"
import Properties from "../../../../../util/Properties"
import moment from "moment";

import expediaLogo from '../../../../../assets/img/expedia_logo.png'
import airbnbLogo from '../../../../../assets/img/airbnb_logo.png'

class PostAccommodationSuggestionInputs extends React.Component {

    constructor(props) {
        super(props);
        this.setStateFunctions = this.props.setStateFunctions;
        this.socialService = SingletonSocialService;
        this.referenceCacheService = SingletonReferenceCacheService;
        this.state = {
            showAccommodationPicker: false
        }
    }

    validatePostAccommodation() {
        return ValidationUtils.combineValidationResults([
            ValidationUtils.validateValueOver(this.props.accommodationItinerarySelection, 0),
            ValidationUtils.validateLength(this.props.accommodationName, 1, 200),
            ValidationUtils.validatePrice(this.props.accommodationPrice),
            ValidationUtils.validateLength(this.props.checkInDate, 1 ),
            ValidationUtils.validateLength(this.props.checkOutDate, 1),
        ]);
    }

    getExpediaSearchHref() {
        const itinerary = this.getItinerary();
        return Properties.EXTERNAL_ROUTE_EXPEDIA_ACCOMMODATION
            .replace(':destinationCityCode', itinerary.selectedDestination)
            .replace(':outboundDate', DateUtils.dateStringSwitchFormat(itinerary.startTravelDate, Properties.DATE_FORMAT, 'DD/MM/YYYY'))
            .replace(':inboundDate', DateUtils.dateStringSwitchFormat(itinerary.endTravelDate, Properties.DATE_FORMAT, 'DD/MM/YYYY'))
            .replace(':noOfAdults', itinerary.noOfAdults)
            .replace(':noOfChildren', itinerary.noOfChildren)
    }

    getAirBnbSearchHref() {
        const itinerary = this.getItinerary();
        const city = this.referenceCacheService.getCity(itinerary.selectedDestination);

        return Properties.EXTERNAL_ROUTE_AIRBNB_ACCOMDODATION
            .replace(':query', city.name + "," + city.countryName)
            .replace(':outboundDate', itinerary.startTravelDate)
            .replace(':inboundDate', itinerary.endTravelDate)
            .replace(':noOfAdults', itinerary.noOfAdults)
            .replace(':noOfChildren', itinerary.noOfChildren)
    }

    handleUrlChange(value) {
        this.setStateFunctions.accommodationUrl(value);

        if (value.length > 10) {
            this.socialService.checkLink(value, Properties.ACCOMMODATION_SUGGESTION)
                .then(responseData => {
                    if (responseData.result !== undefined) {
                        this.setAccommodationDetails(responseData.result + ' link detected. Fetching details...', 'Fetching details...');
                        return this.socialService.processLink(value, Properties.ACCOMMODATION_SUGGESTION)
                            .then(responseData => {
                                let result = responseData.result;
                                this.setAccommodationDetails(result.title, result.totalCost, result.checkInTime, result.checkOutTime);
                            });
                    } else {
                        this.setState({manualInput: true});
                        return Promise.resolve();
                    }
                })
                .catch(() => {
                    this.setAccommodationDetails('Could not get details from link', undefined);
                });
        }
    }

    handlePostSuggestion() {
        return this.props.postAccommodationSuggestion()
            .then(() => this.clearInputs())
    }

    setAccommodationDetails(title, accommodationPrice, checkInDate, checkOutDate) {
        this.setStateFunctions.accommodationName(title);
        this.setStateFunctions.accommodationPrice(accommodationPrice);
        if (checkInDate) this.setStateFunctions.checkInDate(checkInDate);
        if (checkOutDate) this.setStateFunctions.checkOutDate(checkOutDate);
    }

    clearInputs() {
        this.setStateFunctions.accommodationName(undefined);
        this.setStateFunctions.accommodationPrice(undefined);
        this.setStateFunctions.accommodationUrl(undefined);
        this.setStateFunctions.checkInDate(undefined);
        this.setStateFunctions.checkOutDate(undefined);
        this.setStateFunctions.accommodationItinerarySelection(-1);
        this.setStateFunctions.accommodationAddToItinerary(false);
    }

    getItinerary() {
        const itinerary = this.props.itineraries[this.props.accommodationItinerarySelection];
        return itinerary ? itinerary : {};
    }

    render() {
        return (
            <React.Fragment>
                <FormGroup validationState={this.validatePostAccommodation()}>
                    <ItinerarySelection itineraries={this.props.itineraries}
                                        value={this.props.accommodationItinerarySelection}
                                        onChange={(e) => this.setStateFunctions.accommodationItinerarySelection(e.target.value)}
                                        disabled={this.props.isPostingMessage}/>

                    <ButtonGroup className="input-big-button-holder" vertical>
                        <Button href={this.getAirBnbSearchHref()} target="_blank" rel="noopener noreferrer"
                                bsStyle="primary"
                                disabled={this.props.accommodationItinerarySelection === -1 || this.props.isPostingMessage}>
                            <span className="middle-text">Search Accommodation With </span><img src={airbnbLogo}/>
                        </Button>
                        <Button href={this.getExpediaSearchHref()} target="_blank" rel="noopener noreferrer"
                                disabled={this.props.accommodationItinerarySelection === -1 || this.props.isPostingMessage}>
                            <span className="middle-text">Search Hotels With </span><img src={expediaLogo}/>
                        </Button>
                    </ButtonGroup>

                    <p className="instructions-text">Enter details</p>
                    <InputGroup className="comment-text-area" bsSize="sm">
                        <FormControl type="text"
                                     rows="2"
                                     placeholder="Enter URL to Hotel/Accommodation (optional)"
                                     disabled={this.props.isPostingMessage}
                                     value={this.props.accommodationUrl}
                                     onChange={(e) => this.handleUrlChange(e.target.value)}
                                     id="url"/>
                        <InputGroup.Button>
                            <Button disabled={this.props.isPostingMessage}
                                    onClick={() => this.handleUrlChange('')}>Clear</Button>
                        </InputGroup.Button>
                    </InputGroup>

                    <FormControl type="text"
                                 bsSize="sm"
                                 placeholder="Enter Hotel/Accommodation Name"
                                 className="comment-text-area"
                                 value={this.props.accommodationName}
                                 onChange={(e) => this.setStateFunctions.accommodationName(e.target.value)}
                                 disabled={this.props.isPostingMessage}
                                 id="airline-name"/>

                    <InputGroup className="comment-text-area" bsSize="sm">
                        <InputGroup.Addon>£</InputGroup.Addon>
                        <FormControl type="text"
                                     placeholder="Enter Total Hotel/Accommodation Price"
                                     value={this.props.accommodationPrice}
                                     onChange={(e) => this.setStateFunctions.accommodationPrice(e.target.value)}
                                     disabled={this.props.isPostingMessage}
                                     id="price"/>
                    </InputGroup>

                    <div className="col-v-centered comment-dates">
                        <DatePicker id="outbound-start-date"
                                    className="form-control input-sm"
                                    placeholderText="Select Checkin Date"
                                    minDate={moment().add(1, "days")}
                                    maxDate={moment().add(1, "years")}
                                    dateFormat={Properties.DISPLAY_DATE_FORMAT}
                                    selected={DateUtils.stringToMoment(this.props.checkInDate,
                                        Properties.DATE_TIME_FORMAT)}
                                    startDate={DateUtils.stringToMoment(this.props.checkInDate,
                                        Properties.DATE_TIME_FORMAT)}
                                    endDate={DateUtils.stringToMoment(this.props.checkOutDate,
                                        Properties.DATE_TIME_FORMAT)}
                                    onChange={(newDate) =>
                                        this.setStateFunctions.checkInDate(DateUtils.momentToString(Properties.DATE_FORMAT, newDate))}
                                    disabled={this.props.isPostingMessage}
                                    showMonthYearDropdown
                                    withPortal
                                    selectsStart/>

                        <DatePicker id="outbound-end-date"
                                    className="form-control input-sm"
                                    placeholderText="Select Checkout Date"
                                    minDate={DateUtils.stringToMoment(this.props.checkInDate)}
                                    maxDate={moment().add(1, "years")}
                                    dateFormat={Properties.DISPLAY_DATE_FORMAT}
                                    selected={DateUtils.stringToMoment(this.props.checkOutDate,
                                        Properties.DATE_TIME_FORMAT)}
                                    startDate={DateUtils.stringToMoment(this.props.checkInDate,
                                        Properties.DATE_TIME_FORMAT)}
                                    endDate={DateUtils.stringToMoment(this.props.checkOutDate,
                                        Properties.DATE_TIME_FORMAT)}
                                    onChange={(newDate) =>
                                        this.setStateFunctions.checkOutDate(DateUtils.momentToString(Properties.DATE_FORMAT, newDate))}
                                    disabled={this.props.isPostingMessage}
                                    showMonthYearDropdown
                                    withPortal
                                    selectsEnd/>
                    </div>

                    <Checkbox defaultChecked={this.props.accommodationAddToItinerary}
                              onChange={(e) => this.setStateFunctions.accommodationAddToItinerary(e.target.checked)}
                              disabled={this.props.isPostingMessage}>
                        Add to Itinerary
                    </Checkbox>
                </FormGroup>

                <ButtonGroup>
                    <BusyButton bsStyle="primary"
                                onClick={(e) => this.handlePostSuggestion()}
                                disabled={"success" !== this.validatePostAccommodation()}
                                buttonText="Suggest Accommodation"
                                loadingMessage="Saving..."
                                isLoading={this.props.isPostingMessage}/>
                    <Button onClick={() => this.props.onCancel()}>
                        Cancel
                    </Button>
                </ButtonGroup>
            </React.Fragment>
        );
    }

}

export default PostAccommodationSuggestionInputs;