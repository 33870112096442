import moment from 'moment';
import Properties from './Properties'

class DateUtils {

    static dateStringSwitchFormat(dateString: string | undefined | null, fromFormat: string, toFormat: string): string | undefined {
        if (!dateString) {
            return undefined;
        }
        const toDate = moment(dateString, fromFormat);
        return toDate.format(toFormat)
    }

    static stringToMoment(dateString: string | undefined | null, dateFormat?: string): moment.Moment | undefined {
        return !!dateString ? moment(dateString, dateFormat) : undefined
    }

    static momentToString(dateFormat: string, momentDate: moment.Moment | null | undefined): string | undefined {
        return momentDate ? momentDate.format(dateFormat) : undefined
    }

    static getDisplayDateTime(dateString: string | undefined | null): string | undefined {
        return DateUtils.dateStringSwitchFormat(dateString, Properties.DATE_TIME_FORMAT, Properties.DISPLAY_DATE_TIME_FORMAT);
    }

    static getDisplayDate(dateString: string | undefined | null): string | undefined {
        return DateUtils.dateStringSwitchFormat(dateString, Properties.DATE_TIME_FORMAT, Properties.DISPLAY_DATE_FORMAT);
    }

}

export default DateUtils;