import React from "react";
import DateUtils from "../../../../util/DateUtils";

import FontAwesome from "react-fontawesome"
import DatePicker from "react-datepicker"
import ToggleDisplay from "react-toggle-display"

import connectorImg from '../../../../assets/img/connector.png'
import {Extras} from "./Extras";
import {Flight} from "./Flight";
import {Accommodation} from "./Accommodation";
import Properties from '../../../../util/Properties'
import {ConfirmEdit} from "../../../../common_ui/ConfirmEdit";
import moment from 'moment';
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {ItineraryItem, Money, TripItinerary} from "../../../../model/Model";

interface Props {
    tripId: string,
    itineraryId: string,
    itinerary: TripItinerary,
    onSaveItinerary: (itinerary: TripItinerary) => Promise<void>
    key: string
}

interface State {
    itinerary: TripItinerary,
    startTravelDate: string | undefined,
    endTravelDate: string | undefined,
    editStartDate: boolean,
    editEndDate: boolean
}

class Itinerary extends React.Component<Props, State> {

    tripId: string;
    itineraryId: string;
    datePicker: any;
    endDatePicker: any;

    static formatDate(dateString: string | undefined) {
        if (!dateString) {
            return null;
        }

        return DateUtils.dateStringSwitchFormat(dateString, 'YYYY-MM-DD HH:mm', 'DD MMM YYYY')
    }

    constructor(props: Props) {
        super(props);
        this.tripId = props.tripId;
        this.itineraryId = props.itineraryId;

        this.datePicker = undefined;
        this.endDatePicker = undefined;

        this.state = {
            itinerary: props.itinerary,
            startTravelDate: props.itinerary.startTravelDate,
            endTravelDate: props.itinerary.endTravelDate,

            editStartDate: false,
            editEndDate: false
        }
    }

    componentWillReceiveProps(nextProps: Readonly<Props>) {
        if (nextProps.itinerary !== this.state.itinerary) {
            this.setState({itinerary: nextProps.itinerary});
        }
    }

    onSaveItineraryItem(itineraryItem: ItineraryItem, index: number) {
        console.log('newStartDate', itineraryItem.startDate);
        const itinerary = {...this.state.itinerary};
        itinerary.itineraryItems[index] = itineraryItem;
        this.setState({itinerary: itinerary});

        return this._saveItinerary(itinerary);
    }

    setStartDate(newDate: moment.Moment | null) {
        this.setState({
            startTravelDate: DateUtils.momentToString(Properties.DATE_FORMAT, newDate),
            endTravelDate: DateUtils.momentToString(Properties.DATE_FORMAT, newDate?.add(1, 'days'))
        });
    }

    onStartDateEditBegins() {
        this.setState({editStartDate: true, editEndDate: false}, () => this.datePicker?.input.focus());
    }

    onStartDateEditEnds() {
        this.setState({editStartDate: false});
    }

    setEndDate(newDate: moment.Moment | null) {
        this.setState({endTravelDate: DateUtils.momentToString(Properties.DATE_FORMAT, newDate)});
    }

    onEndDateEditBegins() {
        this.setState({editEndDate: true}, () => this.endDatePicker.input.focus());
    }

    onEndDateEditEnds() {
        this.setState({editEndDate: false});
    }

    onEndDateSave() {
        const itinerary = {...this.state.itinerary};
        if (!!this.state.startTravelDate) {
            itinerary.startTravelDate = this.state.startTravelDate;
        }
        if (!!this.state.endTravelDate) {
            itinerary.endTravelDate = this.state.endTravelDate;
        }
        this.setState({itinerary: itinerary, editStartDate: false, editEndDate: false});
        return this._saveItinerary(itinerary);
    }

    onEndDateCancel() {
        this.setState({
            startTravelDate: this.props.itinerary.startTravelDate,
            endTravelDate: this.props.itinerary.endTravelDate,
            editStartDate: false,
            editEndDate: false
        });
    }

    _saveItinerary(itinerary: TripItinerary) {
        return this.props.onSaveItinerary
            ? this.props.onSaveItinerary(itinerary)
            : Promise.resolve({});
    }

    isDatePassed(dateString: string | undefined) {
        if (!dateString) {
            return false;
        } else {
            return moment().valueOf() > (DateUtils.stringToMoment(dateString, 'YYYY-MM-DD')?.valueOf() || 0);
        }
    }

    render() {
        const itineraryItems = this.state.itinerary.itineraryItems.map((itineraryItem, index) => {
            let item;
            switch (itineraryItem.type) {
                case 'FLIGHT':
                    item = <Flight key={index} itineraryItem={itineraryItem}
                                   onSaveItineraryItem={(itineraryItem: ItineraryItem) => this.onSaveItineraryItem(itineraryItem, index)}/>;
                    break;
                case 'ACCOMMODATION_CHECK_IN':
                    item = <Accommodation key={index} itineraryItem={itineraryItem} title="Check In"
                                          onSaveItineraryItem={(itineraryItem: ItineraryItem) => this.onSaveItineraryItem(itineraryItem, index)}/>;
                    break;
                case 'ACCOMMODATION_CHECK_OUT':
                    item = <Accommodation key={index} itineraryItem={itineraryItem} title="Check Out"
                                          onSaveItineraryItem={(itineraryItem: ItineraryItem) => this.onSaveItineraryItem(itineraryItem, index)}/>;
                    break;
                case 'EXTRAS':
                    item = <Extras key={index} itineraryItem={itineraryItem}
                                   onSaveItineraryItem={(itineraryItem: ItineraryItem) => this.onSaveItineraryItem(itineraryItem, index)}/>;
                    break;
            }

            return (
                <div key={index}>
                    {item}
                    <img src={connectorImg}/>
                </div>)
        });

        const getCostAmountText = (totalAmount: Money, amountPerPerson: Money, costText: string) => (
            <span>
                {costText}:<b>{" " + totalAmount.amount + " " + totalAmount.currency}</b>
                {" (" + amountPerPerson.amount + " " + amountPerPerson.currency + " per person)"}
            </span>
        );

        const tripExpiredStyle = this.isDatePassed(this.state.startTravelDate) ? 'expired-trip' : '';

        return (
            <div className="itinerary-holder" key={this.props.key}>
                <div className="itinerary-header">
                    <p className="total-cost">
                        {getCostAmountText(this.state.itinerary.cost.tripPriceTotal,
                            this.state.itinerary.cost.tripPricePerPerson,
                            "Total Cost")
                        }
                    </p>

                    <p>
                        <FontAwesome name='plane' style={{marginRight: '10px'}}/>
                        {getCostAmountText(this.state.itinerary.cost.flightsPriceTotal,
                            this.state.itinerary.cost.flightsPricePerPerson,
                            "Flights Cost")}
                    </p>
                    <p>
                        <FontAwesome name='bed' style={{marginRight: '10px'}}/>
                        {getCostAmountText(this.state.itinerary.cost.accommodationPriceTotal,
                            this.state.itinerary.cost.accommodationPricePerPerson,
                            "Accommodation Cost")}
                    </p>
                    <p>
                        <FontAwesome name='sun' style={{marginRight: '10px'}}/>
                        {getCostAmountText(
                            this.state.itinerary.cost.extrasPriceTotal,
                            this.state.itinerary.cost.extrasPricePerPerson,
                            "Extras Cost")}
                    </p>
                </div>

                <img src={connectorImg}/>

                <div className="itinerary-date flight-outbound-details">
                    <ToggleDisplay if={!this.state.editStartDate}>
                        <span className={`itinerary-item-flight ${tripExpiredStyle}`}>
                            {`Trip begins on ${Itinerary.formatDate(this.state.startTravelDate)}`}
                        </span>

                        <ToggleDisplay if={this.isDatePassed(this.state.startTravelDate)}>
                            <OverlayTrigger
                                placement='bottom'
                                overlay={<Tooltip id={'trip-started-tooltip'}>{`This trip has started`}</Tooltip>}>
                                <FontAwesome name='info-circle' className='date-passed-icon'/>
                            </OverlayTrigger>
                        </ToggleDisplay>
                    </ToggleDisplay>

                    <ToggleDisplay if={this.state.editStartDate}>
                        <DatePicker id="travel-start-date"
                                    ref={(datePicker) => {
                                        this.datePicker = datePicker
                                    }}
                                    minDate={moment().add(1, "days")}
                                    selected={DateUtils.stringToMoment(this.state.startTravelDate, Properties.DATE_FORMAT)}
                                    startDate={DateUtils.stringToMoment(this.state.startTravelDate, Properties.DATE_FORMAT)}
                                    endDate={DateUtils.stringToMoment(this.state.endTravelDate, Properties.DATE_FORMAT)}
                                    onChange={(newDate : moment.Moment | null) => this.setStartDate(newDate)}
                                    onSelect={() => this.onEndDateEditBegins()}
                                    className="form-control"
                                    dateFormat={Properties.DISPLAY_DATE_FORMAT}
                                    showMonthYearDropdown
                                    withPortal
                                    selectsStart/>
                    </ToggleDisplay>

                    <div>
                        <ConfirmEdit editMode={false}
                                     onStartEdit={() => this.onStartDateEditBegins()}
                                     onStopEdit={() => this.onStartDateEditEnds()}/>
                    </div>
                </div>

                <img src={connectorImg}/>

                {itineraryItems}

                <div className="itinerary-date flight-outbound-details">
                    <ToggleDisplay if={!this.state.editEndDate}>
                        <span className={`itinerary-item-flight ${tripExpiredStyle}`}>
                            {'Trip ends on ' + Itinerary.formatDate(this.state.endTravelDate)}
                        </span>

                        <ToggleDisplay if={this.isDatePassed(this.state.endTravelDate)}>
                            <OverlayTrigger
                                placement='bottom'
                                overlay={<Tooltip id={'trip-ended-tooltip'}>{`This trip has ended`}</Tooltip>}>
                                <FontAwesome name='info-circle' className='date-passed-icon'/>
                            </OverlayTrigger>
                        </ToggleDisplay>
                    </ToggleDisplay>

                    <ToggleDisplay if={this.state.editEndDate}>
                        <DatePicker id="travel-end-date"
                                    ref={(datePicker) => {
                                        this.endDatePicker = datePicker
                                    }}
                                    selected={DateUtils.stringToMoment(this.state.endTravelDate, Properties.DATE_FORMAT)}
                                    startDate={DateUtils.stringToMoment(this.state.startTravelDate, Properties.DATE_FORMAT)}
                                    endDate={DateUtils.stringToMoment(this.state.endTravelDate, Properties.DATE_FORMAT)}
                                    minDate={DateUtils.stringToMoment(this.state.startTravelDate, Properties.DATE_FORMAT)}
                                    onChange={(newDate) => this.setEndDate(newDate)}
                                    className="form-control"
                                    dateFormat={Properties.DISPLAY_DATE_FORMAT}
                                    showMonthYearDropdown
                                    withPortal
                                    selectsEnd/>
                    </ToggleDisplay>

                    <div>
                        <ConfirmEdit editMode={this.state.editEndDate}
                                     onStartEdit={() => this.onEndDateEditBegins()}
                                     onStopEdit={() => this.onEndDateEditEnds()}
                                     onSave={() => this.onEndDateSave()}
                                     onCancel={() => this.onEndDateCancel()}/>
                    </div>
                </div>

                <img src={connectorImg} alt="connector"/>
            </div>
        );
    }
}

export default Itinerary;