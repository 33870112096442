import SharedState from './state/SharedState'
import ReferenceCacheService from './service/ReferenceCacheService'
import DestinationSuggestionService from './service/DestinationSuggestionService'
import UserService from "./service/UserService";
import SocialService from "./service/SocialService";
import AnalyticsService from "./service/AnalyticsService";
import WebSocketClient from "./service/WebSocketClient";
import {NavBarState, TripCreationState, TripSharedState} from "./model/State";

const tripCreationSharedState = new SharedState<TripCreationState>('tripCreationStore', {
    isLoadingSuggestions: false,
    suggestionRequest: {},
    travelParameters: {},
    createTripStage: 1,
    selectedDestinationSuggestions: {},
    suggestionsData: {suggestions: []}
});
const navBarSharedState = new SharedState<NavBarState>('navBarState',
    {existingTrips: [], invitedTrips: [], isLoadingTrips: false}, false); //TODO use redux
const tripSharedState = new SharedState<TripSharedState>('tripSharedState',
    {itineraries: []}, false); //TODO use redux

export const SingletonTripCreationSharedState = tripCreationSharedState;
export const SingletonNavBarSharedState = navBarSharedState;
export const SingletonTripSharedState = tripSharedState;

const referenceCacheService = new ReferenceCacheService();
const destinationSuggestionService = new DestinationSuggestionService(tripCreationSharedState, referenceCacheService);
const userService = new UserService(navBarSharedState, referenceCacheService);
const socialService = new SocialService(userService, tripSharedState);

export const SingletonReferenceCacheService = referenceCacheService;
export const SingletonDestinationSuggestionService = destinationSuggestionService;
export const SingletonUserService = userService;
export const SingletonSocialService = socialService;
export const SingletonWebSocketClient = WebSocketClient();
export const SingletonAnalyticsService = new AnalyticsService();

export const GlobalErrorState = new SharedState('globalErrorState', {
    errorMessage: undefined
}, false);