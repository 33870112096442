import React from "react";
import {SingletonUserService} from "../../AppContext";
import LoadingComponent from "../../common_ui/LoadingComponent";
import ToggleDisplay from 'react-toggle-display'
import {Alert} from "react-bootstrap";
import {RouteComponentProps} from "react-router";
import UserService from "../../service/UserService";

interface State {
    isLoading: boolean,
    userId: string | null,
    token: string | null,
    verifyEmailSuccess: boolean
}

class VerifyAccount extends React.Component<RouteComponentProps, State> {

    userService: UserService;

    constructor(props: RouteComponentProps) {
        super(props);

        const searchString = this.props.location.search;
        const params = new URLSearchParams(searchString);
        this.state = {
            isLoading: false,
            userId: params.get('userId'),
            token: params.get('token'),
            verifyEmailSuccess: false
        };
        this.userService = SingletonUserService;
    }

    componentWillMount() {
        this.startLoading();
        const request = {
            userId: this.state.userId,
            verifyToken: this.state.token
        };

        this.userService.verifyAccount(request)
            .then(
                () => this.setState({
                    isLoading: false,
                    verifyEmailSuccess: true
                }),
                () => this.stopLoading()
            );
    }

    startLoading() {
        this.setState({isLoading: true});
    }

    stopLoading() {
        this.setState({isLoading: false})
    }

    render() {
        return (
            <div className="destination-suggestion-section col-v-centered"
                 style={{height: '80vh'}}>
                <LoadingComponent isLoading={this.state.isLoading}/>

                <ToggleDisplay if={!this.state.isLoading && this.state.verifyEmailSuccess}>
                    <Alert>
                        Thanks for signing up!<br/>
                        Your account is all set up and ready to go!
                    </Alert>
                </ToggleDisplay>
            </div>
        )
    }

}

export default VerifyAccount;