import React from 'react';
import ReactBootstrapSlider from 'react-bootstrap-slider'
import moment from 'moment';

import 'react-datepicker/dist/react-datepicker.css';
import Properties from '../util/Properties'
import DateUtils from '../util/DateUtils'
import SessionStore from '../state/SessionStore'
import {Redirect} from "react-router-dom";
import BusyButton from './BusyButton'
import {SingletonDestinationSuggestionService, SingletonTripCreationSharedState} from "../AppContext";
import {Button, Checkbox} from "react-bootstrap";
import {TripParametersInput} from "./TripParametersInput";

class SuggestionTripDestinationFilter extends React.Component {

    static formatTemperature(value) {
        return value < 45 ? value + "ºC" : value + "+ºC";
    }

    static formatMoney(value) {
        return value < 10000 ? "£" + value : "£" + value + "+";
    }

    render() {
        const selectedActivities = this.props.selectedActivities.map((activity, index) =>
            <div key={index} style={{textAlign: 'left'}}>
                <Button block
                        disabled={this.props.disabled}
                        bsSize="large"
                        bsStyle="primary"
                        id={"activityCheckBox" + index}
                        active={activity.checked}
                        onClick={() => this.props.handleSelectedActivityChange(index)}>
                    <span>{`${activity.displayName} `}</span><span className={activity.fontAwesomeIcon}/>
                </Button>
            </div>
        );

        return (
            <React.Fragment>
                <div>
                    <p>What would you like to see on holiday?</p>
                    <div className="check-box-options">
                        {selectedActivities}
                    </div>
                </div>

                <div>
                    <p>What's your ideal temperature?</p>
                    <ReactBootstrapSlider id="temperature_slider"
                                          value={this.props.temperature}
                                          slideStop={(e) => this.props.handleTemperatureChange(e.target.value)}
                                          formatter={SuggestionTripDestinationFilter.formatTemperature}
                                          ticks={[0, 15, 30, 45]}
                                          ticks_labels={["0ºC", "15ºC", "20ºC", "45+ºC"]}
                                          disabled={this.props.anyTemperature || this.props.disabled ? 'disabled' : ''}/>

                    <Checkbox defaultChecked={this.props.anyTemperature}
                              disabled={this.props.disabled}
                              onChange={(e) => this.props.handleAnyTemperatureChange(e.target.checked)}>
                        Any Temperature
                    </Checkbox>
                </div>


                <div>
                    <p>How much are you willing to spend per person on flights?</p>
                    <ReactBootstrapSlider id="money_slider"
                                          value={this.props.spendPerPerson}
                                          slideStop={(e) => this.props.handleSpendPerPersonChange(e.target.value)}
                                          formatter={SuggestionTripDestinationFilter.formatMoney}
                                          ticks={[0, 50, 100, 500, 1000]}
                                          ticks_labels={["0", "£50", "£100", "£500", "£1000+"]}
                                          ticks_positions={[0, 25, 50, 75, 100]}
                                          disabled={this.props.anyPrice  || this.props.disabled ? 'disabled' : ''}/>
                    <Checkbox defaultChecked={this.props.anyPrice}
                              disabled={this.props.disabled}
                              onChange={(e) => this.props.handleAnyPriceChange(e.target.checked)}>
                        Any Price
                    </Checkbox>
                </div>

                {
                    this.props.handleCancel
                        ? <section className="btn-group">
                            <BusyButton bsStyle="primary"
                                        buttonText="Search New Destinations"
                                        loadingMessage="Searching..."
                                        isLoading={this.props.disabled}
                                        onClick={() => this.props.handleFindDestinations()}/>
                            <Button disabled={this.props.disabled}
                                    onClick={() => this.props.handleCancel()}>
                                Cancel
                            </Button>
                        </section>
                        : <BusyButton bsSize="large"
                                      bsStyle="primary"
                                      buttonText="Next"
                                      loadingMessage="Searching..."
                                      isLoading={this.props.disabled}
                                      onClick={() => this.props.handleFindDestinations()}/>
                }
            </React.Fragment>
        )
    }
}

class SuggestionsInputSection extends React.Component {

    STORE_NAME = 'suggestionInputStore';

    static selectedActivities() {
        return [
            {
                name: 'BEACH',
                displayName: 'Beaches',
                checked: false,
                fontAwesomeIcon: 'fas fa-umbrella-beach'
            },
            {
                name: 'HIGHLY_RATED_SPAS',
                displayName: 'Spa Retreats',
                checked: false,
                fontAwesomeIcon: 'fas fa-hot-tub'
            },
            {
                name: 'THEME_PARKS',
                displayName: 'Theme Parks',
                checked: false,
                fontAwesomeIcon: 'fab fa-fort-awesome'
            },
            {
                name: 'SKI_RESORTS',
                displayName: 'Ski Resorts',
                checked: false,
                fontAwesomeIcon: 'fas fa-skiing'
            },
            {
                name: 'GOLF_CLUBS',
                displayName: 'Golf Courses',
                checked: false,
                fontAwesomeIcon: 'fas fa-golf-ball'
            }
        ];
    }

    constructor(props) {
        super(props);
        const initialState = SessionStore.getItemOrDefault(this.STORE_NAME, SuggestionsInputSection.getInitialState());
        this.state = {
            ...initialState,
            next: false,
            isLoadingSuggestions: false
        };
        this.sharedState = SingletonTripCreationSharedState;
        this.destinationSuggestionService = SingletonDestinationSuggestionService;

        const startTravelMoment = DateUtils.stringToMoment(this.state.startTravelDate, Properties.DATE_FORMAT);
        if (moment().valueOf() > startTravelMoment.valueOf()) {
            this.state.startTravelDate = SuggestionsInputSection.getInitialState().startTravelDate;
            this.state.endTravelDate = SuggestionsInputSection.getInitialState().endTravelDate;
        }
    }

    static getInitialState() {
        return {
            originCityCode: "LON",
            noOfAdults: 2,
            noOfChildren: 0,
            startTravelDate: DateUtils.momentToString(Properties.DATE_FORMAT, moment().add(1, "days")),
            endTravelDate: DateUtils.momentToString(Properties.DATE_FORMAT, moment().add(5, "days")),
            temperature: 0,
            travelTime: 0,
            spendPerPerson: 0,
            anyPrice: true,
            anyTemperature: true,
            anyActivities: false,
            selectedActivities: SuggestionsInputSection.selectedActivities()
        };
    }

    componentWillMount() {
        this.sharedState.subscribeOnState((state) => { //TODO use redux
            this.setState({
                isLoadingSuggestions: state.isLoadingSuggestions,
            })
        });
    }

    storeState(partialState, callback) {
        return this.setState(partialState, () => {
            callback && callback();
            return SessionStore.setItem(this.STORE_NAME, this.state);
        });
    }

    handleFindDestinations() {
        const selectedTripTypes = this.state.selectedActivities.filter(it => it.checked).map(it => it.name);

        const suggestionRequest = {
            originCityCode: this.state.originCityCode,
            destinationCityCodesToIgnore: [],
            noOfAdults: this.state.noOfAdults,
            noOfChildren: this.state.noOfChildren,
            startTravelDate: this.state.startTravelDate,
            endTravelDate: this.state.endTravelDate,
            minimumTemperature: !(this.state.anyTemperature) ? this.state.temperature - 3 : null,
            maximumTemperature: !(this.state.anyTemperature) ? this.state.temperature + 5 : null,
            maxSpendPerPerson: !(this.state.anyPrice) ? this.state.spendPerPerson : null,
            tripTypes: !(this.state.anyActivities) ? selectedTripTypes : null
        };

        this.destinationSuggestionService.getDestinationSuggestions(suggestionRequest)
            .then(() => this.setState({next: true}));
    }

    handleSelectedActivityChange(index) {
        let activities = this.state.selectedActivities;
        activities[index].checked = !activities[index].checked
        this.storeState({selectedActivities: activities});
    }

    render() {
        if (this.state.next) {
            return <Redirect to={this.props.nextPageRoute}/>
        }

        return (
            <div>
                <div>
                    <TripParametersInput originCityCode={this.state.originCityCode}
                                         setOriginCityCode={(value) => this.storeState({originCityCode: value})}
                                         noOfAdults={this.state.noOfAdults}
                                         setNoOfAdults={(value) => this.storeState({noOfAdults: value})}
                                         noOfChildren={this.state.noOfChildren}
                                         setNoOfChildren={(value) => this.storeState({noOfChildren: value})}
                                         startTravelDate={this.state.startTravelDate}
                                         setStartTravelDate={(value) => this.storeState({startTravelDate: value})}
                                         endTravelDate={this.state.endTravelDate}
                                         setEndTravelDate={(value) => this.storeState({endTravelDate: value})}/>

                    <div className="find-destination-section">
                        <SuggestionTripDestinationFilter selectedActivities={this.state.selectedActivities}
                                                         spendPerPerson={this.state.spendPerPerson}
                                                         temperature={this.state.temperature}

                                                         anyActivities={this.state.anyActivities}
                                                         anyPrice={this.state.anyPrice}
                                                         anyTemperature={this.state.anyTemperature}

                                                         handleSelectedActivityChange={(index) => this.handleSelectedActivityChange(index)}
                                                         handleSpendPerPersonChange={(value) => this.storeState({spendPerPerson: value})}
                                                         handleTemperatureChange={(value) => this.storeState({temperature: value})}

                                                         handleAnyActivitiesChange={(checked) => this.storeState({anyActivities: checked})}
                                                         handleAnyPriceChange={(checked) => this.storeState({anyPrice: checked})}
                                                         handleAnyTemperatureChange={(checked) => this.storeState({anyTemperature: checked})}

                                                         disabled={this.state.isLoadingSuggestions}
                                                         handleFindDestinations={() => this.handleFindDestinations()}/>
                    </div>

                </div>
            </div>
        )
    }
}

export default SuggestionsInputSection;
export {SuggestionTripDestinationFilter}