import Axios, {AxiosInstance} from 'axios'
import {GlobalErrorState} from '../AppContext'
import UserState from "../state/UserState"
import Properties from '../util/Properties'
import UserService from "./UserService";
import {Login} from "../model/Model";


export interface Options {
    defaultErrorHandling?: boolean,
    errorMessage?: string
}

class HttpUtilService {

    private axiosClient: AxiosInstance;

    constructor() {
        this.axiosClient = Axios.create();
    }

    static getHeaders() {
        if (UserState.getToken() !== undefined) {
            return {
                'Content-Type': 'application/json',
                'Authorization': UserState.getToken().accessToken
            }
        } else {
            return {'Content-Type': 'application/json'}
        }
    }

    get(url: string, baseURL: string, params?: any, options: Options = {}) {
        const {defaultErrorHandling = true, errorMessage = undefined} = options;
        return handleRequest(this.axiosClient.request({
            method: 'get',
            url: url,
            baseURL: baseURL,
            params: params,
            headers: HttpUtilService.getHeaders()
        }), errorMessage, defaultErrorHandling);
    }

    put(url: string, baseURL: string, body: any, options: Options = {}) {
        const {defaultErrorHandling = true, errorMessage = undefined} = options;
        return handleRequest(this.axiosClient.request({
            method: 'put',
            url: url,
            baseURL: baseURL,
            data: body,
            headers: HttpUtilService.getHeaders()
        }), errorMessage, defaultErrorHandling);
    }

    post(url: string, baseURL: string, body: any, options: Options = {}) {
        const {defaultErrorHandling = true, errorMessage = undefined} = options;
        return handleRequest(this.axiosClient.request({
            method: 'post',
            url: url,
            baseURL: baseURL,
            data: body,
            headers: HttpUtilService.getHeaders()
        }), errorMessage, defaultErrorHandling);
    }

    delete(url: string, baseURL: string, params?: any, options: Options = {}) {
        const {defaultErrorHandling = true, errorMessage = undefined} = options;
        return handleRequest(this.axiosClient.request({
            method: 'delete',
            url: url,
            baseURL: baseURL,
            params: params,
            headers: HttpUtilService.getHeaders()
        }), errorMessage, defaultErrorHandling);
    }
}

export const reloadWindowOnTokenExpiry = () => {
    if (!!UserState.getCurrentUser()) {
        return refreshAccessToken()
    } else {
        return guestToken();
    }
};

const successFunc = (response: any) => {
    return response;
};
const defaultErrorFunc = (err: any, errorMessage: string | undefined) => {
    return handleError(err, errorMessage);
};

const handleRequest = (httpResponse: Promise<any>, errorMessage: string | undefined, defaultErrorHandling: boolean) => {
    if (defaultErrorHandling) {
        return httpResponse.then(successFunc, (error) => defaultErrorFunc(error, errorMessage))
    } else {
        return httpResponse
    }
};

const handleError = (error: any, errorMessage: string | undefined = undefined) => {
    GlobalErrorState.setState({
        errorMessage: errorMessage || 'Oops! Something went wrong. Please try again later!' //TODO context sensitive messages
    });
    throw error;
};

const guestToken = async () => {
    const response = await performTokenHttpFetch(Properties.POST_GUEST_LOGIN_URL, {});
    return UserService._setToken(response.data);
};

const refreshAccessToken = async () => {
    const request = {
        refreshToken: UserState.getToken()?.refreshToken,
        userId: UserState.getCurrentUser()?.userId
    };

    try {
        const response = await performTokenHttpFetch(Properties.POST_REFRESH_ACCESS_TOKEN_URL, request);
        const responseData: Login = response.data;
        if (responseData.loginSuccess === "FAILURE") {
            return UserService._clearCurrentUser();
        }

        return await UserService._setToken(responseData)
    } catch (e) {
        return UserService._clearCurrentUser();
    }
};

const performTokenHttpFetch = (url: string, body: any) => Axios.create().request({
    method: 'post',
    url: url,
    baseURL: process.env.REACT_APP_USER_ACCOUNT_BACK_END_API,
    data: body,
    headers: HttpUtilService.getHeaders()
});

export default HttpUtilService;