import React from "react";
import {Button, Panel} from "react-bootstrap";
import {
    SingletonReferenceCacheService,
    SingletonSocialService,
    SingletonTripSharedState,
    SingletonUserService,
    SingletonWebSocketClient
} from "../../../../AppContext";
import ToggleDisplay from 'react-toggle-display'
import FontAwesome from "react-fontawesome"
import 'react-datepicker/dist/react-datepicker.css';
import {
    AccommodationSuggestion,
    Comment,
    ExtrasSuggestion,
    FlightSuggestion,
    NewDestinationSuggestion,
    Poll,
    TaskList,
    TaskListUpdate,
    WelcomeMessage
} from "./MessageComponents";
import SessionStore from "../../../../state/SessionStore";
import LoadingComponent from "../../../../common_ui/LoadingComponent";
import MessageButtonGroup from "./MessageButtonGroup";
import PostMessageInputs from "./inputs/PostMessageInputs";
import PostFlightSuggestionInputs from "./inputs/PostFlightSuggestionInputs";
import PostAccommodationSuggestionInputs from "./inputs/PostAccommodationSuggestionInputs";
import PostOtherSuggestionInputs from "./inputs/PostOtherSuggestionInputs";
import PostDestinationSuggestionInputs from "./inputs/PostDestinationSuggestionInputs";
import Properties from '../../../../util/Properties'
import SignUpLogInModal from "../../../../common_ui/SignUpLogInModal";
import NewPollInputs from "./inputs/NewPollInputs";
import queryString from 'query-string-es5'
import CreateTaskList from "./inputs/CreateTaskList";

class TripMessagePanel extends React.Component {

    STORE_NAME = 'tripPlanningPanel';
    NEW_MESSAGE_TOPIC = 'NEW_MESSAGE_' + this.props.tripId;
    DELETE_MESSAGE_TOPIC = 'DELETE_MESSAGE_' + this.props.tripId;
    UPDATE_MESSAGE_TOPIC = 'UPDATE_MESSAGE_' + this.props.tripId;
    MESSAGE_ITINERARY_UPDATE_TOPIC = 'MESSAGE_ITINERARY_UPDATE_' + this.props.tripId;

    static getInitialState() {
        return {
            newCommentText: '',
            messageMode: undefined,
            messagesFilterType: "ALL",

            flightUrl: undefined,
            flightAirlineName: undefined,
            flightPrice: undefined,
            flightOutboundDepartureTime: undefined,
            flightOutboundArrivalTime: undefined,
            flightInboundDepartureTime: undefined,
            flightInboundArrivalTime: undefined,
            flightItinerarySelection: -1,
            isReturnFlight: true,
            flightAddToItinerary: false,

            accommodationUrl: undefined,
            accommodationName: undefined,
            accommodationPrice: undefined,
            accommodationItinerarySelection: -1,
            checkInDate: undefined,
            checkOutDate: undefined,
            accommodationAddToItinerary: false,

            extrasUrl: undefined,
            extrasName: undefined,
            extrasCategory: "N/A",
            extrasDescription: undefined,
            extrasPrice: undefined,
            extrasStartDate: undefined,
            extrasEndDate: undefined,
            extrasItinerarySelection: -1,
            extrasAddToItinerary: false,

            newDestinationCityCode: undefined,
            newDestinationText: undefined,
            newDestinationAddToItinerary: false,

            panelExpandedState: false,
            isLoadingMessages: false,

            cities: []
        };
    }

    constructor(props) {
        super(props);
        const queryStringValues = props.location.search ? queryString.parse(props.location.search) : {};
        const sessionState = SessionStore.getItemOrDefault(this.STORE_NAME, TripMessagePanel.getInitialState());
        this.state = {
            ...sessionState,
            messages: [],
            itineraries: [],
            isPostingMessage: false,
            showSignUp: false,
            panelExpandedState: false,
            messageMode: undefined,
            queryStringValues: queryStringValues
        };
        this.newMessagesClient = SingletonWebSocketClient;
        this.userService = SingletonUserService;
        this.socialService = SingletonSocialService;
        this.tripSharedState = SingletonTripSharedState;
        this.referenceCacheService = SingletonReferenceCacheService;
        this.suggestionInputDiv = undefined;
    }

    storeState(partialState, callback) {
        this.setState(partialState, () => {
            SessionStore.setItem(this.STORE_NAME, this.state);
            callback && callback();
        });
    }

    handleFlightItinerarySelection(itineraries = this.state.itineraries,
                                   selection = this.state.flightItinerarySelection) {
        if (selection !== -1 && itineraries[selection]) {
            const itinerary = itineraries[selection];
            if (!itinerary) {
                return;
            }

            this.storeState({
                flightOutboundDepartureTime: itinerary.startTravelDate + " 07:00",
                flightOutboundArrivalTime: itinerary.startTravelDate + " 08:00",
                flightInboundDepartureTime: itinerary.endTravelDate + " 07:00",
                flightInboundArrivalTime: itinerary.endTravelDate + " 08:00"
            });
        }
    }

    handleAccommodationItinerarySelection(itineraries = this.state.itineraries,
                                          selection = this.state.accommodationItinerarySelection) {
        if (selection !== -1 && itineraries[selection]) {
            const itinerary = itineraries[selection];
            if (!itinerary) {
                return;
            }

            this.storeState({
                checkInDate: itinerary.startTravelDate,
                checkOutDate: itinerary.endTravelDate
            });
        }
    }

    handleExtrasItinerarySelection(itineraries = this.state.itineraries,
                                   selection = this.state.extrasItinerarySelection) {
        if (selection !== -1 && itineraries[selection]) {
            const itinerary = itineraries[selection];
            this.storeState({
                extrasStartDate: itinerary.startTravelDate + " 12:00",
                extrasEndDate: itinerary.endTravelDate + " 12:00"
            });
        }
    }

    componentWillMount() {
        this.setState({isLoadingMessages: true});
        this.getMessages(this.state.messagesFilterType).then(() => {
            this.scrollToMessage(this.state.queryStringValues.showMessage);
        });

        this.tripSharedState.subscribeOnState((state) => { //TODO use redux
            this.storeState({itineraries: state.itineraries});
            this.handleFlightItinerarySelection(state.itineraries);
            this.handleAccommodationItinerarySelection(state.itineraries);
            this.handleExtrasItinerarySelection(state.itineraries)
        });

        this.newMessagesClient.connect([this.NEW_MESSAGE_TOPIC, this.DELETE_MESSAGE_TOPIC, this.UPDATE_MESSAGE_TOPIC, this.MESSAGE_ITINERARY_UPDATE_TOPIC])
            .then(() => this.newMessagesClient.onMessageReceived((topic, message) => {
                console.log('message', message);
                if (topic === this.NEW_MESSAGE_TOPIC) return this.handleNewMessage(message);
                if (topic === this.DELETE_MESSAGE_TOPIC) return this.handleDeleteMessage(message);
                if (topic === this.UPDATE_MESSAGE_TOPIC) return this.updateMessage(message);
                if (topic === this.MESSAGE_ITINERARY_UPDATE_TOPIC) return this.updateMessage(message);
            }))

    }

    componentWillReceiveProps(nextProps) {
        const newQueryStringParameters = nextProps.location.search ? queryString.parse(nextProps.location.search) : {};
        if (newQueryStringParameters.showMessage) {
            this.setState({queryStringValues: newQueryStringParameters});
            this.scrollToMessage(newQueryStringParameters.showMessage);
        }
    }

    scrollToMessage(showMessage = null, highlight = true) {
        if (showMessage) {
            if (!this.state.panelExpandedState) {
                this.storeState({panelExpandedState: true});
            }

            setTimeout(() => {
                this.calcHeight();
                this.scrollToMessageWithId(showMessage, highlight);
            }, 400);
        }
    }

    scrollToMessageWithId = (showMessage, highlight) => {
        const className = "selected-message-wrapper";
        const els = document.getElementsByClassName(className);
        Array.from(els).forEach(el => el.classList.remove(className));

        const element = document.getElementById(showMessage);
        if (element) {
            element.parentNode.scrollIntoView({behavior: "smooth", block: "end", inline: "end"});

            if (highlight) {
                const parent = element.parentNode;
                parent.classList.add(className);
            }
        }
        this.props.history.push(`${Properties.ROUTE_LOAD_TRIP.replace(":id", this.props.tripId)}`);
    };

    handleNewMessage(message) {
        if (this.state.messagesFilterType === "ALL" || this.state.messagesFilterType === message.messageType) {
            this.socialService.populateMessageWithUsername(message)
                .then(message => {
                    const messages = this.state.messages;
                    messages.push(message);
                    this.storeState({messages: messages}, () => {
                        if (this.isThisMyComment(message)) {
                            this.scrollToMessage(message.id, true);
                        } else if (this.isAlreadyScrolledToBottom()) {
                            this.scrollToMessage(message.id, true);
                        }
                    });
                });
        }
    }

    handleDeleteMessage(deletedMessage) {
        console.log("Deleted message received " + JSON.stringify(deletedMessage));
        const messages = this.state.messages.filter(currentMessage => {
            return currentMessage.id !== deletedMessage.id;
        });
        this.storeState({messages: messages});
    }

    getMessages(messageType) {
        return this.socialService.getMessagesForTrip(this.props.tripId)
            .then(data => this.filterMessages(data, messageType))
            .then(data => this.storeState({messages: data}))
            .then(() => this.setState({isLoadingMessages: false}))
    }

    filterMessages(messages, messageType) {
        return messages.filter(message => messageType === "ALL" || messageType === message.messageType);
    }

    postComment() {
        this.setState({isPostingMessage: true});
        return this.socialService.postCommentsForTrip(this.props.tripId, this.state.newCommentText)
            .then(() => this.finishedPostingMessage(), () => this.finishedPostingMessage())
    }

    postFlightSuggestion() {
        const itinerary = this.state.itineraries[this.state.flightItinerarySelection];
        this.setState({isPostingMessage: true});
        return this.socialService.postFlightSuggestionForTrip(
            this.props.tripId,
            this.state.flightUrl || null,
            this.state.flightAirlineName,
            +this.state.flightPrice,
            this.state.isReturnFlight,
            itinerary.originCityCode,
            itinerary.selectedDestination,
            itinerary.selectedDestination,
            itinerary.originCityCode,
            "",
            this.state.flightOutboundDepartureTime,
            this.state.flightOutboundArrivalTime,
            this.state.flightInboundDepartureTime,
            this.state.flightInboundArrivalTime,
            this.state.flightItinerarySelection,
            this.state.flightAddToItinerary)
            .then(() => this.finishedPostingMessage(), () => this.finishedPostingMessage())
    }

    postAccommodationSuggestion() {
        const itinerary = this.state.itineraries[this.state.accommodationItinerarySelection];
        this.setState({isPostingMessage: true});
        return this.socialService.postAccommodationSuggestionForTrip(
            this.props.tripId,
            this.state.accommodationUrl || null,
            this.state.accommodationName,
            itinerary.selectedDestination,
            +this.state.accommodationPrice,
            "",
            this.state.checkInDate,
            this.state.checkOutDate,
            this.state.accommodationItinerarySelection,
            this.state.accommodationAddToItinerary)
            .then(() => this.finishedPostingMessage(), () => this.finishedPostingMessage())
    }

    postExtrasSuggestion() {
        this.setState({isPostingMessage: true});
        return this.socialService.postExtrasSuggestionForTrip(
            this.props.tripId,
            this.state.extrasUrl || null,
            this.state.extrasName,
            this.state.extrasCategory,
            this.state.extrasDescription,
            +this.state.extrasPrice,
            "",
            this.state.extrasStartDate,
            this.state.extrasEndDate,
            this.state.extrasItinerarySelection,
            this.state.extrasAddToItinerary)
            .then(() => this.finishedPostingMessage(), () => this.finishedPostingMessage())
    }

    postNewPoll(pollObj) {
        this.setState({isPostingMessage: true});
        return this.socialService.postNewPoll(this.props.tripId, pollObj)
            .then(() => this.finishedPostingMessage(), () => this.finishedPostingMessage())
    }

    postNewTaskList(taskListObj) {
        this.setState({isPostingMessage: true});
        return this.socialService.postNewTaskList(this.props.tripId, taskListObj)
            .then(() => this.finishedPostingMessage(), () => this.finishedPostingMessage())
    }

    isThisMyComment(comment) {
        return this.userService.isUserLoggedIn()
            ? this.userService.getCurrentUser().userId === comment.creatorUserId
            : false;
    }

    handleToggle() {
        if (this.state.panelExpandedState) {
            this.storeState({panelExpandedState: false, messageMode: undefined});
        } else {
            this.storeState({panelExpandedState: true});
        }
    }

    handleSwitchMessageMode(messageMode) {
        if (this.state.messageMode === messageMode) {
            return this.storeState({messageMode: undefined});
        }

        if (!this.state.panelExpandedState) {
            this.storeState({messageMode: messageMode, panelExpandedState: true});
        } else {
            this.storeState({messageMode: messageMode});
        }
        setTimeout(() => this.calcHeight(), 50)
    }

    handleMessageFilterChange(filterType) {
        this.setState({messagesFilterType: filterType});
        this.getMessages(filterType);
    }

    finishedPostingMessage() {
        return this.storeState({messageMode: undefined, isPostingMessage: false});
    }

    updateMessage(newMessage) {
        const newMessages = this.state.messages.map((message) =>
            message.id === newMessage.id ? Object.assign(message, newMessage) : message);

        return this.storeState({messages: newMessages});
    }

    isAlreadyScrolledToBottom() {
        return this.messageList.scrollHeight - this.messageList.clientHeight <= this.messageList.scrollTop + 100;
    }

    componentDidUpdate() {
        this.calcHeight();
    }

    calcHeight() {
        this.messageList.style.maxHeight = "calc(96vh - " + this.suggestionInputDiv.offsetHeight + "px -  120px)"
    }

    renderMessage(message, index) {
        const getItinerary = () => this.state.itineraries[0] ? this.state.itineraries[0] : {};
        const {startTravelDate, endTravelDate, originCityCode} = getItinerary(0);

        const {...props} = {
            key: index,
            message: message,
            isThisMyComment: (arg) => this.isThisMyComment(arg),
            referenceCacheService: this.referenceCacheService,
            destinationCityCode: getItinerary(message.itineraryNo).selectedDestination,
            tripId: this.props.tripId
        };

        switch (message.messageType) {
            case 'WELCOME':
                return <WelcomeMessage
                    onMessageModeChange={(newMessageMode => this.handleSwitchMessageMode(newMessageMode))}/>;
            case 'COMMENT':
                return <Comment {...props} />;
            case 'FLIGHT_SUGGESTION':
                return <FlightSuggestion {...props} />;
            case 'EXTRAS_SUGGESTION':
                return <ExtrasSuggestion {...props} />;
            case 'ACCOMMODATION_SUGGESTION':
                return <AccommodationSuggestion {...props} />;
            case 'NEW_DESTINATION_SUGGESTION':
                return <NewDestinationSuggestion {...props}
                                                 startTravelDate={startTravelDate}
                                                 endTravelDate={endTravelDate}
                                                 originCityCode={originCityCode}/>;
            case 'POLL':
                return <Poll {...props}/>;
            case 'TASK_LIST':
                return <TaskList {...props}/>;
            case 'TASK_LIST_UPDATE':
                return <TaskListUpdate {...props} showMessage={(id) => this.scrollToMessage(id, true)}/>;
            default:
                return null;
        }
    }

    render() {
        const getItinerary = () => this.state.itineraries[0] ? this.state.itineraries[0] : {};

        const {startTravelDate, endTravelDate, originCityCode, noOfAdults, noOfChildren} = getItinerary(0);

        const isLoggedOn = this.userService.isUserLoggedIn();

        const welcomeMessage = {
            messageType: 'WELCOME'
        };

        const messages = [welcomeMessage].concat(this.state.messages).map((message, index) =>
            <div className="message-wrapper" key={index}>{this.renderMessage(message, index)}</div>
        );

        const logInButton = <Button bsStyle="primary"
                                    onClick={() => this.setState({showSignUp: true})}>
            Log In To Post Messages/Suggestions
        </Button>;

        const canHideMessagesDisplay = [Properties.FLIGHT_SUGGESTION, Properties.ACCOMMODATION_SUGGESTION,
            Properties.EXTRAS_SUGGESTION, Properties.NEW_DESTINATION_SUGGESTION, Properties.POLL].indexOf(this.state.messageMode) !== -1;

        return (
            <div className="comment-holder">
                <SignUpLogInModal title="Please Sign In"
                                  show={this.state.showSignUp}
                                  logInFunction={(e) => window.location.reload()}
                                  closeFunction={(e) => this.setState({showSignUp: false})}/>

                <Panel expanded={this.state.panelExpandedState} onToggle={(e) => this.handleToggle()}>
                    <Panel.Heading className="comments-footer">
                        <div>
                            <Panel.Title toggle>
                                <span style={{color: 'white'}}><FontAwesome name='comments'/>Trip Messages </span>
                            </Panel.Title>

                            <ToggleDisplay if={!this.state.panelExpandedState && !isLoggedOn}>
                                {logInButton}
                            </ToggleDisplay>
                        </div>

                    </Panel.Heading>
                    <Panel.Collapse>
                        <Panel.Body className="comments-panel-body">
                            <div className="comments-display-filter mobile-hide">
                                <select className="form-control input-sm"
                                        value={this.state.messagesFilterType}
                                        onChange={(e) => this.handleMessageFilterChange(e.target.value)}>
                                    <option value={"ALL"}>Show All</option>
                                    <option value={Properties.FLIGHT_SUGGESTION}>Show Flight Suggestions</option>
                                    <option value={Properties.ACCOMMODATION_SUGGESTION}>Show Accommodation Suggestions
                                    </option>
                                    <option value={Properties.EXTRAS_SUGGESTION}>Show Other Suggestions</option>
                                    <option value={Properties.NEW_DESTINATION_SUGGESTION}>Show New Destinations
                                        Suggestions
                                    </option>
                                </select>
                            </div>
                            <div
                                className={canHideMessagesDisplay ? "comments-display mobile-hide" : "comments-display"}
                                ref={(div) => this.messageList = div}>
                                <LoadingComponent isLoading={this.state.isLoadingMessages} fontSize="20px"/>
                                {messages}
                            </div>
                        </Panel.Body>
                        <Panel.Footer className="comments-footer">
                            <div id="components-input-footer" ref={(div) => this.suggestionInputDiv = div}>
                                <ToggleDisplay if={isLoggedOn}>
                                    <MessageButtonGroup messageMode={this.state.messageMode}
                                                        onMessageModeChange={(newMessageMode => this.handleSwitchMessageMode(newMessageMode))}/>

                                    <ToggleDisplay if={this.state.messageMode === Properties.MESSAGE}>
                                        <PostMessageInputs
                                            isPostingMessage={this.state.isPostingMessage}
                                            postComment={() => this.postComment()}
                                            onCancel={() => this.finishedPostingMessage()}
                                            newCommentText={this.state.newCommentText}
                                            setNewCommentFunction={(newValue) => this.storeState({newCommentText: newValue})}/>
                                    </ToggleDisplay>

                                    <ToggleDisplay if={this.state.messageMode === Properties.FLIGHT_SUGGESTION}>
                                        <PostFlightSuggestionInputs
                                            tripId={this.props.tripId}
                                            isPostingMessage={this.state.isPostingMessage}
                                            postFlightSuggestion={() => this.postFlightSuggestion()}
                                            onSubmit={() => this.finishedPostingMessage()}
                                            onCancel={() => this.finishedPostingMessage()}
                                            itineraries={this.state.itineraries}
                                            flightItinerarySelection={this.state.flightItinerarySelection}
                                            flightUrl={this.state.flightUrl}
                                            flightAirlineName={this.state.flightAirlineName}
                                            flightPrice={this.state.flightPrice}
                                            flightOutboundDepartureTime={this.state.flightOutboundDepartureTime}
                                            flightOutboundArrivalTime={this.state.flightOutboundArrivalTime}
                                            flightInboundDepartureTime={this.state.flightInboundDepartureTime}
                                            flightInboundArrivalTime={this.state.flightInboundArrivalTime}
                                            flightAddToItinerary={this.state.flightAddToItinerary}
                                            setStateFunctions={{
                                                flightItinerarySelection: (newValue) => this.storeState({flightItinerarySelection: newValue},
                                                    this.handleFlightItinerarySelection(this.state.itineraries, newValue)),

                                                flightUrl: (newValue) => this.storeState({flightUrl: newValue}),
                                                flightAirlineName: (newValue) => this.storeState({flightAirlineName: newValue}),
                                                flightPrice: (newValue) => this.storeState({flightPrice: newValue}),
                                                flightOutboundDepartureTime: (newValue) => this.storeState({flightOutboundDepartureTime: newValue}),
                                                flightOutboundArrivalTime: (newValue) => this.storeState({flightOutboundArrivalTime: newValue}),
                                                flightInboundDepartureTime: (newValue) => this.storeState({flightInboundDepartureTime: newValue}),
                                                flightInboundArrivalTime: (newValue) => this.storeState({flightInboundArrivalTime: newValue}),
                                                flightAddToItinerary: (newValue) => this.storeState({flightAddToItinerary: newValue})
                                            }}/>
                                    </ToggleDisplay>

                                    <ToggleDisplay if={this.state.messageMode === Properties.ACCOMMODATION_SUGGESTION}>
                                        <PostAccommodationSuggestionInputs
                                            tripId={this.props.tripId}
                                            isPostingMessage={this.state.isPostingMessage}
                                            postAccommodationSuggestion={() => this.postAccommodationSuggestion()}
                                            onSubmit={() => this.finishedPostingMessage()}
                                            onCancel={() => this.finishedPostingMessage()}
                                            itineraries={this.state.itineraries}
                                            accommodationItinerarySelection={this.state.accommodationItinerarySelection}
                                            accommodationUrl={this.state.accommodationUrl}
                                            accommodationName={this.state.accommodationName}
                                            accommodationPrice={this.state.accommodationPrice}
                                            checkInDate={this.state.checkInDate}
                                            checkOutDate={this.state.checkOutDate}
                                            accommodationAddToItinerary={this.state.accommodationAddToItinerary}
                                            setStateFunctions={{
                                                accommodationItinerarySelection: (newValue) => this.storeState({accommodationItinerarySelection: newValue},
                                                    this.handleAccommodationItinerarySelection(this.state.itineraries, newValue)),

                                                accommodationUrl: (newValue) => this.storeState({accommodationUrl: newValue}),
                                                accommodationName: (newValue) => this.storeState({accommodationName: newValue}),
                                                accommodationPrice: (newValue) => this.storeState({accommodationPrice: newValue}),
                                                checkInDate: (newValue) => this.storeState({checkInDate: newValue}),
                                                checkOutDate: (newValue) => this.storeState({checkOutDate: newValue}),
                                                accommodationAddToItinerary: (newValue) => this.storeState({accommodationAddToItinerary: newValue})
                                            }}/>
                                    </ToggleDisplay>

                                    <ToggleDisplay
                                        if={this.state.messageMode === Properties.NEW_DESTINATION_SUGGESTION}>
                                        <PostDestinationSuggestionInputs
                                            tripId={this.props.tripId}
                                            onCancel={() => this.finishedPostingMessage()}
                                            originCityCode={originCityCode}
                                            noOfAdults={noOfAdults}
                                            noOfChildren={noOfChildren}
                                            startTravelDate={startTravelDate}
                                            endTravelDate={endTravelDate}
                                            cityCodesToIgnore={this.props.cityCodesToIgnore}
                                        />
                                    </ToggleDisplay>

                                    <ToggleDisplay if={this.state.messageMode === Properties.EXTRAS_SUGGESTION}>
                                        <PostOtherSuggestionInputs
                                            isPostingMessage={this.state.isPostingMessage}
                                            postExtrasSuggestion={() => this.postExtrasSuggestion()}
                                            onCancel={() => this.finishedPostingMessage()}
                                            itineraries={this.state.itineraries}
                                            extrasItinerarySelection={this.state.extrasItinerarySelection}
                                            extrasUrl={this.state.extrasUrl}
                                            extrasName={this.state.extrasName}
                                            extrasCategory={this.state.extrasCategory}
                                            extrasDescription={this.state.extrasDescription}
                                            extrasPrice={this.state.extrasPrice}
                                            extrasStartDate={this.state.extrasStartDate}
                                            extrasEndDate={this.state.extrasEndDate}
                                            extrasAddToItinerary={this.state.extrasAddToItinerary}
                                            setStateFunctions={{
                                                extrasItinerarySelection: (newValue) => this.storeState({extrasItinerarySelection: newValue},
                                                    this.handleExtrasItinerarySelection(this.state.itineraries, newValue)),

                                                extrasUrl: (newValue) => this.storeState({extrasUrl: newValue}),
                                                extrasName: (newValue) => this.storeState({extrasName: newValue}),
                                                extrasCategory: (newValue) => this.storeState({extrasCategory: newValue}),
                                                extrasDescription: (newValue) => this.storeState({extrasDescription: newValue}),
                                                extrasPrice: (newValue) => this.storeState({extrasPrice: newValue}),
                                                extrasStartDate: (newValue) => this.storeState({extrasStartDate: newValue}),
                                                extrasEndDate: (newValue) => this.storeState({extrasEndDate: newValue}),
                                                extrasAddToItinerary: (newValue) => this.storeState({extrasAddToItinerary: newValue})
                                            }}/>
                                    </ToggleDisplay>

                                    <ToggleDisplay if={this.state.messageMode === Properties.POLL}>
                                        <NewPollInputs isPostingMessage={this.state.isPostingMessage}
                                                       onCancel={() => this.finishedPostingMessage()}
                                                       postNewPoll={(poll) => this.postNewPoll(poll)}/>
                                    </ToggleDisplay>

                                    <ToggleDisplay if={this.state.messageMode === Properties.TODO_LIST}>
                                        <CreateTaskList isPostingMessage={this.state.isPostingMessage}
                                                        onCancel={() => this.finishedPostingMessage()}
                                                        postNewTaskList={(taskList) => this.postNewTaskList(taskList)}/>
                                    </ToggleDisplay>
                                </ToggleDisplay>

                                <ToggleDisplay if={!isLoggedOn}>
                                    {logInButton}
                                </ToggleDisplay>
                            </div>
                        </Panel.Footer>
                    </Panel.Collapse>
                </Panel>
            </div>
        );
    }
}

export default TripMessagePanel;