import React from "react";
import {Button, ButtonGroup, FormControl, FormGroup, HelpBlock, Modal} from 'react-bootstrap'

import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import GoogleLogin from 'react-google-login'
import ToggleDisplay from 'react-toggle-display'
import PasswordValidator from 'password-validator'

import {SingletonUserService} from "../AppContext";
import BusyButton from "./BusyButton";
import Properties from "../util/Properties"

import facebookLoginIcon from '../assets/img/facebook_login_icon.png'
import googleLoginIcon from '../assets/img/google_login_icon.png'
import ValidationUtils from "../util/ValidationUtils";

class SignUpLogInModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            emailAddress: '',
            firstName: '',
            lastName: '',
            password: '',
            confirmPassword: '',

            loginEmailAddress: '',
            loginPassword: '',

            signUpMessage: '',
            loginMessage: '',

            state: 'LOGIN',

            isLoggingIn: false,
            isSigningUp: false,

            initiatePasswordResetSuccess: false
        };
        this.FACEBOOK_APP_ID = process.env.REACT_APP_FB_APP_ID;
        this.GOOGLE_APP_ID = process.env.REACT_APP_GOOGLE_APP_ID;
        this.userService = SingletonUserService;

        this.passwordSchema = new PasswordValidator();
        this.passwordSchema
            .is().min(8)
            .is().max(100)
            .has().uppercase()
            .has().lowercase()
            .has().digits()
            .has().not().spaces();
    }

    getSignUpDetailsValidation() {
        if (this.isSignUpStarted()) {
            return null;
        }

        return ValidationUtils.combineValidationResults([
            ValidationUtils.validateEmail(this.state.emailAddress),
            ValidationUtils.validateLength(this.state.firstName, 1, 70),
            ValidationUtils.validateLength(this.state.lastName, 1, 70)
        ]);
    }

    getLoginInValidation() {
        if (this.isLoginStated()) {
            return null;
        }

        return ValidationUtils.combineValidationResults([
            ValidationUtils.validateEmail(this.state.loginEmailAddress, 1, 70),
            ValidationUtils.validateLength(this.state.loginPassword, 1, 70)
        ]);
    }

    getSignUpPasswordValidation() {
        if (this.isSignUpStarted()) {
            return null;
        }

        return ValidationUtils.combineValidationResults([
            ValidationUtils.validateLength(this.state.password, 1),
            ValidationUtils.validateLength(this.state.confirmPassword, 1),
            this.state.password === this.state.confirmPassword ?  'success' : 'warning',
            this.passwordSchema.validate(this.state.password, {}) ? 'success' : 'error',
        ]);
    }

    isSignUpStarted() {
        return this.state.emailAddress.length === 0 && this.state.firstName.length === 0
            && this.state.lastName.length === 0 && this.state.password.length === 0 && this.state.confirmPassword.length === 0;
    }

    isLoginStated() {
        return this.state.loginEmailAddress.length === 0 && this.state.loginPassword.length === 0
    }

    signUp() {
        const signUpRequest = {
            emailAddress: this.state.emailAddress,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            password: this.state.password,
            accountType: Properties.ACCOUNT_TYPE_NORMAL
        };

        this.setState({isSigningUp: true});
        this.handleSignUpRequest(signUpRequest);
    }

    facebookSignUp(response) {
        const signUpRequest = {
            emailAddress: response.email,
            firstName: response.first_name,
            lastName: response.last_name,
            password: response.accessToken,
            accountType: Properties.ACCOUNT_TYPE_FACEBOOK
        };

        this.handleSignUpRequest(signUpRequest);
    }

    googleSignUp(response) {
        const signUpRequest = {
            emailAddress: response.profileObj.email,
            firstName: response.profileObj.givenName,
            lastName: response.profileObj.familyName,
            password: response.tokenId,
            accountType: Properties.ACCOUNT_TYPE_GOOGLE
        };

        this.handleSignUpRequest(signUpRequest);
    }

    login() {
        const loginRequest = {
            emailAddress: this.state.loginEmailAddress,
            password: this.state.loginPassword,
            accountType: Properties.ACCOUNT_TYPE_NORMAL
        };

        this.handleLoginRequest(loginRequest);
    }

    facebookLogin(response) {
        const loginRequest = {
            emailAddress: response.email,
            password: response.accessToken,
            accountType: Properties.ACCOUNT_TYPE_FACEBOOK
        };
        this.handleLoginRequest(loginRequest);
    }

    googleLogin(response) {
        const loginRequest = {
            emailAddress: response.profileObj.email,
            password: response.tokenId,
            accountType: Properties.ACCOUNT_TYPE_GOOGLE
        };
        this.handleLoginRequest(loginRequest);
    }

    initiateForgotPassword() {
        this.setState({loginMessage: '', isLoggingIn: true});

        const request = {
            emailAddress: this.state.loginEmailAddress
        };

        this.userService.forgotPassword(request)
            .then(() => this.setState({
                isLoggingIn: false,
                initiatePasswordResetSuccess: true
            }), () => this.setState({isLoggingIn: false}));
    }

    handleSignUpRequest(signUpRequest) {
        this.setState({isSigningUp: true});

        this.userService.signUp(signUpRequest)
            .then((responseData) => {
                if (responseData.loginSuccess === 'SUCCESS') {
                    this.executeLogInFunction(responseData);
                    this.props.closeFunction();
                } else {
                    this.setState({signUpMessage: responseData.loginMessage});
                }

                return responseData;
            })
            .then((responseData) => this.getTripsOnLogin(responseData))
            .then(() => this.setState({isSigningUp: false}), () => this.setState({isSigningUp: false}));
    }

    handleLoginRequest(loginRequest) {
        this.setState({loginMessage: ''});
        this.setState({isLoggingIn: true});

        this.userService.performLogin(loginRequest)
            .then((responseData) => {
                if (responseData.loginSuccess === 'SUCCESS') {
                    this.executeLogInFunction(responseData);
                    this.props.closeFunction();
                } else {
                    this.setState({loginMessage: responseData.loginMessage});
                }

                return responseData;
            })
            .then((responseData) => this.getTripsOnLogin(responseData))
            .then(() => this.setState({isLoggingIn: false}), () => this.setState({isLoggingIn: false}));
    }

    getTripsOnLogin(responseData) {
        if (responseData.loginSuccess === 'SUCCESS') {
            this.userService.getUserTrips();
        }
    }

    executeLogInFunction(response) {
        const promise = (this.props.logInFunction) ? this.props.logInFunction() : Promise.resolve({});
        return promise.then(() => response);
    }

    render() {
        const isLoginValid = this.getLoginInValidation() === 'success';

        const isSignUpValid = this.getSignUpDetailsValidation() === 'success' && this.getSignUpPasswordValidation() === 'success';

        const validateEmail = ValidationUtils.validateEmail(this.state.loginEmailAddress);

        const handleLoginKeyPress = (e) => {
            if (e.key === 'Enter' && !e.shiftKey && this.getLoginInValidation() === 'success') {
                this.login();
                e.preventDefault();
            }
        };

        const handleSignUpKeyPress = (e) => {
            if (e.key === 'Enter' && !e.shiftKey && this.getSignUpDetailsValidation() === 'success') {
                this.signUp();
                e.preventDefault();
            }
        };

        return (
            <Modal show={this.props.show}>
                <Modal.Header closeButton={true} onHide={(e) => this.props.closeFunction()}>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <ToggleDisplay show={this.state.state === 'LOGIN'}>
                            <form className="log-in-modal">
                                <FormGroup id="loginForm" validationState={this.getLoginInValidation()}>
                                    <FormControl type="text"
                                                 id="email"
                                                 value={this.state.loginEmailAddress}
                                                 onChange={(e) => this.setState({loginEmailAddress: e.target.value})}
                                                 disabled={this.state.isLoggingIn}
                                                 placeholder="Enter email address"
                                                 onKeyPress={handleLoginKeyPress}/>
                                    <FormControl type="password"
                                                 id="pass"
                                                 value={this.state.loginPassword}
                                                 onChange={(e) => this.setState({loginPassword: e.target.value})}
                                                 disabled={this.state.isLoggingIn}
                                                 placeholder="Enter password"
                                                 onKeyPress={handleLoginKeyPress}/>
                                    <FormControl.Feedback/>
                                </FormGroup>

                                <span className="center">{this.state.loginMessage}</span>

                                <BusyButton bsStyle="primary"
                                            className="log-in-modal-button"
                                            buttonText="Log In"
                                            loadingMessage="Logging In..."
                                            disabled={!isLoginValid}
                                            isLoading={this.state.isLoggingIn}
                                            onClick={() => this.login()}/>

                                <hr/>

                                <ButtonGroup vertical style={{width: '100%'}}>
                                    {/*<FacebookLogin*/}
                                    {/*    appId={this.FACEBOOK_APP_ID}*/}
                                    {/*    autoLoad={false}*/}
                                    {/*    fields="first_name,last_name,email"*/}
                                    {/*    callback={(response) => this.facebookLogin(response)}*/}
                                    {/*    render={renderProps => (*/}
                                    {/*        <Button onClick={renderProps.onClick}*/}
                                    {/*                bsStyle="primary"*/}
                                    {/*                className="log-in-modal-button"*/}
                                    {/*                disabled={this.state.isLoggingIn}>*/}
                                    {/*            <img src={facebookLoginIcon} style={{paddingRight: '5px'}}/> Log In with*/}
                                    {/*            Facebook*/}
                                    {/*        </Button>*/}
                                    {/*    )}/>*/}

                                    <GoogleLogin
                                        clientId={this.GOOGLE_APP_ID}
                                        buttonText="Login"
                                        autoLoad={false}
                                        onSuccess={(response) => this.googleLogin(response)}
                                        onFailure={(response) => console.error(response)}
                                        render={renderProps => (
                                            <Button onClick={renderProps.onClick}
                                                    className="log-in-modal-button"
                                                    disabled={this.state.isLoggingIn}>
                                                <img src={googleLoginIcon} style={{paddingRight: '5px'}}/> <span
                                                style={{fontFamily: 'Roboto'}}>Log In with Google</span>
                                            </Button>
                                        )}
                                    />
                                </ButtonGroup>
                            </form>
                        </ToggleDisplay>

                        <ToggleDisplay show={this.state.state === 'SIGNUP'}>
                            <form className="log-in-modal">
                                <FormGroup id="signUpForm" validationState={this.getSignUpDetailsValidation()}>
                                    <FormControl type="text"
                                                 id="signUpEmail"
                                                 value={this.state.emailAddress}
                                                 onChange={(e) => this.setState({emailAddress: e.target.value})}
                                                 disabled={this.state.isSigningUp}
                                                 placeholder="Enter email address"
                                                 onKeyPress={handleSignUpKeyPress}/>
                                    <FormControl type="text"
                                                 id="firstName"
                                                 value={this.state.firstName}
                                                 onChange={(e) => this.setState({firstName: e.target.value})}
                                                 disabled={this.state.isSigningUp}
                                                 placeholder="Enter first name"
                                                 onKeyPress={handleSignUpKeyPress}/>
                                    <FormControl type="text"
                                                 id="lastName"
                                                 value={this.state.lastName}
                                                 onChange={(e) => this.setState({lastName: e.target.value})}
                                                 disabled={this.state.isSigningUp}
                                                 placeholder="Enter surname"
                                                 onKeyPress={handleSignUpKeyPress}/>
                                </FormGroup>

                                <FormGroup id="signUpFormPassword"
                                           validationState={this.getSignUpPasswordValidation()}>

                                    <FormControl type="password"
                                                 id="password"
                                                 value={this.state.password}
                                                 onChange={(e) => this.setState({password: e.target.value})}
                                                 disabled={this.state.isSigningUp}
                                                 placeholder="Enter password"
                                                 onKeyPress={handleSignUpKeyPress}/>
                                    <FormControl type="password"
                                                 id="confirmPassword"
                                                 value={this.state.confirmPassword}
                                                 onChange={(e) => this.setState({confirmPassword: e.target.value})}
                                                 disabled={this.state.isSigningUp}
                                                 placeholder="Confirm password"
                                                 onKeyPress={handleSignUpKeyPress}/>
                                    <FormControl.Feedback/>

                                    <HelpBlock>
                                        - Minimum 8 characters.<br/>
                                        - At least one upper case letter.<br/>
                                        - At least lower case letter.<br/>
                                        - At least one number.<br/>
                                    </HelpBlock>
                                </FormGroup>

                                <span className="center">{this.state.signUpMessage}</span>

                                <BusyButton bsStyle="primary"
                                            className="log-in-modal-button"
                                            buttonText="Sign Up"
                                            loadingMessage="Signing Up..."
                                            isLoading={this.state.isSigningUp}
                                            disabled={!isSignUpValid}
                                            onClick={() => this.signUp()}/>
                                <hr/>

                                <ButtonGroup vertical>
                                    {/*<FacebookLogin*/}
                                    {/*    appId={this.FACEBOOK_APP_ID}*/}
                                    {/*    autoLoad={false}*/}
                                    {/*    fields="first_name,last_name,email"*/}
                                    {/*    callback={(response) => this.facebookSignUp(response)}*/}
                                    {/*    render={renderProps => (*/}
                                    {/*        <Button onClick={renderProps.onClick}*/}
                                    {/*                bsStyle="primary"*/}
                                    {/*                className="log-in-modal-button"*/}
                                    {/*                disabled={this.state.isSigningUp}>*/}
                                    {/*            <img src={facebookLoginIcon} style={{paddingRight: '5px'}}/> Sign Up*/}
                                    {/*            with Facebook*/}
                                    {/*        </Button>*/}
                                    {/*    )}/>*/}

                                    <GoogleLogin
                                        clientId={this.GOOGLE_APP_ID}
                                        buttonText="Login"
                                        autoLoad={false}
                                        onSuccess={(response) => this.googleSignUp(response)}
                                        onFailure={(response) => console.error(response)}
                                        render={renderProps => (
                                            <Button onClick={renderProps.onClick}
                                                    className="log-in-modal-button"
                                                    disabled={this.state.isSigningUp}>
                                                <img src={googleLoginIcon} style={{paddingRight: '5px'}}/>
                                                <span
                                                    style={{fontFamily: 'Roboto'}}>Sign Up with Google</span>
                                            </Button>
                                        )}
                                    />
                                </ButtonGroup>
                            </form>
                        </ToggleDisplay>

                        <ToggleDisplay show={this.state.state === 'FORGOT_PASS'}>
                            <form className="log-in-modal">
                                <FormGroup id="loginForm"
                                           validationState={validateEmail}>
                                    <FormControl type="text"
                                                 id="email"
                                                 value={this.state.loginEmailAddress}
                                                 disabled={this.state.isLoggingIn}
                                                 onChange={(e) => this.setState({loginEmailAddress: e.target.value})}
                                                 placeholder="Enter email address"/>

                                    <hr/>

                                    <ToggleDisplay if={!this.state.initiatePasswordResetSuccess}>
                                        <BusyButton className="log-in-modal-button"
                                                    bsStyle="primary"
                                                    isLoading={this.state.isLoggingIn}
                                                    disabled={this.state.isLoggingIn || validateEmail !== 'success'}
                                                    buttonText="Reset Password"
                                                    loadingMessage="Resetting Password..."
                                                    onClick={() => this.initiateForgotPassword()}/>
                                    </ToggleDisplay>
                                    <ToggleDisplay if={this.state.initiatePasswordResetSuccess}>
                                        <Button className="log-in-modal-button"
                                                disabled={true}>
                                            Link sent to registered email address!
                                        </Button>
                                    </ToggleDisplay>

                                    <FormControl.Feedback/>
                                </FormGroup>
                            </form>
                        </ToggleDisplay>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <ToggleDisplay show={this.state.state === 'LOGIN'}>
                        <Button className="center" bsStyle="link"
                                onClick={(e) => this.setState({state: 'FORGOT_PASS'})}>
                            Forgot Password?
                        </Button>

                        <Button className="center" bsStyle="link"
                                onClick={(e) => this.setState({state: 'SIGNUP'})}>
                            New user? Sign up here.
                        </Button>
                    </ToggleDisplay>

                    <ToggleDisplay show={this.state.state === 'SIGNUP'}>
                        <Button className="center" bsStyle="link"
                                onClick={(e) => this.setState({state: 'LOGIN'})}>
                            Existing user? Log In here.
                        </Button>
                    </ToggleDisplay>

                    <ToggleDisplay show={this.state.state === 'FORGOT_PASS'}>
                        <Button className="center" bsStyle="link"
                                onClick={(e) => this.setState({state: 'LOGIN'})}>
                            Back to Login
                        </Button>
                    </ToggleDisplay>
                </Modal.Footer>
            </Modal>
        );
    }

}

export default SignUpLogInModal;