import React from "react";
import Properties from '../../../../util/Properties'
import DateUtils from "../../../../util/DateUtils";

import FontAwesome from "react-fontawesome"
import ToggleDisplay from 'react-toggle-display'
import {SingletonSocialService, SingletonUserService} from "../../../../AppContext";
import BusyButton from "../../../../common_ui/BusyButton";
import LoadingComponent from "../../../../common_ui/LoadingComponent";
import {ButtonGroup, Checkbox, OverlayTrigger, Tooltip} from "react-bootstrap";

class Utils {

    static getDisplayDate(dateString) {
        return DateUtils.dateStringSwitchFormat(dateString, Properties.DATE_TIME_FORMAT, Properties.DISPLAY_DATE_FORMAT);
    }

    static getDisplayDateTime(dateString) {
        return DateUtils.dateStringSwitchFormat(dateString, Properties.DATE_TIME_FORMAT, Properties.DISPLAY_DATE_TIME_FORMAT);
    }

    static getCityDetails(referenceCacheService, destinationCityCode) {
        return referenceCacheService.loadCacheWithCityData([destinationCityCode])
            .then(() => referenceCacheService.getCity(destinationCityCode))
    }

}

class AddToItinerary extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isDeleting: false
        };
        this.socialService = SingletonSocialService;
        this.userService = SingletonUserService;
    }

    handleAddToItinerary() {
        this.setState({isLoading: true});
        return this.socialService.addSuggestionToItinerary(this.props.message.id, this.props.message.messageType)
            .then(() => this.setState({isLoading: false}));
    }

    handleRemoveFromItinerary() {
        this.setState({isLoading: true});
        return this.socialService.removeSuggestionFromItinerary(this.props.message.id, this.props.message.messageType)
            .then(() => this.setState({isLoading: false}));
    }

    handleDeleteMessage() {
        this.setState({isDeleting: true});
        return this.socialService.removeSuggestion(this.props.message.id, this.props.message.messageType)
            .then(() => this.setState({isDeleting: false}));
    }

    render() {
        const newItineraryPrice = this.props.message.costWhenPartOfItinerary || {amount: 0, currency: ""};
        const newItineraryPricePP = this.props.message.costWhenPartOfItineraryPP || {amount: 0, currency: ""};

        return (
            <div>
                <ButtonGroup>
                    {(!this.props.message.addedToItinerary) &&
                    <BusyButton bsStyle="primary"
                                bsSize="sm"
                                onClick={(e) => this.handleAddToItinerary()}
                                disabled={this.state.isLoading || this.state.isDeleting || !this.userService.isUserLoggedIn()}
                                buttonText={this.props.addToItineraryText || "Add To Itinerary"}
                                loadingMessage="Adding..."
                                isLoading={this.state.isLoading}/>}

                    {(!!this.props.message.addedToItinerary) &&
                    <BusyButton bsStyle="primary"
                                bsSize="sm"
                                onClick={(e) => this.handleRemoveFromItinerary()}
                                disabled={this.state.isLoading || this.state.isDeleting || !this.userService.isUserLoggedIn()}
                                buttonText={this.props.removeFromItineraryText || "Remove from Itinerary"}
                                loadingMessage="Removing..."
                                isLoading={this.state.isLoading}/>}

                    <BusyButton bsStyle="danger"
                                bsSize="sm"
                                onClick={(e) => this.handleDeleteMessage()}
                                disabled={this.state.isLoading || this.state.isDeleting || !this.userService.isUserLoggedIn()}
                                buttonText="Delete"
                                loadingMessage="Deleting..."
                                isLoading={this.state.isDeleting}/>
                </ButtonGroup>

                <ToggleDisplay
                    if={!this.props.message.addedToItinerary && !!this.props.message.costWhenPartOfItinerary}>
                    <hr/>
                    <span>This would bring the trip price to <b>{newItineraryPrice.amount + " " + newItineraryPrice.currency
                    + " (" + newItineraryPricePP.amount + " " + newItineraryPricePP.currency + " per person )"}</b>
                        </span>
                </ToggleDisplay>
            </div>
        );
    }
}

class MessageLikes extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLikeInProgress: false
        };
        this.socialService = SingletonSocialService;
        this.userService = SingletonUserService;
    }

    static getLikedMessage(message) {
        const likes = Object.values(message.likes);
        if (likes.length === 0)
            return "";

        const likedUsers = likes.map((message) => {
            return message.userFirstName;
        });

        return likedUsers.join(", ") + " liked this";
    }

    postLike() {
        this.startLikeAction();
        return this.socialService.postLike(this.props.message.id, this.props.message.messageType)
            .then(() => this.finishLikeAction(), () => this.finishLikeAction());
    }

    postUnlike() {
        this.startLikeAction();
        return this.socialService.postUnlike(this.props.message.id, this.props.message.messageType)
            .then(() => this.finishLikeAction(), () => this.finishLikeAction());
    }

    startLikeAction() {
        this.setState({isLikeInProgress: true});
    }

    finishLikeAction() {
        this.setState({isLikeInProgress: false});
    }

    render() {
        return (
            <div className="message-like">
                <ToggleDisplay hide={this.state.isLikeInProgress}>
                    <span className="like-message">{MessageLikes.getLikedMessage(this.props.message)}</span>
                </ToggleDisplay>

                <LoadingComponent isLoading={this.state.isLikeInProgress} fontSize="10px"/>

                <ToggleDisplay if={this.userService.isUserLoggedIn()}>
                    <ToggleDisplay if={this.socialService.isMessageLikedByMe(this.props.message)}>
                        <a onClick={(e) => this.postUnlike()} disabled={this.state.isLikeInProgress}>
                            <FontAwesome name='thumbs-up' className="like-button liked"/>
                        </a>
                    </ToggleDisplay>

                    <ToggleDisplay if={!this.socialService.isMessageLikedByMe(this.props.message)}>
                        <a onClick={(e) => this.postLike()} disabled={this.state.isLikeInProgress}>
                            <FontAwesome name='thumbs-up' className="like-button unliked"/>
                        </a>
                    </ToggleDisplay>
                </ToggleDisplay>

                <ToggleDisplay if={!this.userService.isUserLoggedIn()}>
                    <FontAwesome name='thumbs-up' className="like-button-no-cursor unliked"/>
                </ToggleDisplay>

            </div>
        );
    }
}

class WelcomeMessage extends React.Component {
    render() {
        const messageMode = (e) => this.props.onMessageModeChange(Properties.MESSAGE);
        const flightMode = (e) => this.props.onMessageModeChange(Properties.FLIGHT_SUGGESTION);
        const accommodationMode = (e) => this.props.onMessageModeChange(Properties.ACCOMMODATION_SUGGESTION);
        const otherSuggestionMode = (e) => this.props.onMessageModeChange(Properties.EXTRAS_SUGGESTION);
        const pollMode = (e) => this.props.onMessageModeChange(Properties.POLL);
        const newDestinationMode = (e) => this.props.onMessageModeChange(Properties.NEW_DESTINATION_SUGGESTION);

        return (
            <div className="other-comments existing-comments" style={{maxWidth: '400px'}}>
                <p>
                    Welcome to <b>TripSavvi Beta.</b><br/>
                    This is your trip planning page.<br/>
                    You can use it to <a onClick={messageMode}>Post Messages</a>{', '}<a onClick={flightMode}>Share
                    Flights</a>{', '}
                    <a onClick={accommodationMode}>Accommodation</a>{', '}<a onClick={otherSuggestionMode}>Other
                    Suggestions</a>{', '}
                    <a onClick={pollMode}>New Polls</a> and <a onClick={newDestinationMode}>Search New
                    Destinations</a>{' '}with your friends and family.
                </p>
            </div>
        )
    }
}

class Comment extends React.Component {
    render() {
        const {...props} = this.props;

        return (
            <div id={this.props.message.id} className={this.props.isThisMyComment(this.props.message)
                ? "my-comments existing-comments"
                : "other-comments existing-comments"}>
                <b>{this.props.message.creatorFirstName}: </b> {this.props.message.commentText}

                <MessageLikes {...props}/>
            </div>
        )
    };
}

class FlightSuggestion extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillMount() {
        Utils.getCityDetails(this.props.referenceCacheService, this.props.destinationCityCode)
            .then(city => this.setState({destinationName: city.name}))
    }

    render() {
        const {...props} = this.props;

        return (
            <div id={this.props.message.id} className={this.props.isThisMyComment(this.props.message)
                ? "my-comments existing-comments"
                : "other-comments existing-comments"}>
                <p>
                    <b>{this.props.message.creatorFirstName} </b>
                    <a href={this.props.message.url} target="_blank" rel="noopener noreferrer">
                        has suggested a flight to
                        <b> {this.state.destinationName}</b> <FontAwesome name='plane'/>
                    </a>
                    <br/>
                    Outbound to <b>{this.props.message.outboundDestinationIATA}</b> at
                    <b> {Utils.getDisplayDateTime(this.props.message.outboundDepartureTime)}</b> arriving at
                    <b> {Utils.getDisplayDateTime(this.props.message.outboundArrivalTime)}</b>
                    <br/>
                    Returning to <b>{this.props.message.outboundOriginIATA}</b> at
                    <b> {Utils.getDisplayDateTime(this.props.message.inboundDepartureTime)}</b> arriving at
                    <b> {Utils.getDisplayDateTime(this.props.message.inboundArrivalTime)}</b>
                    <br/>
                    Flight price: <b>{this.props.message.cost.amount + '' + this.props.message.cost.currency}</b>
                </p>

                <AddToItinerary {...props}/>
                <MessageLikes {...props}/>
            </div>
        )
    };
}


class ExtrasSuggestion extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillMount() {
        if (!this.props.destinationCityCode)
            return;

        Utils.getCityDetails(this.props.referenceCacheService, this.props.destinationCityCode)
            .then(city => this.setState({destinationName: city.name}))
    }

    render() {
        const {...props} = this.props;

        return (
            <div id={this.props.message.id} className={this.props.isThisMyComment(this.props.message)
                ? "my-comments existing-comments"
                : "other-comments existing-comments"}>
                <p>
                    <b>{this.props.message.creatorFirstName} </b>
                    <a href={this.props.message.url} target="_blank" rel="noopener noreferrer">
                        has made an suggestion for <b>{this.state.destinationName} ({this.props.message.category})</b>
                        <FontAwesome name='sun'/>
                    </a>
                    <br/>
                    <b>{this.props.message.name}</b>
                    <br/>
                    Description: <b>{this.props.message.description}</b>
                    <br/>
                    Starting at <b> {Utils.getDisplayDateTime(this.props.message.startDate)} </b>
                    and Ending at <b> {Utils.getDisplayDateTime(this.props.message.endDate)} </b>
                    <br/>
                    Price: <b>{this.props.message.cost.amount + '' + this.props.message.cost.currency}</b>
                </p>

                <AddToItinerary {...props}/>
                <MessageLikes {...props}/>
            </div>
        )
    };
}

class AccommodationSuggestion extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillMount() {
        Utils.getCityDetails(this.props.referenceCacheService, this.props.destinationCityCode)
            .then(city => this.setState({destinationName: city.name}))
    }

    render() {
        const {...props} = this.props;

        const cost = this.props.message.cost ? this.props.message.cost : {};

        return (
            <div id={this.props.message.id} className={this.props.isThisMyComment(this.props.message)
                ? "my-comments existing-comments"
                : "other-comments existing-comments"}>
                <p>
                    <b>{this.props.message.creatorFirstName} </b> <a href={this.props.message.url} target="_blank" rel="noopener noreferrer">
                    has suggested accommodation in <b> {this.state.destinationName} <FontAwesome name='bed'/></b>
                </a>
                    <br/>
                    <b>{this.props.message.accommodationName}</b>
                    <br/>
                    Checking in at
                    <b> {Utils.getDisplayDate(this.props.message.checkInDate)} </b> and checking out
                    <b> {Utils.getDisplayDate(this.props.message.checkOutDate)}</b>
                    <br/>
                    Accommodation price: <b>{cost.amount + '' + cost.currency}</b>
                </p>

                <AddToItinerary {...props}/>
                <MessageLikes {...props}/>
            </div>
        )
    };
}

class NewDestinationSuggestion extends React.Component {
    static getCityDetailsHref(props) {
        return Properties.ROUTE_LOAD_TRIP_CITY_DETAIL_PAGE
            .replace(':id', props.tripId)
            .replace(':originCityCode', props.originCityCode)
            .replace(':destinationCityCode', props.message.destinationCityCode)
            .replace(':chosenStartDate', props.startTravelDate)
            .replace(':chosenEndDate', props.endTravelDate);
    }

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillMount() {
        Utils.getCityDetails(this.props.referenceCacheService, this.props.message.destinationCityCode)
            .then(city => this.setState({destinationName: city.name + ", " + city.countryName + " (" + city.code + ")"}))
    }

    render() {
        const {...props} = this.props;

        return (
            <div id={this.props.message.id} className={this.props.isThisMyComment(this.props.message)
                ? "my-comments existing-comments"
                : "other-comments existing-comments"}>
                <p>
                    <b>{this.props.message.creatorFirstName} </b>
                    <a href={NewDestinationSuggestion.getCityDetailsHref(this.props)} target="_blank" rel="noopener noreferrer">
                        has suggested a new destination -
                        <b> {this.state.destinationName} <FontAwesome name='sun'/></b></a>
                    <br/>
                    <ToggleDisplay if={!!this.props.message.commentText}>
                        <i>"{this.props.message.commentText}"</i>
                    </ToggleDisplay>
                </p>

                <AddToItinerary {...props}
                                addToItineraryText="Add Destination To Trip"
                                removeFromItineraryText="Remove Destination From Trip"/>
                <MessageLikes {...props}/>
            </div>
        )
    };
}

class Poll extends React.Component {

    constructor(props) {
        super(props);
        this.socialService = SingletonSocialService;
        this.userService = SingletonUserService;
        this.state = {
            isDeleting: false,
            isVoting: this.props.message.pollOptions.map(() => false)
        };
    }

    handleAddVoteToPoll(index) {
        this.updateIsVotingAction(index, true);
        return this.socialService.updatePoll(this.props.message.id, 'ADD_VOTE', index)
            .then(() => this.updateIsVotingAction(index, false));
    }

    handleRemoveVoteFromPoll(index) {
        this.updateIsVotingAction(index, true);
        return this.socialService.updatePoll(this.props.message.id, 'REMOVE_VOTE', index)
            .then(() => this.updateIsVotingAction(index, false));
    }

    isUserAlreadyVoted(index) {
        const pollOption = this.props.message.pollOptions[index];
        return this.socialService.isVotedByMe(pollOption);
    }

    isUserVotedForAnyOption() {
        return this.props.message.pollOptions.some(pollOption => this.socialService.isVotedByMe(pollOption));
    }

    updateIsVotingAction(index, bool) {
        const voting = this.state.isVoting;
        voting[index] = bool;
        this.setState({isVoting: voting})
    }

    handleDeleteMessage() {
        this.setState({isDeleting: true});
        return this.socialService.removeSuggestion(this.props.message.id, this.props.message.messageType)
            .then(() => this.setState({isDeleting: false}));
    }

    render() {
        const message = this.props.message;

        const votesDisplay = (pollOptionsVotes) => Object.values(pollOptionsVotes).map((singleVote, index) =>
            <OverlayTrigger
                key={index}
                placement='bottom'
                trigger='click'
                overlay={
                    <Tooltip>
                        Voted by <strong>{singleVote.userFirstName}</strong>.
                    </Tooltip>
                }>
                <FontAwesome name='check'/>
            </OverlayTrigger>
        );

        const allowVote = !!message.pollAllowMultipleVotes || !this.isUserVotedForAnyOption();

        const votesPerOptions = message.pollOptions.map((pollOption, index) => {
                const alreadyVoted = this.isUserAlreadyVoted(index);

                return <div key={index} className="poll-option-message-component">
                    <div className="left">
                        <b>{pollOption.option}</b>
                    </div>

                    <div className="middle"/>

                    <div className="right">
                        {votesDisplay(pollOption.votes)}

                        <BusyButton bsStyle="primary"
                                    bsSize="sm"
                                    onClick={!alreadyVoted
                                        ? (e) => this.handleAddVoteToPoll(index)
                                        : (e) => this.handleRemoveVoteFromPoll(index)}
                                    disabled={this.state.isDeleting || !this.userService.isUserLoggedIn() || (!alreadyVoted && !allowVote)}
                                    buttonText={!alreadyVoted ? 'Vote' : 'Unvote'}
                                    loadingMessage="Updating..."
                                    isLoading={this.state.isVoting[index]}/>
                    </div>
                </div>;
            }
        );

        return (
            <div id={message.id} className={this.props.isThisMyComment(message)
                ? "my-comments existing-comments"
                : "other-comments existing-comments"}>

                <span>
                    <b>{message.creatorFirstName} </b>
                        has started a Poll - <b><FontAwesome name='poll'/></b>
                    <br/>
                    <b>{message.pollQuestion + (!!message.pollAllowMultipleVotes ? ' (You can vote for multiple options)' : '')}</b>
                    <hr/>
                    {votesPerOptions}
                </span>

                <BusyButton bsStyle="danger"
                            bsSize="sm"
                            onClick={(e) => this.handleDeleteMessage()}
                            disabled={this.state.isDeleting || !this.userService.isUserLoggedIn()}
                            buttonText="Delete"
                            loadingMessage="Deleting..."
                            isLoading={this.state.isDeleting}/>
            </div>
        )
    }
}

class TaskList extends React.Component {

    constructor(props) {
        super(props);
        this.socialService = SingletonSocialService;
        this.userService = SingletonUserService;
        this.state = {
            isSubmitting: false
        };
    }

    handleMarkAsDone(index) {
        this.updateIsSubmittingAction(true);
        return this.socialService.updateTaskList(this.props.message.id, 'COMPLETE_TASK', index)
            .then(() => this.updateIsSubmittingAction(false));
    }

    handleMarkAsNotDone(index) {
        this.updateIsSubmittingAction(true);
        return this.socialService.updateTaskList(this.props.message.id, 'UNCOMPLETE_TASK', index)
            .then(() => this.updateIsSubmittingAction(false));
    }

    updateIsSubmittingAction(bool) {
        this.setState({isSubmitting: bool})
    }

    render() {
        const {message} = this.props;
        const {id, creatorFirstName, taskListName, taskList} = message;

        const taskListComponents = taskList.map((task, index) => {
            const doneClassName = task.done ? 'task-done' : '';

            return <div key={index} className="poll-option-message-component">
                <div className="left">
                    <b className={doneClassName}>{task.taskName}</b>
                    <i className={doneClassName}>{task.dueDate && `(Due: ${DateUtils.getDisplayDate(task.dueDate)})`}</i>
                </div>

                <div className="middle"/>

                <div className="right">
                    <Checkbox defaultChecked={task.done}
                              disabled={this.state.isSubmitting || !this.userService.isUserLoggedIn()}
                              onChange={!task.done
                                  ? (e) => this.handleMarkAsDone(index)
                                  : (e) => this.handleMarkAsNotDone(index)}/>
                </div>
            </div>;
        });

        return (
            <div id={id} className={this.props.isThisMyComment(message)
                ? "my-comments existing-comments"
                : "other-comments existing-comments"}>
                <span>
                    <b>{creatorFirstName} </b>
                        has started a Task List - <b><FontAwesome name='tasks'/></b>
                    <br/>
                    <b>{taskListName}</b>
                    <hr/>
                    {taskListComponents}
                </span>
                <hr/>
                <MessageLikes {...this.props}/>
            </div>
        )
    }
}

class TaskListUpdate extends React.Component {
    render() {
        const {message} = this.props;
        const {id, taskListName} = message;

        return (
            <div id={id} className={this.props.isThisMyComment(message)
                ? "my-comments existing-comments"
                : "other-comments existing-comments"}>
                <span>
                    <a onClick={() => this.props.showMessage(message.previousId)}>
                        <b>{taskListName}</b> (Task List)
                    </a> is complete - <b><FontAwesome name='poll'/></b>
                </span>
            </div>
        )
    }
}

export {
    WelcomeMessage,
    Comment,
    FlightSuggestion,
    ExtrasSuggestion,
    AccommodationSuggestion,
    NewDestinationSuggestion,
    Poll,
    TaskList,
    TaskListUpdate
}
