import * as React from "react";
import {ItineraryItem} from "../../../../model/Model";

export interface ItineraryProps {
    itineraryItem: ItineraryItem,
    title?: string,
    onSaveItineraryItem: (itineraryItem: ItineraryItem) => Promise<any>
}

export interface IntineraryState {
    itineraryItem: ItineraryItem,
    editMode: boolean,
    isSaving: boolean
}

class ItineraryComponent extends React.Component<ItineraryProps, IntineraryState> {

    constructor(props: ItineraryProps) {
        super(props);
        this.state = {
            itineraryItem: props.itineraryItem,
            editMode: false,
            isSaving: false
        };
    }

    componentWillReceiveProps(nextProps: Readonly<ItineraryProps>) {
        if (nextProps.itineraryItem !== this.state.itineraryItem) {
            this.setState({itineraryItem: nextProps.itineraryItem});
        }
    }

    setEditMode(bool: boolean) {
        this.setState({editMode: bool})
    }

    onSave() {
        this.setState({isSaving: true});

        const promise: Promise<{}> = this.props.onSaveItineraryItem
            ? this.props.onSaveItineraryItem(this.state.itineraryItem)
            : Promise.resolve({});

        return promise.then(() => this.setState({isSaving: false}));
    }

    onCancel() {
        this.setState({itineraryItem: this.props.itineraryItem})
    }

}

export {ItineraryComponent}