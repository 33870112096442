import React from "react";
import {Button, ButtonGroup} from "react-bootstrap";
import FontAwesome from "react-fontawesome"
import Properties from "../../../../util/Properties";

class MessageButtonGroup extends React.Component {

    render() {
        return (
            <React.Fragment>
                <ButtonGroup>
                    <Button id="message-button"
                            bsStyle="primary"
                            onClick={(e) => this.props.onMessageModeChange(Properties.MESSAGE)}
                            active={this.props.messageMode === Properties.MESSAGE}>
                        <FontAwesome name='comment'/><span className="mobile-hide"> Post Message</span>
                    </Button>
                    <Button id="flight-suggestion-button"
                            bsStyle="primary"
                            onClick={(e) => this.props.onMessageModeChange(Properties.FLIGHT_SUGGESTION)}
                            active={this.props.messageMode === Properties.FLIGHT_SUGGESTION}>
                        <FontAwesome name='plane'/><span className="mobile-hide"> Suggest Flight</span>
                    </Button>
                    <Button id="accommodation-suggestion-button"
                            bsStyle="primary"
                            onClick={(e) => this.props.onMessageModeChange(Properties.ACCOMMODATION_SUGGESTION)}
                            active={this.props.messageMode === Properties.ACCOMMODATION_SUGGESTION}>
                        <FontAwesome name='bed'/><span className="mobile-hide"> Suggest Accommodation</span>
                    </Button>
                    <Button id="extras-suggestion-button"
                            bsStyle="primary"
                            onClick={(e) => this.props.onMessageModeChange(Properties.EXTRAS_SUGGESTION)}
                            active={this.props.messageMode === Properties.EXTRAS_SUGGESTION}>
                        <FontAwesome name='glass-martini'/><span
                        className="mobile-hide"> Other Suggestion</span>
                    </Button>
                    <Button id="new-destination-suggestion-button"
                            bsStyle="primary"
                            onClick={(e) => this.props.onMessageModeChange(Properties.NEW_DESTINATION_SUGGESTION)}
                            active={this.props.messageMode === Properties.NEW_DESTINATION_SUGGESTION}>
                        <FontAwesome name='sun'/><span className="mobile-hide"> Search New Destinations</span>
                    </Button>
                </ButtonGroup>
                <br/>
                <ButtonGroup>
                    <Button id="new-poll"
                            bsStyle="primary"
                            onClick={(e) => this.props.onMessageModeChange(Properties.POLL)}
                            active={this.props.messageMode === Properties.POLL}>
                        <FontAwesome name='poll'/><span className="mobile-hide"> New Poll</span>
                    </Button>
                    <Button id="message-button"
                            bsStyle="primary"
                            onClick={(e) => this.props.onMessageModeChange(Properties.TODO_LIST)}
                            active={this.props.messageMode === Properties.TODO_LIST}>
                        <FontAwesome name='tasks'/><span className="mobile-hide"> Create Task List</span>
                    </Button>
                </ButtonGroup>
            </React.Fragment>
        );
    }

}

export default MessageButtonGroup;