type ValidationResult = 'warning' | 'success' | 'error';

class ValidationUtils {

    static PRICE_REG_EXP: RegExp = /^[0-9]+\.{0,1}[0-9]{0,2}$/;
    static EMAIL_REG_EXP: RegExp = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

    static validateNonNull(value: any): ValidationResult {
        return !value ? "warning" : "success";
    }

    static validateValueNotEquals(value: any, notEqualValue: any): ValidationResult {
        if (!value) {
            return 'warning';
        }

        if (value === notEqualValue) {
            return 'warning';
        } else {
            return 'success';
        }
    }

    static validateValueOver(value: number, minimumValue: number): ValidationResult {
        if (value >= minimumValue) {
            return 'success';
        } else {
            return 'warning';
        }
    }

    static validateLength(value: string, minimumLength: number, maximumLength: number = 99999999999): ValidationResult {
        if (!value || value.length === 0) {
            return 'warning';
        }

        if (value.length >= minimumLength && (value.length <= maximumLength)) {
            return 'success';
        } else {
            return 'error';
        }
    }

    static validatePrice(value: string | undefined): ValidationResult {
        return ValidationUtils.validateRegEx(value, ValidationUtils.PRICE_REG_EXP);
    }

    static validateEmail(value: string | undefined): ValidationResult {
        return ValidationUtils.validateRegEx(value, ValidationUtils.EMAIL_REG_EXP);
    }

    static validateRegEx(value: string | undefined, regex: RegExp) {
        if (!value || value.length === 0) {
            return 'warning';
        }

        if (regex.test(value)) {
            return 'success';
        } else {
            return 'error';
        }
    }

    static combineValidationResults(validationResults: ValidationResult[]) {
        if (validationResults.filter(validationResult => validationResult === "error").length > 0) {
            return "error";
        } else if (validationResults.filter(validationResult => validationResult === "warning").length > 0) {
            return "warning";
        }

        return "success";
    }

}

export default ValidationUtils;