import * as React from "react";
import {ReactNode} from "react";
import {SingletonAnalyticsService, SingletonUserService} from "./AppContext";
import SessionStore from "./state/SessionStore";
import UserService from "./service/UserService";
import AnalyticsService from "./service/AnalyticsService";
import {reloadWindowOnTokenExpiry} from "./service/HttpUtilService";
import LoadingComponent from "./common_ui/LoadingComponent";

interface Props {
    children: ReactNode
}
class AppSecurityProvider extends React.Component<Props, {shouldRefreshToken: boolean}> {

    VERSION_STORE = 'version';
    CURRENT_LOCAL_STORAGE_VERSION = '0.9.22';

    userService: UserService;
    analyticsService: AnalyticsService;

    constructor(props: Props) {
        super(props);
        this.userService = SingletonUserService;
        this.analyticsService = SingletonAnalyticsService;
        this.state = {
            shouldRefreshToken: true
        }
    }

    componentDidMount() {
        if (this.isLocalStorageVersionIncompatible()) {
            console.log('incompatible local storage version');
            this.clearLocalStorage();
        }

        if (this.state.shouldRefreshToken) {
            reloadWindowOnTokenExpiry().then(() => {
                this.setState({shouldRefreshToken: false});
                return this.userService.getUserTrips();
            });
        }

        if (process.env.NODE_ENV === 'production') {
            this.analyticsService.logPageView();
        }
    }

    isLocalStorageVersionIncompatible(): boolean {
        const localStoreVersion = SessionStore.getItemOrDefault(this.VERSION_STORE, '0.0.1');
        return this.CURRENT_LOCAL_STORAGE_VERSION !== localStoreVersion;
    }

    clearLocalStorage() {
        SessionStore.clearAll();
        SessionStore.setItem(this.VERSION_STORE, this.CURRENT_LOCAL_STORAGE_VERSION);
        window.location.reload();
    }

    render() {
        if (this.state.shouldRefreshToken) {
            return (
                <div className="col-v-centered" style={{height: '100vh'}}>
                    <LoadingComponent isLoading={true}/>
                </div>
            )
        }

        return (
            <div>
                {this.props.children}
            </div>
        )
    }
}

export default AppSecurityProvider;