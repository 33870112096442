import React from "react";
import {SingletonReferenceCacheService} from "../AppContext";
import LoadingComponent from "./LoadingComponent";
import BarChart from "./BarChart";
import FontAwesome from "react-fontawesome"
import {Button} from "react-bootstrap";
import {SizeMe} from 'react-sizeme'

class DetailedCityInfo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            info: undefined,
            currentIndex: 0
        };
        this.referenceCacheService = SingletonReferenceCacheService
        this.myRef = React.createRef();
    }

    getBackgroundImageString() {
        return 'url("' + this.referenceCacheService.getLargeCityPhoto(this.props.match.params.destinationCityCode) + '")';
    }

    componentWillMount() {
        this.referenceCacheService.loadCacheWithCityData([this.props.match.params.destinationCityCode])
            .then(() => this.referenceCacheService.fetchCityDetailedInfo(
                this.props.match.params.originCityCode,
                this.props.match.params.destinationCityCode,
                this.props.match.params.chosenStartDate,
                this.props.match.params.chosenEndDate))
            .then((responseData) => this.setState({info: responseData}))
    }

    setCurrentIndex = currentIndex =>
        this.setState({
            currentIndex
        });


    render() {
        if (!this.state.info) {
            return (
                <div className="destination-suggestion-section col-v-centered" style={{height: '80vh'}}>
                    <LoadingComponent isLoading={true}/>
                </div>
            );
        }

        const stars = (noOfStars) => [...new Array(noOfStars).keys()].map((star, index) =>
            <FontAwesome key={index} name='star' style={{color: 'white'}}/>
        );

        const hotelPrices = this.state.info.hotelPricesForChosenPeriod.map((hotelPrice, index) =>
            <div key={index} style={{width: '100%', height: '30px', padding: '5px'}}>
                <div style={{float: 'left'}}>
                    {stars(hotelPrice.stars)}
                </div>

                <div style={{margin: '0 auto'}}/>

                <div style={{float: 'right'}}>
                    <p style={{color: 'white'}}>£{hotelPrice.priceAvgDailyRateGBP} per night</p>
                </div>
            </div>
        );

        const temperatureInfo = this.state.info.yearTemperatureInfo[this.state.currentIndex];


        return (
            <div className="destination-suggestion-section">
                <div>
                    <div style={{backgroundImage: this.getBackgroundImageString()}}
                         className="destination-suggestion-large">
                        <div className='destination-suggestion-title'>
                            <span>{this.state.info.city.name}, {this.state.info.country.name}</span>
                        </div>
                    </div>

                    <br/>

                    <div className="find-destination-section">
                        {/*<div>*/}
                            {/*<p style={{fontSize: '16px'}}>Average Hotel Price</p>*/}
                            {/*{hotelPrices}*/}
                        {/*</div>*/}

                        <SizeMe>
                            {({size}) => <div id="bar-container" ref={this.myRef}>
                                <p style={{fontSize: '16px'}}>All Year Temperature</p>

                                <svg width="100%">
                                    <BarChart
                                        data={this.state.info.yearTemperatureInfo.map(x => x.avgTemperatureC)}
                                        displayValues={this.state.info.yearTemperatureInfo.map(x => x.avgTemperatureC + "°C")}
                                        width={size.width}
                                        height={200}
                                        x={0}
                                        y={0}
                                        highlightBar={this.setCurrentIndex}
                                        highlightedBar={this.state.currentIndex}/>
                                </svg>

                                <p>
                                    <b>{temperatureInfo.month + " "}</b>
                                </p>
                                <p>
                                    {"High: " + temperatureInfo.maxTemperatureC + "°C, "}
                                    {"Average: " + temperatureInfo.avgTemperatureC + "°C, "}
                                    {"Low: " + temperatureInfo.minTemperatureC + "°C"}
                                </p>
                            </div>}
                        </SizeMe>

                    </div>
                </div>

                <Button bsSize="large" href={this.props.backPageRoute}>Back</Button>
            </div>
        );
    }
}

export default DetailedCityInfo;

