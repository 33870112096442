import React from "react";
import Properties from '../../../../../util/Properties'
import {Button, ButtonGroup} from "react-bootstrap";
import {Redirect} from "react-router-dom";
import {SingletonDestinationSuggestionService, SingletonTripCreationSharedState} from "../../../../../AppContext";
import SessionStore from "../../../../../state/SessionStore";
import SuggestionsInputSection, {SuggestionTripDestinationFilter} from "../../../../../common_ui/SuggestionsInputSection";
import {UserDestinationSuggestionInput} from "../../../../../common_ui/UserDestinationSuggestionSection";

class PostDestinationSuggestionInputs extends React.Component {

    STORE_NAME = 'postDestinationSuggestionInputs';

    static getInitialState() {
        return {
            temperature: 0,
            spendPerPerson: 0,
            anyTemperature: false,
            anyPrice: false,
            anyActivities: false,
            destinationSearch: true,
            selectedActivities: SuggestionsInputSection.selectedActivities()
        }
    }

    constructor(props) {
        super(props);
        const store = SessionStore.getItemOrDefault(this.STORE_NAME, PostDestinationSuggestionInputs.getInitialState());
        this.state = {
            ...store,
            next: false,
            nextTripPlanning: false,
            isLoadingSuggestions: false
        };
        this.sharedState = SingletonTripCreationSharedState;
        this.destinationSuggestionService = SingletonDestinationSuggestionService;
    }

    storeState(partialState, callback) {
        this.setState(partialState, () => {
            SessionStore.setItem(this.STORE_NAME, this.state);
            callback && callback();
        });
    }

    componentWillMount() {
        this.sharedState.subscribeOnState((state) => { //TODO use redux
            this.setState({
                isLoadingSuggestions: state.isLoadingSuggestions,
            })
        });
    }

    handleSelectedActivityChange(index) {
        let activities = this.state.selectedActivities;
        activities[index].checked = !activities[index].checked;
        this.storeState({selectedActivities: activities});
    }

    selectDestinationSearch(bool) {
        this.storeState({destinationSearch: bool})
    }

    handleFindDestinations() {
        const selectedTripTypes = this.state.selectedActivities.filter(it => it.checked).map(it => it.name);
        const suggestionRequest = {
            originCityCode: this.props.originCityCode,
            destinationCityCodesToIgnore: this.props.cityCodesToIgnore,
            noOfAdults: this.props.noOfAdults,
            noOfChildren: this.props.noOfChildren,
            startTravelDate: this.props.startTravelDate,
            endTravelDate: this.props.endTravelDate,
            minimumTemperature: !(this.state.anyTemperature) ? this.state.temperature - 5 : null,
            maximumTemperature: !(this.state.anyTemperature) ? this.state.temperature + 5 : null,
            maxSpendPerPerson: !(this.state.anyPrice) ? this.state.spendPerPerson : null,
            tripTypes: !(this.state.anyActivities) ? selectedTripTypes : null
        };

        this.destinationSuggestionService.getDestinationSuggestions(suggestionRequest)
            .then(() => this.setState({next: true}));
    }

    render() {
        if (this.state.next) {
            const url = Properties.ROUTE_LOAD_TRIP_NEW_DESTINATION_SEARCH_RESULTS.replace(":id", this.props.tripId);
            return <Redirect to={url}/>
        }

        if (this.state.nextTripPlanning) {
            const url = Properties.ROUTE_LOAD_TRIP_NEW_DESTINATION_SEARCH_RESULTS_CONFIRM.replace(":id", this.props.tripId);
            return <Redirect to={url}/>
        }

        return (
            <div style={{marginTop: '20px'}}>
                <div className="find-destination-section">
                    <div>
                        <ButtonGroup className="create-trip-button-group">
                            <Button bsStyle="primary" style={{width: '50%'}}
                                    active={this.state.destinationSearch}
                                    onClick={(e) => this.selectDestinationSearch(true)}>
                                Be inspired
                            </Button>
                            <Button bsStyle="primary" style={{width: '50%'}}
                                    active={!this.state.destinationSearch}
                                    onClick={(e) => this.selectDestinationSearch(false)}>
                                Select my own destination
                            </Button>
                        </ButtonGroup>
                    </div>

                    {this.state.destinationSearch
                        ? <SuggestionTripDestinationFilter selectedActivities={this.state.selectedActivities}
                                                           spendPerPerson={this.state.spendPerPerson}
                                                           temperature={this.state.temperature}

                                                           anyActivities={this.state.anyActivities}
                                                           anyPrice={this.state.anyPrice}
                                                           anyTemperature={this.state.anyTemperature}

                                                           handleSelectedActivityChange={(index) => this.handleSelectedActivityChange(index)}
                                                           handleSpendPerPersonChange={(value) => this.storeState({spendPerPerson: value})}
                                                           handleTemperatureChange={(value) => this.storeState({temperature: value})}

                                                           handleAnyActivitiesChange={(checked) => this.storeState({anyActivities: checked})}
                                                           handleAnyPriceChange={(checked) => this.storeState({anyPrice: checked})}
                                                           handleAnyTemperatureChange={(checked) => this.storeState({anyTemperature: checked})}

                                                           handleFindDestinations={() => this.handleFindDestinations()}
                                                           handleCancel={() => this.props.onCancel()}
                                                           disabled={this.state.isLoadingSuggestions}/>

                        : <UserDestinationSuggestionInput startTravelDate={this.props.startTravelDate}
                                                          endTravelDate={this.props.endTravelDate}
                                                          originCityCode={this.props.originCityCode}
                                                          noOfAdults={this.props.noOfAdults}
                                                          noOfChildren={this.props.noOfChildren}
                                                          handleNext={() => this.setState({nextTripPlanning: true})}
                                                          handleCancel={() => this.props.onCancel()}/>
                    }
                </div>
            </div>
        );
    }
}

export default PostDestinationSuggestionInputs;