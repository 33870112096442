import SessionStore from './SessionStore'
import {StoredUser} from "../model/Model";


export interface Token {
    accessToken?: string,
    refreshToken?: string
}

class UserState {

    static STATE_NAME = 'userSessionStore';
    static TOKEN_STATE_NAME = 'tokenStore';

    currentUser: StoredUser | undefined;
    token: { accessToken: string, refreshToken: string } | undefined;

    constructor() {
        this.currentUser = SessionStore.getItemOrDefault(UserState.STATE_NAME, undefined);
        this.token = SessionStore.getItemOrDefault(UserState.TOKEN_STATE_NAME, undefined);
    }

    setCurrentUser(userId: string, userEmail: string, firstName: string, lastName: string, userDisplayPicture: string) {
        this.currentUser = {
            userId: userId,
            userEmail: userEmail,
            firstName: firstName,
            lastName: lastName,
            userDisplayPicture: userDisplayPicture
        };
        SessionStore.setItem(UserState.STATE_NAME, this.currentUser);
    }

    getCurrentUser(): StoredUser | undefined {
        return this.currentUser;
    }

    setToken(accessToken: string, refreshToken: string) {
        this.token = {
            accessToken: accessToken,
            refreshToken: refreshToken
        };
        SessionStore.setItem(UserState.TOKEN_STATE_NAME, this.token);
    }

    getToken(): Token {
        if (!this.token) {
            return {};
        }
        return this.token;
    }

    clearCurrentUser() {
        this.currentUser = undefined;
        this.token = undefined;
        SessionStore.clearItem(UserState.STATE_NAME);
        SessionStore.clearItem(UserState.TOKEN_STATE_NAME);
    }
}

const globalState = new UserState();
export default globalState;