import DateUtils from "../util/DateUtils";
import Properties from "../util/Properties";
import DatePicker from "react-datepicker";
import moment from "moment";
import DestinationSelector from "./DestinationSelector";
import React from "react";

export function TripParametersInput({
                                        originCityCode, setOriginCityCode,
                                        noOfAdults, setNoOfAdults,
                                        noOfChildren, setNoOfChildren,
                                        startTravelDate, setStartTravelDate,
                                        endTravelDate, setEndTravelDate
                                    }) {

    const numberOfAdultsOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9].map((number) =>
        <option key={number} value={number}>{number}</option>);

    const numberOfChildrenOptions = [0, 1, 2, 3, 4, 5, 6].map((number) =>
        <option key={number} value={number}>{number}</option>);

    let endDatePicker = undefined;

    const handleStartTravelDateChange = (newDate) => {
        setStartTravelDate(DateUtils.momentToString(Properties.DATE_FORMAT, newDate));
        setEndTravelDate(DateUtils.momentToString(Properties.DATE_FORMAT, newDate.add(1, "days")));
    };

    const handleEndTravelDateChange = (newDate) => {
        setEndTravelDate(DateUtils.momentToString(Properties.DATE_FORMAT, newDate));
    };

    return (
        <div className="find-destination-section">
            <div>
                <p>How many people are travelling?</p>

                <div className="people-amount-selection">
                    <div>
                        <label htmlFor="no-of-adults-select">Adults (18+)</label>
                        <select className="form-control input-sm" id="no-of-adults-select"
                                value={noOfAdults}
                                onChange={(e) => setNoOfAdults(e.target.value)}>
                            {numberOfAdultsOptions}
                        </select>
                    </div>

                    <div>
                        <label htmlFor="no-of-children-select">Children</label>
                        <select className="form-control input-sm" id="no-of-children-select"
                                value={noOfChildren}
                                onChange={(e) => setNoOfChildren(e.target.value)}>
                            {numberOfChildrenOptions}
                        </select>
                    </div>
                </div>
            </div>

            <div>
                <p>When are you travelling?</p>
                <div className="people-amount-selection">
                    <div>
                        <label htmlFor="travel-start-date">Start Date</label>
                        <DatePicker id="travel-start-date"
                                    selected={DateUtils.stringToMoment(startTravelDate, Properties.DATE_FORMAT)}
                                    startDate={DateUtils.stringToMoment(startTravelDate, Properties.DATE_FORMAT)}
                                    endDate={DateUtils.stringToMoment(endTravelDate, Properties.DATE_FORMAT)}
                                    className="form-control input-sm"
                                    onChange={(newDate) => handleStartTravelDateChange(newDate)}
                                    onSelect={() => endDatePicker.input.focus()}
                                    minDate={moment().add(1, "days")}
                                    maxDate={moment().add(1, "years")}
                                    dateFormat={Properties.DISPLAY_DATE_FORMAT}
                                    showMonthYearDropdown
                                    withPortal
                                    selectsStart/>
                    </div>

                    <div>
                        <label htmlFor="travel-end-date">End Date</label>
                        <DatePicker id="travel-end-date"
                                    ref={(datePicker) => {
                                        endDatePicker = datePicker
                                    }}
                                    selected={DateUtils.stringToMoment(endTravelDate, Properties.DATE_FORMAT)}
                                    startDate={DateUtils.stringToMoment(startTravelDate, Properties.DATE_FORMAT)}
                                    endDate={DateUtils.stringToMoment(endTravelDate, Properties.DATE_FORMAT)}
                                    className="form-control input-sm"
                                    onChange={(newDate) => handleEndTravelDateChange(newDate)}
                                    minDate={DateUtils.stringToMoment(startTravelDate)}
                                    maxDate={moment().add(1, "years")}
                                    dateFormat={Properties.DISPLAY_DATE_FORMAT}
                                    showMonthYearDropdown
                                    withPortal
                                    selectsEnd/>
                    </div>
                </div>
            </div>

            <div>
                <p>Where are you traveling from?</p>
                <DestinationSelector id="origin-selector"
                                     showDestinationDetails={false}
                                     selectedDestination={originCityCode}
                                     onSelect={(code) => setOriginCityCode(code)}/>
            </div>
        </div>
    )
}