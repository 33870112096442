import React from 'react'
import ToggleDisplay from 'react-toggle-display'
import FontAwesome from "react-fontawesome"

import SuggestionsInputSection from '../../common_ui/SuggestionsInputSection'
import {Button, ButtonGroup, Carousel, Panel} from "react-bootstrap";
import UserDestinationSuggestionSection from "../../common_ui/UserDestinationSuggestionSection";
import SessionStore from "../../state/SessionStore";

import compareDestinationsImg from '../../assets/img/compare_destinations.png'
import destinationShortlistImg from '../../assets/img/destination_shortlist.png'
import collaborateImg from '../../assets/img/collaborate.png'
import buildItineraryImg from '../../assets/img/build_itinerary.png'
import {RouteComponentProps} from "react-router";

interface WelcomeInstructionProps {
    showCarousel: boolean,
    toggleCarousel: (value: boolean) => void
}
const WelcomeInstruction: React.FC<WelcomeInstructionProps> = ({showCarousel, toggleCarousel}) => (
    <div className="trip-savvi-instructions">
        <ToggleDisplay hide={showCarousel}>
            <span className="beta-title">Welcome to the Tripsavvi Beta.</span>
            <br/>
            <span><FontAwesome name='check-circle'/> Plan your solo trip or with your friends and family.</span>
            <br/>
            <Button bsStyle="link" onClick={() => toggleCarousel(true)}>
                See more...
            </Button>
        </ToggleDisplay>
        <Panel expanded={showCarousel}>
            <Panel.Collapse>
                <Panel.Body className="welcome-instruction-panel-body">
                    <Carousel interval={null}>
                        <Carousel.Item>
                            <img src={compareDestinationsImg} alt="compare destinations"/>
                            <Carousel.Caption>
                                <h4>Compare destinations</h4>
                                <p>Search for trip destinations and compare them based on average temperature and
                                    things to do</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img src={destinationShortlistImg} alt="destination shortlist"/>
                            <Carousel.Caption>
                                <h4>Make a destination shortlist</h4>
                                <p>Not decided where to go? Choose between multiple destinations with friends and
                                    family or for your solo trip.</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img src={collaborateImg} alt="collaborate and plan"/>
                            <Carousel.Caption>
                                <h4>Collaborate and plan your trip.</h4>
                                <p>Share messages or potential flight, accommodation and activities and plan your
                                    trip collaboratively.</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img src={buildItineraryImg} alt="build itinerary"/>
                            <Carousel.Caption>
                                <h4>Build your itinerary</h4>
                                <p>Create your trip and determine the cost per person in real time. Perfect for the
                                    Savvi traveller!</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                    </Carousel>
                </Panel.Body>
                <Panel.Footer>
                    <Button block bsStyle="link" onClick={() => toggleCarousel(false)}>
                        Got it!
                    </Button>
                </Panel.Footer>
            </Panel.Collapse>
        </Panel>
    </div>
);


interface Props extends RouteComponentProps<{ noOfAdults: string, noOfChildren: string, startTravelDate: string, endTravelDate: string }> {
    nextPageSelectedDestinationsRoute: string
    nextPageInspirationDestinationsRoute: string
}

interface State {
    destinationSearch: boolean;
    showCarousel: boolean
}

class DiscoverPage extends React.Component<Props, State> {

    STORE_NAME = 'discoverPage';

    constructor(props: Props) {
        super(props);
        this.state = SessionStore.getItemOrDefault(this.STORE_NAME, {
            destinationSearch: true,
            showCarousel: true
        });
    }

    storeState(partialState: Partial<State>) {
        this.setState({...this.state, ...partialState}, () => SessionStore.setItem(this.STORE_NAME, this.state));
    }

    selectDestinationSearch(bool: boolean) {
        this.storeState({destinationSearch: bool})
    }

    toggleCarousel(bool: boolean) {
        this.storeState({showCarousel: bool})
    }

    render() {
        return (
            <div className="create-trip-section">
                <WelcomeInstruction showCarousel={this.state.showCarousel}
                                    toggleCarousel={(bool: boolean) => this.toggleCarousel(bool)}/>
                <div>
                    <ButtonGroup className="create-trip-button-group">
                        <Button bsStyle="primary" style={{width: '50%'}}
                                active={this.state.destinationSearch}
                                onClick={() => this.selectDestinationSearch(true)}>
                            Be inspired
                        </Button>
                        <Button bsStyle="primary" style={{width: '50%'}}
                                active={!this.state.destinationSearch}
                                onClick={() => this.selectDestinationSearch(false)}>
                            Select my own destination
                        </Button>
                    </ButtonGroup>
                </div>


                <ToggleDisplay if={this.state.destinationSearch}>
                    <SuggestionsInputSection nextPageRoute={this.props.nextPageInspirationDestinationsRoute}/>
                </ToggleDisplay>

                <ToggleDisplay if={!this.state.destinationSearch}>
                    <UserDestinationSuggestionSection nextPageRoute={this.props.nextPageSelectedDestinationsRoute}
                                                      noOfAdults={this.props.match.params.noOfAdults}
                                                      noOfChildren={this.props.match.params.noOfChildren}
                                                      startTravelDate={this.props.match.params.startTravelDate}
                                                      endTravelDate={this.props.match.params.endTravelDate}/>
                </ToggleDisplay>

            </div>
        )
    };
}

export default DiscoverPage;