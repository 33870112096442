import React from "react";
import {MenuItem, Nav, Navbar, NavDropdown, NavItem} from 'react-bootstrap'

import Properties from './util/Properties'
import SignUpLogInModal from "./common_ui/SignUpLogInModal";
import NotificationDisplay from "./common_ui/NotificationDisplay";
import {SingletonNavBarSharedState, SingletonUserService} from "./AppContext";

import logo from './assets/img/tripsavvi.png'
import LoadingComponent from "./common_ui/LoadingComponent";
import {RouteProps} from "react-router-dom";
import UserService from "./service/UserService";
import SharedState from "./state/SharedState";
import {MinimalTrip} from "./model/Model";
import {NavBarState} from "./model/State";

interface State {
    existingTrips: MinimalTrip[],
    invitedTrips: MinimalTrip[],
    showSignUp: boolean,
    isLoadingTrips: boolean
}

class AppNavBar extends React.Component<RouteProps, State> {

    private static getTripUrl(trip: {id: string}) {
        return Properties.ROUTE_LOAD_TRIP.replace(":id", trip.id);
    }

    userService: UserService;
    navBarSharedState: SharedState<NavBarState>;

    constructor(props: RouteProps) {
        super(props);
        this.state = {
            existingTrips: [],
            invitedTrips: [],
            showSignUp: false,
            isLoadingTrips: false
        };
        this.userService = SingletonUserService;
        this.navBarSharedState = SingletonNavBarSharedState;
    }

    componentWillMount() {
        this.navBarSharedState.subscribeOnState((state: any) => { //TODO use redux
            this.setState({
                existingTrips: state.existingTrips,
                invitedTrips: state.invitedTrips,
                isLoadingTrips: state.isLoadingTrips
            })
        });
    }

    getCurrentUserName() {
        return this.userService.isUserLoggedIn() ? this.userService.getCurrentUser()?.firstName : '';
    }

    getCurrentUserId() {
        return this.userService.isUserLoggedIn() ? this.userService.getCurrentUser()?.userId : '';
    }

    logOut() {
        this.userService.logoutCurrentUser();
        window.location.reload();
    }

    hasCreatedTrips() {
        return this.userService.isUserLoggedIn() && !!this.state.existingTrips.length;
    }

    hasInvitedTrips() {
        return this.userService.isUserLoggedIn() && !!this.state.invitedTrips.length;
    }

    render() {
        const createdTrips = this.state.existingTrips.map((trip, index) =>
            <MenuItem key={index} eventKey={3 + "." + index} href={AppNavBar.getTripUrl(trip)}>
                <span>{trip.tripName}</span>
            </MenuItem>
        );

        const invitedTrips = this.state.invitedTrips.map((trip, index) =>
            <MenuItem key={index} eventKey={4 + "." + index} href={AppNavBar.getTripUrl(trip)}>
                <span>{trip.tripName}</span>
            </MenuItem>
        );

        let existingTripsNav;
        if (this.userService.isUserLoggedIn() && this.state.isLoadingTrips) {
            existingTripsNav =
                <NavDropdown eventKey={3} title="Existing Trips" id="basic-nav-dropdown">
                    <MenuItem eventKey={3.2}>
                        <div>
                            <LoadingComponent fontSize='14px' loadingMessage="Loading Trips..."/>
                        </div>
                    </MenuItem>
                </NavDropdown>;

        } else if (this.userService.isUserLoggedIn() && (this.hasCreatedTrips() || this.hasInvitedTrips())) {
            existingTripsNav = <NavDropdown eventKey={3} title="Existing Trips" id="basic-nav-dropdown">
                {this.hasCreatedTrips() && <Navbar.Text>
                    Created Trips
                </Navbar.Text>}
                {createdTrips}

                {this.hasInvitedTrips() && <Navbar.Text>
                    Invited Trips
                </Navbar.Text>}
                {invitedTrips}
            </NavDropdown>;
        } else if (this.userService.isUserLoggedIn() && !this.hasCreatedTrips()) {
            existingTripsNav =
                <MenuItem eventKey={3.2}>
                    <span>No Created Trips</span>
                </MenuItem>;
        } else {
            existingTripsNav =
                <MenuItem eventKey={3.2}
                          onClick={(e) => this.setState({showSignUp: true})}>
                        <span>
                            Log In to See Existing Trips
                        </span>
                </MenuItem>;
        }

        return (
            <div>
                <SignUpLogInModal title="Please Sign In to View Existing Trips"
                                  show={this.state.showSignUp}
                                  closeFunction={() => this.setState({showSignUp: false})}/>

                <Navbar inverse collapseOnSelect>
                    <Navbar.Header>
                        <Navbar.Brand>
                            <a href={Properties.ROUTE_DISCOVER_NEXT_DESTINATION} className="navbar-left">
                                <img src={logo} alt="logo" height="37px"/>
                            </a>
                        </Navbar.Brand>
                        <Navbar.Toggle/>
                    </Navbar.Header>
                    <Navbar.Collapse>
                        <Nav>
                            <NavItem eventKey={3.1} href={Properties.ROUTE_DISCOVER_NEXT_DESTINATION}>
                                <span>
                                    New Trip
                                </span>
                            </NavItem>
                            {existingTripsNav}

                        </Nav>
                        <Nav pullRight>
                            {!this.userService.isUserLoggedIn() && <NavItem
                                onClick={(e) => this.setState({showSignUp: true})}>
                                Sign In
                            </NavItem>}

                            {this.userService.isUserLoggedIn() && <NavItem
                                onClick={(e) => this.logOut()}>
                                {this.getCurrentUserName() + ' [Logout]'}
                            </NavItem>}

                        </Nav>
                    </Navbar.Collapse>
                    <Nav id="nav-item-notifications" pullRight>
                        {this.userService.isUserLoggedIn() &&
                        <NotificationDisplay userId={this.getCurrentUserId()} {...this.props}/>}
                    </Nav>
                </Navbar>
            </div>
        )
    }
}

export default AppNavBar;