import React from "react";
import DatePicker from "react-datepicker";
import ToggleDisplay from 'react-toggle-display'

import {ItineraryDateUtils} from "./ItineraryDateUtils";
import {ItineraryComponent} from "./ItineraryComponent";
import DateUtils from "../../../../util/DateUtils";
import Properties from '../../../../util/Properties'
import {ConfirmEdit} from "../../../../common_ui/ConfirmEdit";
import {FormControl} from "react-bootstrap";
import {SingletonUserService} from "../../../../AppContext";

class Flight extends ItineraryComponent {

    constructor(props) {
        super(props);
        this.userService = SingletonUserService;
    }

    setStartDate(startDate) {
        const itineraryItem = {...this.state.itineraryItem};
        this.setState({itineraryItem: {...itineraryItem, startDate: startDate, }})
    }

    setEndDate(endDate) {
        const itineraryItem = {...this.state.itineraryItem};
        this.setState({itineraryItem: {...itineraryItem, endDate: endDate}})
    }

    setAirline(airline) {
        const itineraryItem = {...this.state.itineraryItem};
        this.setState({itineraryItem: {...itineraryItem, airline: airline}})
    }

    render() {
        return (
            <div className="itinerary-item flight-item">
                <div className="flight-outbound-details col-v-centered">
                    <span>
                        {this.state.itineraryItem.originIATACode + ': '}
                        <span className="itinerary-item-flight">
                            <ToggleDisplay if={!this.state.editMode}>
                                {ItineraryDateUtils.formatTime(this.state.itineraryItem.startDate)}
                            </ToggleDisplay>

                            <ToggleDisplay if={this.state.editMode}>
                                <DatePicker id="travel-end-date"
                                            selected={DateUtils.stringToMoment(this.state.itineraryItem.startDate)}
                                            onChange={(newDate) => this.setStartDate(DateUtils.momentToString(Properties.DATE_TIME_FORMAT, newDate))}
                                            className="form-control"
                                            dateFormat={Properties.DISPLAY_DATE_TIME_FORMAT}
                                            showMonthDropdown
                                            showTimeSelect
                                            timeIntervals={5}
                                            withPortal/>
                            </ToggleDisplay>
                        </span>
                    </span>
                    <i className="fas fa-plane"/>
                    <span>
                        {this.state.itineraryItem.destinationIATACode + ': '}
                        <span className="itinerary-item-flight">

                            <ToggleDisplay if={!this.state.editMode}>
                                {ItineraryDateUtils.formatTime(this.state.itineraryItem.endDate)}
                            </ToggleDisplay>

                            <ToggleDisplay if={this.state.editMode}>
                                <DatePicker id="travel-end-date"
                                            selected={DateUtils.stringToMoment(this.state.itineraryItem.endDate)}
                                            onChange={(newDate) => this.setEndDate(DateUtils.momentToString(Properties.DATE_TIME_FORMAT, newDate))}
                                            className="form-control"
                                            dateFormat={Properties.DISPLAY_DATE_TIME_FORMAT}
                                            showMonthDropdown
                                            showTimeSelect
                                            timeIntervals={5}
                                            withPortal/>
                            </ToggleDisplay>
                        </span>
                    </span>
                </div>
                <span>
                    <ToggleDisplay if={!this.state.editMode}>
                        <a href={this.state.itineraryItem.pageLink} target="_blank" rel="noopener noreferrer">
                            {this.state.itineraryItem.airline + ' '}
                        </a>
                    </ToggleDisplay>

                    <ToggleDisplay if={this.state.editMode}>
                        <FormControl type="text"
                                     id="airline-name"
                                     value={this.state.itineraryItem.airline}
                                     onChange={(e) => this.setAirline(e.target.value)}
                                     placeholder="Enter Airline name"/>
                    </ToggleDisplay>
                 </span>
                <ToggleDisplay show={this.state.itineraryItem.cost.amount > 0}>
                    <div className="flight-price">
                        <span>
                            {this.state.itineraryItem.cost.amount + ' ' + this.state.itineraryItem.cost.currency}
                        </span>
                    </div>
                </ToggleDisplay>

                <div>
                    <ConfirmEdit editMode={this.state.editMode}
                                 onStartEdit={() => this.setEditMode(true)}
                                 onStopEdit={() => this.setEditMode(false)}
                                 onSave={() => this.onSave()}
                                 disabled={!this.userService.isUserLoggedIn()}
                                 onCancel={() => this.onCancel()}/>
                </div>
            </div>
        );
    }
}

export {Flight};