import React, {ChangeEvent, FormEvent} from "react";
import {Button, FormControl, FormGroup, HelpBlock} from 'react-bootstrap'
import PasswordValidator from 'password-validator'
import ToggleDisplay from 'react-toggle-display'
import BusyButton from "../../common_ui/BusyButton";
import {SingletonUserService} from "../../AppContext";
import {RouteComponentProps} from "react-router";
import UserService from "../../service/UserService";

interface State {
    password: string,
    confirmPassword: string,
    isLoading: boolean,

    userId: string | null,
    token: string | null,
    resetPasswordSuccess: boolean
}

class ResetPassword extends React.Component<RouteComponentProps, State> {

    userService: UserService;
    passwordSchema: any;

    constructor(props: RouteComponentProps) {
        super(props);

        const searchString = this.props.location.search;
        const params = new URLSearchParams(searchString);
        this.state = {
            password: '',
            confirmPassword: '',
            isLoading: false,

            userId: params.get('userId'),
            token: params.get('token'),
            resetPasswordSuccess: false
        };
        this.userService = SingletonUserService;

        this.passwordSchema = new PasswordValidator();
        this.passwordSchema
            .is().min(8)
            .is().max(100)
            .has().uppercase()
            .has().lowercase()
            .has().digits()
            .has().not().spaces();
    }

    getSignUpPasswordValidation() {
        if (this.state.password.length === 0 && this.state.confirmPassword.length === 0) return 'warning';
        else if ((this.state.password !== this.state.confirmPassword) || !this.passwordSchema.validate(this.state.password, {})) return 'error';
        else if (this.state.password === this.state.confirmPassword) return 'success';
        return null;
    }

    resetPassword() {
        this.startLoading();
        const request = {
            userId: this.state.userId,
            password: this.state.password,
            resetPasswordToken: this.state.token
        };

        this.userService.newPassword(request)
            .then(
                () => this.setState({
                    isLoading: false,
                    resetPasswordSuccess: true
                }),
                () => this.stopLoading()
            );
    }

    startLoading() {
        this.setState({isLoading: true});
    }

    stopLoading() {
        this.setState({isLoading: false})
    }

    render() {
        return (
            <div className="create-trip-section col-v-centered" style={{height: '90vh'}}>
                <div>
                    <form className="log-in-modal">
                        <FormGroup id="loginForm" validationState={this.getSignUpPasswordValidation()}>
                            <FormControl type="password"
                                         id="password"
                                         value={this.state.password}
                                         onChange={(e: ChangeEvent<any>) => this.setState({password: e.target.value})}
                                         placeholder="Enter password"/>
                            <FormControl type="password"
                                         id="confirmPassword"
                                         value={this.state.confirmPassword}
                                         onChange={(e: ChangeEvent<any>) => this.setState({confirmPassword: e.target.value})}
                                         placeholder="Confirm password"/>
                            <FormControl.Feedback/>

                            <HelpBlock style={{color: 'black'}}>
                                - Minimum 8 characters.<br/>
                                - At least one upper case letter.<br/>
                                - At least lower case letter.<br/>
                                - At least one number.<br/>
                            </HelpBlock>
                        </FormGroup>

                        <ToggleDisplay if={!this.state.resetPasswordSuccess}>
                            <BusyButton bsStyle="primary"
                                        className="log-in-modal-button"
                                        buttonText="Change Password"
                                        loadingMessage="Changing Password..."
                                        disabled={this.state.isLoading}
                                        isLoading={this.state.isLoading}
                                        onClick={() => this.resetPassword()}/>
                        </ToggleDisplay>
                        <ToggleDisplay if={this.state.resetPasswordSuccess}>
                            <Button className="log-in-modal-button"
                                    disabled={true}>
                                Password Changed
                            </Button>
                        </ToggleDisplay>

                    </form>
                </div>
            </div>
        );
    }
}

export default ResetPassword;