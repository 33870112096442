import React from "react";
import SessionStore from "../../../../../state/SessionStore";
import ValidationUtils from "../../../../../util/ValidationUtils";
import {Button, ButtonGroup, FormControl, FormGroup} from "react-bootstrap";
import BusyButton from "../../../../../common_ui/BusyButton";
import DatePicker from "react-datepicker";
import DateUtils from "../../../../../util/DateUtils";
import Properties from "../../../../../util/Properties";

class CreateTaskList extends React.Component {

    STORE_NAME = 'createTodoList';

    static getInitialState() {
        return {
            taskListName: '',
            taskList: [
                {taskName: '', done: false, dueDate: undefined},
                {taskName: '', done: false, dueDate: undefined},
                {taskName: '', done: false, dueDate: undefined}
            ]
        };
    }

    constructor(props) {
        super(props);
        this.datePicker = undefined;
        this.state = SessionStore.getItemOrDefault(this.STORE_NAME, CreateTaskList.getInitialState())
    }

    storeState(partialState, callback) {
        return this.setState(partialState, () => {
            callback && callback();
            return SessionStore.setItem(this.STORE_NAME, this.state);
        });
    }

    addTaskListOption() {
        const options = this.state.taskList;
        options.push({taskName: '', done: false, dueDate: undefined});
        this.storeState({taskName: options})
    }

    changeTaskListName(newValue, index) {
        const options = this.state.taskList;
        options[index].taskName = newValue;
        this.storeState({taskList: options})
    }

    removeTaskListOption(index) {
        const options = this.state.taskList;
        options.splice(index, 1);
        this.storeState({taskName: options})
    }

    validateToDoList() {
        const taskListValidation = this.state.taskList
            .map(it => ValidationUtils.validateLength(it.taskName, 1, 80));

        return ValidationUtils.combineValidationResults([
            ValidationUtils.validateLength(this.state.taskListName, 1, 80),
            ...taskListValidation
        ]);
    }

    handleTaskTodoList() {
        const taskList = {
            taskListName: this.state.taskListName,
            taskList: this.state.taskList
        };

        return this.props.postNewTaskList(taskList)
            .then(() => this.clearInputs());
    }

    handleTaskDueDateChange(index, newDate) {
        const options = this.state.taskList;
        options[index].dueDate = DateUtils.momentToString(Properties.DATE_FORMAT, newDate);
        this.storeState({taskList: options})
    }


    clearInputs() {
        this.storeState(CreateTaskList.getInitialState());
    }

    render() {
        const getPlaceholderText = (index) => {
            switch (index) {
                case 0:
                    return 'e.g Book flights';
                case 1:
                    return 'e.g Book accommodation';
                case 2:
                    return 'e.g Get travel insurance';
                default:
            }
        };

        const taskListOptions = this.state.taskList.map((task, index) => {
            return (
                <div className="task-holder" key={index}>
                    <FormControl type="text"
                                 placeholder={getPlaceholderText(index)}
                                 id={'task-' + index}
                                 value={task.taskName}
                                 className="form-control input-sm task-name"
                                 disabled={this.props.isPostingMessage}
                                 onChange={(e) => this.changeTaskListName(e.target.value, index)}/>

                    <DatePicker selected={DateUtils.stringToMoment(task.dueDate, Properties.DATE_FORMAT)}
                                onChange={(newDate) => this.handleTaskDueDateChange(index, newDate)}
                                dateFormat={Properties.DISPLAY_DATE_FORMAT}
                                className="form-control input-sm task-due-date"
                                placeholderText={'Enter due date (optional)'}
                                withPortal/>

                    <Button bsStyle="danger"
                            bsSize="sm"
                            disabled={index === 0 || this.props.isPostingMessage}
                            onClick={(e) => this.removeTaskListOption(index)}>x</Button>
                </div>
            );
        });

        return (
            <div className="todo-list-option-inputs-holder">
                <FormGroup validationState={this.validateToDoList()}>
                    <FormControl type="text"
                                 className="new-poll-option-inputs"
                                 id='task-list-name'
                                 value={this.state.taskListName}
                                 placeholder="Enter a name for the task list"
                                 onChange={(e) => this.storeState({taskListName: e.target.value})}
                                 disabled={this.props.isPostingMessage}/>
                    {taskListOptions}
                    <Button disabled={this.state.taskList.length >= 5 || this.props.isPostingMessage}
                            bsSize="sm"
                            onClick={(e) => this.addTaskListOption()}>Add option</Button>
                </FormGroup>

                <ButtonGroup>
                    <BusyButton bsStyle="primary" onClick={(e) => this.handleTaskTodoList()}
                                buttonText="New Task List"
                                loadingMessage="Saving..."
                                disabled={"success" !== this.validateToDoList() || this.props.isPostingMessage}
                                isLoading={this.props.isPostingMessage}/>
                    <Button disabled={this.props.isPostingMessage}
                            onClick={() => this.props.onCancel()}>
                        Cancel
                    </Button>
                </ButtonGroup>
            </div>
        )
    }

}


export default CreateTaskList;