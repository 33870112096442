import React from "react";
import {Button, Glyphicon} from 'react-bootstrap'
import ToggleDisplay from 'react-toggle-display'

class BusyButton extends React.Component {
    render() {
        const {loadingMessage, buttonText, isLoading, disabled, ...other} = this.props;

        return (
            <Button disabled={isLoading || disabled} {...other}>

                <ToggleDisplay show={this.props.isLoading}>
                    <Glyphicon glyph="refresh" className="loading-component-button"/>
                    <span>{loadingMessage}</span>
                </ToggleDisplay>

                <ToggleDisplay hide={this.props.isLoading}>
                    {buttonText}
                </ToggleDisplay>

            </Button>
        );
    }
}

export default BusyButton;