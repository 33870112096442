import React from 'react'
import {Button, ButtonGroup} from 'react-bootstrap'
import ToggleDisplay from 'react-toggle-display'
import {Redirect} from "react-router-dom";
import FontAwesome from "react-fontawesome"
import {
    SingletonDestinationSuggestionService,
    SingletonReferenceCacheService,
    SingletonTripCreationSharedState
} from "../../AppContext";
import SessionStore from '../../state/SessionStore'

import BusyButton from '../../common_ui/BusyButton'
import Properties from "../../util/Properties";
import SuggestionsInputSection from "../../common_ui/SuggestionsInputSection";

class SuggestionPointsOfInterest extends React.Component {

    MAX_CHARACTER_COUNT = 48;

    renderList(displayName, poiType) {
        const {suggestion, pointsOfInterest} = this.props;
        if (!pointsOfInterest || !pointsOfInterest[poiType]) {
            return null
        }

        const truncate = pointsOfInterest[poiType].reduce((acc, poi) => {
            const charactersSoFar = acc.charactersSoFar + poi.name.length + 2;
            if (charactersSoFar <= this.MAX_CHARACTER_COUNT) {
                acc.noOfWords = acc.noOfWords + 1;
                acc.charactersSoFar = charactersSoFar;
            }
            return acc;
        }, {noOfWords: 0, charactersSoFar: 0});

        const links = pointsOfInterest[poiType]
            .slice(0, truncate.noOfWords || 1)
            .sort((o1, o2) => o2.rank - o1.rank)
            .map((poi, index) => <a target="_blank"
                                    rel="noopener noreferrer"
                                    key={index}
                                    onClick={(e) => e.stopPropagation()}
                                    href={`https://www.google.com/search?q=${poi.name}+${suggestion.cityName}`}
                                    style={{color: 'white', marginRight: '10px'}}><FontAwesome
                name='info-circle'/> {poi.name}
            </a>);

        return (
            <React.Fragment>
                {displayName} - <span>{links}</span><br/>
            </React.Fragment>
        );
    }

    render() {
        const {pointsOfInterest} = this.props;
        if (!pointsOfInterest || !Object.keys(pointsOfInterest).length) {
            return null
        }

        return (
            <div className="destination-points-of-interest">
                {SuggestionsInputSection.selectedActivities().map(it => this.renderList(it.displayName, it.name))}
            </div>
        )
    }
}

class SuggestionDisplay extends React.Component {

    static getCityDisplayText(city) {
        return `${city.cityName}, ${city.countryName} (${city.cityCode})`;
    }

    getCityDetailsHref(destinationCityCode) {
        return Properties.ROUTE_SELECTED_YOUR_DESTINATION_CITY_DETAIL_PAGE
            .replace(':originCityCode', this.props.travelParameters.originCityCode)
            .replace(':destinationCityCode', destinationCityCode)
            .replace(':chosenStartDate', this.props.travelParameters.startTravelDate)
            .replace(':chosenEndDate', this.props.travelParameters.endTravelDate);
    }


    handleDestinationSelection(suggestion) {
        this.props.destinationSuggestionService.selectDestinationSuggestion(suggestion);
    }

    isDestinationSelected(suggestion) {
        return this.props.selectedDestinationSuggestions[suggestion.cityCode];
    }

    render() {
        const {suggestion, suggestionRequest} = this.props;
        const isPriceMatching = suggestion.pricePerPerson.totalPrice <= suggestionRequest.maxSpendPerPerson;

        const isTemperatureMatching = suggestion.temperature.average >= suggestionRequest.minimumTemperature
            && suggestion.temperature.high <= suggestionRequest.maximumTemperature;

        const nonMatchingStyle = {color: '#adc3e0'};

        const flightPriceInfo =
            <React.Fragment>
                <FontAwesome name='pound-sign'/>
                {" Flights From: "}<b className="highlight-detail"
                                      style={isPriceMatching ? null : nonMatchingStyle}>
                £{suggestion.pricePerPerson.flightPrice}pp</b>
            </React.Fragment>;

        const priceInfo =
            <React.Fragment>
                <FontAwesome name='pound-sign'/>
                {" Price From: "}<b className="highlight-detail"
                                    style={isPriceMatching ? null : nonMatchingStyle}>
                £{suggestion.pricePerPerson.totalPrice}pp</b>, Flights From: <b
                className="highlight-detail-less">£{suggestion.pricePerPerson.flightPrice}pp</b>,
                Hotels From: <b
                className="highlight-detail-less">£{suggestion.pricePerPerson.hotelPrice}pp</b>
            </React.Fragment>;

        return (
            <div
                style={{backgroundImage: `url("${this.props.referenceCacheService.getCityPhoto(suggestion.cityCode)}")`}}
                className={'destination-suggestion ' + (this.isDestinationSelected(suggestion) ? 'destination-suggestion-selected' : '')}
                onClick={e => this.handleDestinationSelection(suggestion)}>

                <div className='destination-suggestion-title-small'>
                    <span>{SuggestionDisplay.getCityDisplayText(suggestion)}</span>
                </div>
                <div className="destination-suggestion-middle "/>

                <div className="destination-suggestion-info" style={{padding: '0'}}>
                    <span className="destination-suggestion-text"
                          style={isTemperatureMatching ? null : nonMatchingStyle}>
                        <FontAwesome name='sun'/>
                        {` Low: ${suggestion.temperature.low}°C, Average: ${suggestion.temperature.average}°C, High: ${suggestion.temperature.high}°C`}
                    </span>
                    {
                        (suggestion.pricePerPerson.flightPrice) &&
                        <React.Fragment><br/><span
                            className="destination-suggestion-text">{flightPriceInfo}</span></React.Fragment>
                    }
                    {
                        (suggestion.pricePerPerson.flightPrice && suggestion.pricePerPerson.hotelPrice) &&
                        <React.Fragement><br/><span
                            className="destination-suggestion-text">{priceInfo}</span></React.Fragement>
                    }
                    <div style={{textAlign: 'center'}}>
                        <Button style={{borderRadius: '10px', color: 'white'}} bsStyle="link" bsSize="small"
                                onClick={(e) => e.stopPropagation()}
                                href={this.getCityDetailsHref(suggestion.cityCode)}>
                            <FontAwesome name='info-circle' style={{color: 'white'}}/> Info
                        </Button>
                    </div>

                    <SuggestionPointsOfInterest pointsOfInterest={suggestion.pointsOfInterest} suggestion={suggestion}/>
                </div>
            </div>
        );
    }
}

class DiscoverPageResults extends React.Component {

    STORE_NAME = 'suggestionOutputStore';

    constructor(props) {
        super(props);
        this.state = SessionStore.getItemOrDefault(this.STORE_NAME, DiscoverPageResults.getInitialState());
        this.state.isLoadingSuggestions = false;
        this.state.next = false;

        this.sharedState = SingletonTripCreationSharedState;
        this.referenceCacheService = SingletonReferenceCacheService;
        this.destinationSuggestionService = SingletonDestinationSuggestionService;

    }

    static getInitialState() {
        return {
            selectedIndex: undefined,
            destinationSuggestions: [],
            suggestionRequest: {},
            selectedDestinationSuggestions: {},
            travelParameters: {},
            lastDestinationCodeChecked: undefined
        };
    }

    componentWillMount() {
        this.sharedState.subscribeOnState((state) => { //TODO use redux
            this.setState({
                suggestionRequest: state.suggestionRequest,
                isLoadingSuggestions: state.isLoadingSuggestions,
                selectedDestinationSuggestions: state.selectedDestinationSuggestions,
                travelParameters: state.travelParameters
            });
            this.storeState({
                destinationSuggestions: state.suggestionsData ? state.suggestionsData.suggestions : [],
                lastDestinationCodeChecked: state.suggestionsData ? state.suggestionsData.lastDestinationCodeChecked : undefined
            });
        });
    }

    isAnySuggestionSelected() {
        return Object.keys(this.state.selectedDestinationSuggestions).length > 0;
    }

    storeState(partialState) {
        this.setState(partialState, () => SessionStore.setItem(this.STORE_NAME, this.state));
    }

    handleMore() {
        const newRequest = {
            ...this.state.suggestionRequest,
            lastDestinationCodeChecked: this.state.lastDestinationCodeChecked
        };

        return this.destinationSuggestionService.getMoreDestinationSuggestions(newRequest, this.state.destinationSuggestions)
    }

    handleNext() {
        this.setState({next: true});
    }

    renderSuggestion(index, suggestion) {
        return (
            <SuggestionDisplay key={index} suggestion={suggestion}
                               suggestionRequest={this.state.suggestionRequest}
                               selectedDestinationSuggestions={this.state.selectedDestinationSuggestions}
                               destinationSuggestionService={this.destinationSuggestionService}
                               referenceCacheService={this.referenceCacheService}
                               travelParameters={this.state.travelParameters}/>
        );
    }

    render() {
        if (this.state.next) {
            return <Redirect to={this.props.nextPageRoute}/>
        }

        const matchingSuggestions = this.state.destinationSuggestions.filter(it => it.exactMatch)
            .map((suggestion, index) => this.renderSuggestion(index, suggestion));

        const closestMatchingSuggestions = this.state.destinationSuggestions.filter(it => !it.exactMatch)
            .map((suggestion, index) => this.renderSuggestion(index, suggestion));

        return (
            <div className="destination-suggestion-section">

                <ToggleDisplay if={this.state.destinationSuggestions.length !== 0}>
                    <div>
                        <div className="destination-suggestion-holder">
                            {matchingSuggestions}
                        </div>

                        <ToggleDisplay if={closestMatchingSuggestions.length > 0}>
                            <div style={{padding: '10px'}}>
                                <h3>Here are some destinations that don't match your search that you might still be
                                    interested in.</h3>
                            </div>

                            <div className="destination-suggestion-holder">
                                {closestMatchingSuggestions}
                            </div>
                        </ToggleDisplay>
                    </div>
                </ToggleDisplay>

                <ToggleDisplay if={this.state.destinationSuggestions.length === 0}>
                    <div style={{padding: '30px'}}>
                        <h2>No destinations found</h2>
                        <h3>Please try searching again with different options.</h3>
                    </div>
                    <Button bsSize="large" href={this.props.backPageRoute}>Back</Button>
                </ToggleDisplay>

                <ToggleDisplay if={this.state.destinationSuggestions.length !== 0}>
                    <ButtonGroup>
                        <Button bsSize="large" href={this.props.backPageRoute}>Back</Button>
                        <BusyButton bsSize="large" bsStyle="primary"
                                    buttonText="More"
                                    loadingMessage="Searching..."
                                    isLoading={this.state.isLoadingSuggestions}
                                    onClick={() => this.handleMore()}/>
                        <Button bsSize="large" bsStyle="primary"
                                disabled={!this.isAnySuggestionSelected()}
                                onClick={() => this.handleNext()}>Next</Button>
                    </ButtonGroup>
                </ToggleDisplay>
            </div>
        )
    }

}

export default DiscoverPageResults;