import React, {Component} from 'react';
import './assets/css/main.css';
import './assets/css/mobile.css';

import Properties from './util/Properties'
import AppNavBar from "./AppNavBar";

import DiscoverPage from "./features/tripCreation/DiscoverPage";
import StartPlanningTrip from "./features/tripCreation/StartPlanningTrip";
import PlanningPage from "./features/tripPlanning/PlanningPage";
import ErrorDisplayBar from "./common_ui/ErrorDisplayBar";
import DetailedCityInfo from "./common_ui/DetailedCityInfo";
import ShareSuggestionPage from "./features/tripPlanning/components/shareSuggestion/ShareSuggestionPage";
import NewDestinationSuggestionsPage from "./features/tripPlanning/components/newDestinationSearch/NewDestinationSuggestionsPage";
import ResetPassword from "./features/userAdmin/ResetPassword";
import VerifyAccount from "./features/userAdmin/VerifyAccount";
import AppSecurityProvider from "./AppSecurityProvider";
import DiscoverPageResults from "./features/tripCreation/DiscoverPageResults";
import {BrowserRouter, Route} from "react-router-dom";

class App extends Component {

  render() {
    return (
        <AppSecurityProvider>
          <BrowserRouter>
            <React.Fragment>
              <Route render={props => (
                  <React.Fragment>
                    <AppNavBar {...props}/>
                    <ErrorDisplayBar/>
                  </React.Fragment>
              )}/>

              <Route exact path={Properties.ROUTE_DISCOVER_NEXT_DESTINATION} render={props => (
                  <DiscoverPage {...props}
                                nextPageInspirationDestinationsRoute={Properties.ROUTE_SELECTED_YOUR_DESTINATION}
                                nextPageSelectedDestinationsRoute={Properties.ROUTE_START_PLANNING_TRIP_FROM_HOME}/>
              )}/>

              <Route exact path={Properties.ROUTE_SELECTED_YOUR_DESTINATION} render={props => (
                  <DiscoverPageResults
                      {...props}
                      backPageRoute={Properties.ROUTE_DISCOVER_NEXT_DESTINATION}
                      nextPageRoute={Properties.ROUTE_START_PLANNING_TRIP}/>
              )}/>

              <Route exact path={Properties.ROUTE_START_PLANNING_TRIP} render={props => (
                  <StartPlanningTrip {...props}
                                     backPageRoute={Properties.ROUTE_SELECTED_YOUR_DESTINATION}/>
              )}/>

              <Route exact path={Properties.ROUTE_START_PLANNING_TRIP_FROM_HOME} render={props => (
                  <StartPlanningTrip {...props}
                                     backPageRoute={Properties.ROUTE_DISCOVER_NEXT_DESTINATION}/>
              )}/>

              <Route exact path={Properties.ROUTE_SELECTED_YOUR_DESTINATION_CITY_DETAIL_PAGE}
                     render={props => (
                         <DetailedCityInfo {...props}
                                           backPageRoute={Properties.ROUTE_SELECTED_YOUR_DESTINATION}/>
                     )}/>

              <Route exact path={Properties.ROUTE_LOAD_TRIP} render={props => (
                  <PlanningPage {...props}/>
              )}/>

              <Route exact path={Properties.ROUTE_LOAD_TRIP_CITY_DETAIL_PAGE} render={props => (
                  <DetailedCityInfo {...props}
                                    backPageRoute={Properties.ROUTE_LOAD_TRIP.replace(':id', props.match.params.id)}/>
              )}/>

              <Route exact path={Properties.ROUTE_LOAD_TRIP_NEW_DESTINATION_SEARCH_RESULTS} render={props => (
                  <DiscoverPageResults
                      {...props}
                      backPageRoute={Properties.ROUTE_LOAD_TRIP.replace(':id', props.match.params.id)}
                      nextPageRoute={Properties.ROUTE_LOAD_TRIP_NEW_DESTINATION_SEARCH_RESULTS_CONFIRM.replace(':id', props.match.params.id)}/>
              )}/>

              <Route exact path={Properties.ROUTE_LOAD_TRIP_NEW_DESTINATION_SEARCH_RESULTS_CONFIRM}
                     render={props => (
                         <NewDestinationSuggestionsPage
                             {...props}
                             backPageRoute={Properties.ROUTE_LOAD_TRIP.replace(':id', props.match.params.id)}
                             nextPageRoute={Properties.ROUTE_LOAD_TRIP.replace(':id', props.match.params.id)}/>
                     )}/>

              <Route exact path={Properties.ROUTE_APP_SHARE_SUGGESTION} render={props => (
                  <ShareSuggestionPage {...props}/>
              )}/>

              <Route exact path={Properties.ROUTE_RESET_PASSWORD} render={props => (
                  <ResetPassword {...props}/>
              )}/>

              <Route exact path={Properties.ROUTE_VERIFY_EMAIL} render={props => (
                  <VerifyAccount {...props}/>
              )}/>
            </React.Fragment>
          </BrowserRouter>
        </AppSecurityProvider>
    );
  }
}

export default App;
