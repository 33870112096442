import DateUtils from "../../../../util/DateUtils";

class ItineraryDateUtils {

    static formatTime(dateString: string | undefined) {
        return DateUtils.dateStringSwitchFormat(dateString, 'YYYY-MM-DD HH:mm', 'DD MMM HH:mm')
    }

    static formatDate(dateString: string | undefined) {
        return DateUtils.dateStringSwitchFormat(dateString, 'YYYY-MM-DD HH:mm', 'DD MMM YYYY')
    }

}

export {ItineraryDateUtils}