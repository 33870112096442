import React from 'react';
import {Glyphicon} from 'react-bootstrap'
import ToggleDisplay from 'react-toggle-display'

class LoadingComponent extends React.Component {

    constructor(props) {
        super(props);
        this.fontSize = this.props.fontSize || '30px';
        this.color = this.props.color || 'black';
    }

    render() {
        return (
            <ToggleDisplay show={this.props.isLoading}>
                <span>
                    <Glyphicon glyph="refresh" className="loading-component-icon"
                               style={{fontSize: this.fontSize, color: this.props.color}}/>
                    <span className="loading-component-title"
                          style={{fontSize: this.fontSize, color: this.props.color}}>{this.props.loadingMessage}</span>
                </span>
            </ToggleDisplay>
        )
    }

}

export default LoadingComponent;