import React from "react";
import {Badge, NavDropdown, NavItem} from "react-bootstrap";
import FontAwesome from "react-fontawesome"
import {SingletonSocialService, SingletonWebSocketClient} from "../AppContext";
import {Redirect} from "react-router-dom";
import Properties from '../util/Properties'
import SessionStore from "../state/SessionStore";
import DateUtils from "../util/DateUtils";
import LoadingComponent from "./LoadingComponent";

const getClassname = function (notification) {
    return notification.seen ? 'notification seen' : 'notification unseen';
};

function TripInvite({notification, onClick}) {
    const {referenceData} = notification;
    return (
        <NavItem className={getClassname(notification)} onClick={onClick}>
            <FontAwesome
                name='suitcase'/>{` You've been invited to `}<b>'{referenceData.tripName}'</b>{' by '}<b>{referenceData.creatorUserFirstName}</b>
        </NavItem>
    );
}

function TripNewMessage({notification, onClick}) {
    const {referenceData, additionalData} = notification;
    return (
        <NavItem className={getClassname(notification)} onClick={onClick}>
            <FontAwesome name='comment'/><b>{` ${referenceData.creatorUserFirstName}`}</b>
            {` has written a comment on `}<b>'{referenceData.tripName}'</b>
            <br/>
            {`"${additionalData.commentText.substring(0, 40)}${additionalData.commentText.length > 40 ? '...' : ''}"`}
        </NavItem>
    );
}

function TripFlightSuggestion({notification, onClick}) {
    const {referenceData, additionalData} = notification;
    const {tripName} = referenceData;
    const {cost, outboundDepartureTime} = additionalData;
    return (
        <NavItem className={getClassname(notification)} onClick={onClick}>
            <FontAwesome name='plane'/><b>{` ${referenceData.creatorUserFirstName}`}</b>
            {` has suggested a flight for the trip `}<b>'{tripName}'</b>
            <br/>
            {`for ${cost.amount}${cost.currency} departing at `}<b>{DateUtils.getDisplayDateTime(outboundDepartureTime)}</b>
        </NavItem>
    );
}

function TripAccommodationSuggestion({notification, onClick}) {
    const {referenceData, additionalData} = notification;
    const {creatorUserFirstName, tripName} = referenceData;
    const {cost, accommodationName} = additionalData;
    return (
        <NavItem className={getClassname(notification)} onClick={onClick}>
            <FontAwesome name='bed'/><b>{` ${creatorUserFirstName}`}</b>
            {` has suggested accommodation for the trip `}<b>'{tripName}'</b>
            <br/>
            {`"${accommodationName}" for ${cost.amount}${cost.currency}`}
        </NavItem>
    );
}

function TripExtraSuggestion({notification, onClick}) {
    const {referenceData, additionalData} = notification;
    const {creatorUserFirstName, tripName} = referenceData;
    const {cost, name, category} = additionalData;
    return (
        <NavItem className={getClassname(notification)} onClick={onClick}>
            <FontAwesome name='bed'/><b>{` ${creatorUserFirstName}`}</b>
            {` has made a suggestion for the trip `}<b>'{tripName}'</b>
            <br/>
            {`"${name} (${category})" for ${cost.amount}${cost.currency}`}
        </NavItem>
    );
}

function TripNewDestinationSuggestion({notification, onClick}) {
    const {referenceData} = notification;
    const {creatorUserFirstName, tripName, city, temperature} = referenceData;

    return (
        <NavItem className={getClassname(notification)} onClick={onClick}>
           <span> <FontAwesome name='bed'/><b>{` ${creatorUserFirstName}`}</b>
               {` has suggested a destination for the trip `}<b>'{tripName}'</b>
            <br/>
               {`"${city.name}, ${city.countryName}" with Avg Temp: ${temperature.avgTemperatureC}°C`}
           </span>
        </NavItem>
    );
}

function TripNewPoll({notification, onClick}) {
    const {referenceData, additionalData} = notification;
    const {creatorUserFirstName, tripName} = referenceData;
    const {pollQuestion} = additionalData;

    return (
        <NavItem className={getClassname(notification)} onClick={onClick}>
           <span> <FontAwesome name='bed'/><b>{` ${creatorUserFirstName}`}</b>
               {` has started a new poll for the trip `}<b>'{tripName}'</b>
            <br/>
               {`"${pollQuestion}`}
           </span>
        </NavItem>
    );
}

function TripNewTaskList({notification, onClick}) {
    const {referenceData, additionalData} = notification;
    const {creatorUserFirstName, tripName} = referenceData;
    const {taskListName} = additionalData;

    return (
        <NavItem className={getClassname(notification)} onClick={onClick}>
           <span> <FontAwesome name='tasks'/><b>{` ${creatorUserFirstName}`}</b>
               {` has create a new task list for the trip `}<b>'{tripName}'</b>
            <br/>
               {`"${taskListName}`}
           </span>
        </NavItem>
    );
}

function TripNewTaskListUpdate({notification, onClick}) {
    const {referenceData, additionalData} = notification;
    const {tripName} = referenceData;
    const {taskListName} = additionalData;

    return (
        <NavItem className={getClassname(notification)} onClick={onClick}>
           <span> <FontAwesome name='tasks'/>
               {` Task List (${taskListName}) is complete for the trip `}<b>'{tripName}'</b>
           </span>
        </NavItem>
    );
}


export default class NotificationDisplay extends React.Component {

    STORE_NAME = 'notificationDisplay';
    USER_NOTIFICATIONS = `USER_NOTIFICATIONS_${this.props.userId}`;

    static getInitialState() {
        return {
            notifications: [],
            redirectUrl: undefined
        }
    }

    constructor(props) {
        super(props);
        this.newMessagesClient = SingletonWebSocketClient;
        this.socialService = SingletonSocialService;
        const initialStore = SessionStore.getItemOrDefault(this.STORE_NAME, NotificationDisplay.getInitialState());
        this.state = {
            ...initialStore,
            limitNotifications: true,
            navOpen: false,
            settingNotificationsAsRead: false
        };
    }

    storeState(partialState) {
        this.setState(partialState, () => SessionStore.setItem(this.STORE_NAME, this.state));
    }

    componentWillMount() {
        this.socialService.getNotifications()
            .then((notifications) => this.storeState({notifications: notifications}));

        this.newMessagesClient.connect([this.USER_NOTIFICATIONS])
            .then(() => this.newMessagesClient.onMessageReceived((topic, userNotification) => {
                const newNotifications = this.state.notifications;
                newNotifications.unshift(userNotification);
                this.storeState({notifications: newNotifications})
            }));
    }

    updateNotifications(notificationIds) {
        if (!notificationIds.length) {
            return Promise.resolve([]);
        }

        return this.socialService.updateNotifications(notificationIds)
            .then((updatedNotifications) => {
                this.handleUpdatedNotifications(updatedNotifications);
                return updatedNotifications;
            });
    }

    handleUpdatedNotifications = (notifications) => {
        const newNotifications = this.state.notifications.map(existingNotification => {
            const notification = notifications.find(it => it.id === existingNotification.id);
            if (notification) {
                return notification;
            } else {
                return existingNotification;
            }
        });
        this.storeState({notifications: newNotifications})
    };

    handleToggleSeeMore() {
        this.setState({limitNotifications: !this.state.limitNotifications});
    }

    updateNotificationAndNavigate(notification, url) {
        this.updateNotifications([notification.id]);
        this.props.history.push(url);
        this.setState({navOpen: false});
    }

    async markAllAsRead() {
        this.setState({settingNotificationsAsRead: true});
        const notificationIds = this.state.notifications.filter(it => !it.seen).map(it => it.id);
        await this.updateNotifications(notificationIds);
        this.setState({settingNotificationsAsRead: false});
    }

    renderNotification = (notification, index) => {
        const {type, additionalData} = notification;

        const onClickNavigateToTrip = () => {
            this.updateNotificationAndNavigate(notification,
                `${Properties.ROUTE_LOAD_TRIP.replace(":id", additionalData.tripId)}`);
        };

        const onClickNavigateToTripMessage = () => {
            this.updateNotificationAndNavigate(notification,
                `${Properties.ROUTE_LOAD_TRIP.replace(":id", additionalData.tripId)}?showMessage=${additionalData.messageId}`);
        };

        switch (type) {
            case 'TRIP_INVITE':
                return <TripInvite key={index}
                                   notification={notification}
                                   onClick={onClickNavigateToTrip}/>;
            case 'TRIP_NEW_MESSAGE':
                return <TripNewMessage key={index}
                                       notification={notification}
                                       onClick={onClickNavigateToTripMessage}/>;
            case 'TRIP_FLIGHT_SUGGESTION':
                return <TripFlightSuggestion key={index}
                                             notification={notification}
                                             onClick={onClickNavigateToTripMessage}/>;
            case 'TRIP_ACCOMMODATION_SUGGESTION':
                return <TripAccommodationSuggestion key={index}
                                                    notification={notification}
                                                    onClick={onClickNavigateToTripMessage}/>;
            case 'TRIP_EXTRA_SUGGESTION':
                return <TripExtraSuggestion key={index}
                                            notification={notification}
                                            onClick={onClickNavigateToTripMessage}/>;
            case 'TRIP_NEW_DESTINATION_SUGGESTION':
                return <TripNewDestinationSuggestion key={index}
                                                     notification={notification}
                                                     onClick={onClickNavigateToTripMessage}/>;
            case 'TRIP_NEW_POLL':
                return <TripNewPoll key={index}
                                    notification={notification}
                                    onClick={onClickNavigateToTripMessage}/>;
            case 'TRIP_NEW_TASK_LIST':
                return <TripNewTaskList key={index}
                                        notification={notification}
                                        onClick={onClickNavigateToTripMessage}/>;
            case 'TASK_LIST_UPDATE':
                return <TripNewTaskListUpdate key={index}
                                              notification={notification}
                                              onClick={onClickNavigateToTripMessage}/>;
            default:
                return null;
        }
    };

    render() {
        if (this.state.redirectUrl) {
            return <Redirect to={this.state.redirectUrl}/>
        }

        const endIndex = this.state.limitNotifications ? 4 : 9999;
        const notifications = this.state.notifications.slice(0, endIndex).map((it, index) => this.renderNotification(it, index));

        const noOfUnseenNotifications = this.state.notifications.filter(it => !it.seen).length;

        const title = <React.Fragment>
            <FontAwesome name='user'/>
            <Badge bsClass={noOfUnseenNotifications ? 'notification-badge' : ''}>{noOfUnseenNotifications}</Badge>
        </React.Fragment>;

        const onToggle = () => this.setState({navOpen: !this.state.navOpen});

        return (
            <NavDropdown id='notification-dropdown' title={title}
                         open={this.state.navOpen}
                         onToggle={onToggle}>
                {notifications}
                <div className="notification-actions">
                    <div className="notification-mark-all-read" onClick={() => this.markAllAsRead()}>
                        <FontAwesome name='user'/> Mark all as read <LoadingComponent
                        isLoading={this.state.settingNotificationsAsRead}
                        fontSize={"12px"}/>
                    </div>
                    {(this.state.notifications.length > 4) && this.state.limitNotifications &&
                    <div className="notification-see-more" onClick={(e) => this.handleToggleSeeMore(e)}>
                        <FontAwesome name='caret-square-down'/> See more
                    </div>
                    }
                </div>
                {(!this.state.notifications.length) && <NavItem className='notification unseen' disabled={true}>
                    You have no notifications yet!
                </NavItem>}
            </NavDropdown>
        )
    }
}